export default {



    // this function builds a cluster object in js
    // it will be sent to the server api
    build(cluster, excerptMaxTokens) {

        let payload_cluster = {
            key: "",
            excerpts: [],
            post_ids: [],
            img_urls: [],
            tags: [],
            loot: [],
            earliest_post_date: null,
            word2vec_vectors: [],  // hold all cluster's posts' word2vec vectors
            title: "",
        }

        // posts ids
        let earliest = null;
        let source_refs_list = "";
        let is_published = false;
        for (let p of cluster.posts) {

            if (p.is_published) {
                is_published = true; // at least one post is published
            }

            // excerpts
            // max the 3 first longest post content
            // cluster.postsa re sorted by length, the longest first
            // (see ghostMain.vue)
            // we keep all excerpts in english

            // only update excerpts from max 3 differents sources
            if (p.source_ref && !source_refs_list.includes(p.source_ref) && source_refs_list.split(" ").length < 3) {
                if (p.language == "en") {
                    let e = p.title + " " + p.content.replace(/<[^>]+>/g, "")
                    let max_tokens_e = e.split(/\s+/) // Split into tokens by whitespace
                    .slice(0, excerptMaxTokens) // Take the first n tokens
                    .join(" "); // Join the tokens back into a string
                    if (max_tokens_e.length > 0) {
                        payload_cluster.excerpts.push(max_tokens_e);
                        source_refs_list += p.source_ref + " ";
                    }
                } else {
                    if (p.translations.length > 0) {
                        for (let t of p.translations) {
                            if (t.output_language == "en") {
                                let e = t.title + " " + t.content.replace(/<[^>]+>/g, "")
                                let max_tokens_e = e.split(/\s+/) // Split into tokens by whitespace
                                .slice(0, excerptMaxTokens) // Take the first n tokens
                                .join(" "); // Join the tokens back into a string

                                if (max_tokens_e.length > 0) {
                                    payload_cluster.excerpts.push(max_tokens_e);
                                    source_refs_list += p.source_ref + " ";
                                }
                            }
                        }
                    }  
                }
            }

            // store pids
            payload_cluster.post_ids.push(p.id);

            // Capture the earliest post date
            if (!earliest || new Date(p.date) < new Date(earliest)) {
                earliest = p.date;
            }

            // vectors
            payload_cluster.word2vec_vectors.push(p.vector);

            // key
            payload_cluster.key = cluster.key;

        }

        // is published
        payload_cluster.is_published = is_published;

        // earliest date post
        payload_cluster.earliest_post_date = earliest

        // img ids
        payload_cluster.img_urls = this.buildClusterImgUrls(cluster);

        // tags
        payload_cluster.tags = this.buildClusterTags(cluster);

        // loot
        payload_cluster.loot = this.buildClusterLoot(cluster);

        // title
        let title = "";
        if (cluster.posts.length > 0) {
            if (cluster.posts[0].language == "en") {
                title = cluster.posts[0].title;
            } else {
                if (cluster.posts[0].translations.length > 0) {
                    for (let t of cluster.posts[0].translations) {
                        if (t.output_language == "en") {
                            title = t.title;
                        }
                    }
                }
            }
        }
        payload_cluster.title = title;
        
        return JSON.stringify(payload_cluster);
    },

    buildPostExcerpt(p, excerptMaxTokens) {
        let excerpt = "";
        if (p.language == "en") {
            let e = p.title + " " + p.content.replace(/<[^>]+>/g, "")
            let max_tokens_e = e.split(/\s+/) // Split into tokens by whitespace
            .slice(0, excerptMaxTokens) // Take the first n tokens
            .join(" "); // Join the tokens back into a string
            if (max_tokens_e.length > 0) {
                excerpt = max_tokens_e
            }
        } else {
            if (p.translations && p.translations.length > 0) {
                for (let t of p.translations) {
                    if (t.output_language == "en") {
                        let e = t.title + " " + t.content.replace(/<[^>]+>/g, "")
                        let max_tokens_e = e.split(/\s+/) // Split into tokens by whitespace
                        .slice(0, excerptMaxTokens) // Take the first n tokens
                        .join(" "); // Join the tokens back into a string

                        if (max_tokens_e.length > 0) {
                            excerpt = max_tokens_e;
                        }
                    }
                }
            }  
        }

        // sometimes in the excerpt i end up with anexcerpt with triple quote not single quote
        

        return excerpt
    },


    buildClusterImgUrls(cl) {
        let cl_img_urls = [];
        if (cl.posts.length > 0) {
            let regex = /<img[^>]*src="([^"]*)"/g;
            for (let p of cl.posts) {
                let match;
                while ((match = regex.exec(p.content))) {
                    cl_img_urls.push(match[1]);
                }

                // from feeder
                if (p.img_url && p.img_url.length > 0) {
                    cl_img_urls.push(p.img_url)
                }

                // remove duplicates
                cl_img_urls = [...new Set(cl_img_urls)];
            }
        }
        return cl_img_urls
    },

    buildClusterTags(cl) {
        let tags = []
        if (cl.posts && cl.posts.length > 0) {
            for (let p of cl.posts) {
                for (let t of p.tags) {

                    // Check if the country already exists in the tags array
                    let existingTag = tags.find(c => c.ref === t.ref);
                    if (existingTag) {
                        // If found, increment the count
                        existingTag.count += 1;
                    } else {
                        // If not found, add it to the array with count = 1
                        tags.push({
                            ref: t.ref,
                            name: t.name,
                            count: 1,
                            channel: t.channel,
                        });
                    }

                }
            }
        }

        // Sort tags first by channel alphabetically, then by count in descending order
        tags.sort((a, b) => {
            // First compare by channel alphabetically
            if (a.channel < b.channel) return -1;
            if (a.channel > b.channel) return 1;

            // If channels are the same, then compare by count in descending order
            return b.count - a.count;
        });


        return tags
    },


    buildClusterLoot(cl) {
        let loot = []

        for (let p of cl.posts) {
            if (p.loot) {
                for (let l of p.loot) {

                    // Check if the loot already exists in the loot array by matching both tag_ref and value
                    let existingLoot = loot.find(c => c.ref === l.tag_ref && c.value === l.value);

                    if (existingLoot) {
                        // If found, increment the count
                        existingLoot.count += 1;
                    } else {
                        // If not found, add it to the array with count = 1
                        loot.push({
                            tag_ref: l.tag_ref,
                            tag_name: l.tag_name,
                            value: l.value,
                            category: l.category,
                            path: l.path,
                        });
                    }
                }
            }
        }

        // Sort loot by count in descending order
        loot.sort((a, b) => b.count - a.count);

        return loot
    },

}