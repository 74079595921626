<template>
    <div :key="key">
        <!-- cluster saving -->
        <v-progress-linear indeterminate color="teal accent-3" :active="loading"></v-progress-linear>

        <v-sheet rounded outlined class="" :color="headerColor">
            <v-card flat outlined class="d-flex py-1" key="cluster-header" :color="header_background">
                
                <!-- Cluster Meta-->
                <v-card flat outlined color="transparent" width="82px" class="px-1">
                    
                    <!-- SHOW/hide header cluster posts  -->
                    <v-btn v-if="header_cluster.posts.length > 1"  @click="switchShowCluster" x-small class="font-weight-bold Dialog--text ml-1 mt-0" :color="headerColor">
                        #{{cl_index}}·{{deckClustersCount}}
                    </v-btn>
                    <v-btn v-else @click="switchShowCluster" x-small outlined class="font-weight-bold ml-1 mt-0" :color="headerColor">
                        #{{cl_index}}·{{deckClustersCount}}
                    </v-btn>

                    <!-- switch deck  -->
                    <v-btn v-if="header_cluster.is_out" @click="switchDeckOut" x-small class="Dialog--text font-weight-bold ml-1 mt-2" :color="headerColor">
                       <v-icon>mdi-arrow-left-bold</v-icon>
                    </v-btn>
                    <v-btn v-else @click="switchDeckOut" x-small rounded class="Dialog--text font-weight-bold ml-1 mt-2" :color="headerColor">
                       <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                    
                    <!-- drag up/down button -->
                    <v-btn x-small class="mt-2 ml-1" color="grey"><v-icon color="Dialog">mdi-drag</v-icon></v-btn>

                    <!-- imgs/posts count -->
                    <v-btn x-small text class="mt-1 lime--text">{{buildClusterImgUrls.length}}/{{noHtmlContent(header_cluster.posts[0].content).length}}</v-btn>

                    <!-- Go Cluster Publisher -->
                    <v-btn @click="eventOpenPublisherDialog" x-small class="Dialog--text mt-1 ml-1" color="lime accent-2"><v-icon >mdi-publish</v-icon></v-btn>

                    <v-btn x-small v-if="header_cluster.pids_to_add && header_cluster.pids_to_add > 0" class="mt-1" color="error">pids+{{ header_cluster.pids_to_add }}</v-btn>

                </v-card>
                

                <v-divider vertical class="ml-2"></v-divider>
                
                <!-- avatar -->
                <v-avatar v-if="avatar_url.length > 0"
                    rounded=""
                    size="140"
                    class="ml-2"
                >
                    <v-img v-if="header_cluster.posts.length > 0" :src="avatar_url" :key="avatar_url"></v-img>
                </v-avatar>
               
                <v-card flat class="" color="" width="100%" key="title-sources-translation">
                    
                    <!-- title -->
                    <v-card-title @click="switchShowCluster" style="cursor: pointer"  :class='"wordbreak text-body-1 font-weight-bold pt-1 pb-0 pl-2 " + colorTitleClass'>
                        {{buildTitle}}
                    </v-card-title>

                    <v-card-actions class="pt-1 pb-2">
                            
                        <!-- Cluster Posts Meta-->
                        <v-btn v-if="header_cluster.posts.length > 1" x-small class="font-weight-bold Dialog--text" color="lime">{{ header_cluster.posts.length }}</v-btn>
                            
                        <!--Merge -->
                        <v-btn v-if="!header_cluster.is_saved" x-small @click="merger_dialog = true" :color="headerColor" class="Dialog--text font-weight-bold">Merge</v-btn>

                        <!-- CLUSTER -->
                        <v-btn v-if="header_cluster.is_out && !header_cluster.is_saved" @click="eventCreateCluster" x-small class="ml-2 Dialog--text font-weight-bold" color="teal accent-3">CLUSTER</v-btn>
                        <v-btn v-if="header_cluster.is_out && header_cluster.is_saved" x-small class="ml-2 Dialog--text font-weight-bold" color="success">SAVED</v-btn>
                        <v-btn v-if="header_cluster.is_out && error && error.length > 0" x-small class="ml-2 font-weight-bold" color="error">ERROR</v-btn>
                        
                        <!-- Min Doc Length -->
                        <span class="ml-2">{{ cluster.key }}</span>
                        <v-btn @click="copyClusterKey" small depressed icon><v-icon small>mdi-content-copy</v-icon></v-btn>
                        <v-btn x-small v-if="header_cluster.posts.length > 1" color="purple">Cl Radius: {{ buildClusterMaxCosineCentroidrRadius }}</v-btn>
                        
                        <!-- Sources Widget-->
                        <SourcesWidget :posts="header_cluster.posts" :color="headerColor" class="ml-2"/>
                        <v-spacer></v-spacer>
                        
                        <!-- Translation Widget-->
                        <TranslationWidget
                            :post="header_cluster.posts[0]"
                            tab="cluster"
                            stream="post"
                            class="ml-4"
                            @eventTranslation="eventTranslation"
                        ></TranslationWidget>

                    </v-card-actions>
                    
                    <!-- Cluster save Error -->
                    <span v-if="header_cluster.is_out && error.length > 0" class="error--text ml-2">{{ error }}</span>

                    <v-divider></v-divider>

                    <v-card-actions class="py-1 my-0" key="tags-continent-loot">
                        <v-card flat color="">
                            <TagsWidget 
                                :cluster="header_cluster"
                                :cl_index="cl_index"
                                :tags_list="tags_list"
                                @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                                class="ml-2"
                            />

                            <LootWidget 
                            :cluster ="header_cluster"
                            :cl_index="cl_index"
                            :tags_list="tags_list"
                            @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                            class="ml-2 mt-1"
                        />
                        </v-card>
                       
                        <v-spacer></v-spacer>
                        <ContinentWidget 
                            :cluster ="header_cluster"
                            :cl_index="cl_index"
                            :color="headerColor"
                            @eventChangeContinent="eventChangeContinent" 
                            class="mb-1"
                        />
                    </v-card-actions>
                </v-card>

            </v-card>
        </v-sheet>

        <v-dialog v-model="merger_dialog" width="400">
            <v-sheet rounded outlined color="success">
                <v-card v-if="clusters" flat class="pa-2">
                    <!-- warning n loops from 1 to clusters length-->
                    <v-btn small v-for="n in clusters.length" :key="'cluster_' + n" @click="eventMergeClusters(n-1)" :color="clusterColor(n-1)" class="font-weight-bold ml-1 mb-1">{{n-1}}</v-btn>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small
                            :color="headerColor"
                            text
                            @click="merger_dialog = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cluster_vector_operations from "@/common/cluster/vector_operations";
import deck_to_cluster from "@/common/cluster/deck_to_cluster";
// import local_storage from "@/common/cluster/local_storage";

import TranslationWidget from '../../../Translations/TranslationWidget.vue'
import SourcesWidget from './DeckWidgetSources.vue'
import TagsWidget from './DeckWidgetTags.vue'
import LootWidget from './DeckWidgetLoot.vue'
import ContinentWidget from './DeckWidgetContinent.vue'

export default {

    name: 'ClusterHeader',

    props: ['clusters', 'cluster', 'cl_index','tags_list','parameters'],

    data: () => ({

        loading: false,
        error: "",

        // header cluster
        header_cluster: {},
       
        show_cluster: true,

        header_background: "grey darken-4",

        // Merger
        merger_dialog: false,
        dialog_target_cluster: 0,

        // log
        logs: [],

        // force rendering update
        key: 0,
    }),

    components: {
        TranslationWidget,
        SourcesWidget,
        TagsWidget,
        LootWidget,
        ContinentWidget,
    },

    created() {
    },


    watch: {
        cluster: {
            immediate: true,
            deep: true,
            handler() {
                this.header_cluster = JSON.parse(JSON.stringify(this.cluster));
            },
        }
    },

    methods: {

        switchShowCluster() {
            this.header_cluster.show = !this.header_cluster.show
            this.$emit("eventShowCluster", this.cl_index)
        },

        switchDeckOut() {
            this.$emit("eventSwitchDeckOut", this.cl_index)
        },

        eventRemoveTagFromCluster(cl_index, ref) {
            this.$emit("eventRemoveTagFromCluster", cl_index, ref)
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            this.$emit("eventRemoveLootFromCluster", cl_index, ref)
        },

        eventMergeClusters(n) {
            if (n != this.cl_index) {
                this.$emit("eventMergeClusters", this.cl_index, n)
                this.merger_dialog = false;
            }
        },

        //* Translations
        eventTranslation(output_language, content, description, title) {
            console.log("eventTranslation", output_language, content.length, description.length,title)
            if (this.header_cluster.posts[0].translations.length > 0) {
                for (let tr of this.header_cluster.posts[0].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title = title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

        //* Continent
        eventChangeContinent(selected_continent, order){
            this.$emit("eventChangeContinent", selected_continent, order, this.cl_index)
        },

        //* Publisher
        eventOpenPublisherDialog() {
            this.$emit("eventOpenPublisherDialog", this.cl_index)
        },

        copyClusterKey() {
            navigator.clipboard.writeText(this.cluster.key);
        },

        //* Cluster
        eventCreateCluster: async function() {
            console.log("eventCreateCluster", this.cl_index, this.header_cluster.key)

            let payload = {};

            if (this.header_cluster.is_out) {
                let payload_cluster = deck_to_cluster.build(this.header_cluster, this.parameters.gpt_excerpt_tokens);
                payload = {
                    clusters: [payload_cluster],
                }

                this.clusters_loading = true
                this.error = "";
    
                try {
                    let res = await this.$api.post("/clusters/deck_save", payload);
                    this.logs = res.data;
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = data.message;
                } finally {

                    if (this.logs.length == 1) {
                        if (this.logs[0].already) {
                            this.header_cluster.is_already_saved = true;
                            this.header_cluster.is_saved = true;
                        } else if (this.logs[0].success) {
                            this.header_cluster.is_saved = true;
                        } else if (this.logs[0].pids_to_add.length > 0) {
                            this.header_cluster.pids_to_add = this.logs[0].pids_to_add.length;
                        } else {
                            this.error = this.logs[0].error;
                        }

                        // update local storage
                        // local_storage.update_cluster(this.header_cluster.key, this.header_cluster);

                        // update ghost cluster
                        this.$emit("eventUpdateCluster", this.cl_index, this.header_cluster)
                        
                    } else {
                        this.error = "log length!=1 : " + this.log
                    }

                    this.clusters_loading = false
                    // force rendering update
                    this.key ++;
                }

            }
            

        },

        noHtmlContent(text) {
            return text.replace(/<[^>]*>/gi, '');
        },


        clusterColor(n) {
           
            if (this.clusters && this.clusters.length > 0) {
                if (n == this.cl_index ) {
                    return "error"
                } else if (this.clusters[n].is_out) {
                    return "amber"
                } else {
                    return "success"
                }
            } else {
                return "grey"
            }
        },

    },

    computed: {

        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),

        avatar_url() {
            return this.header_cluster.posts.length > 0 ? this.header_cluster.posts[0].img_url : '';
        },

        // needer for cluster header
        deckClustersCount() {
            return this.clusters.filter(c => !c.is_out).length;
        },

        hasLoot() {
            if (this.header_cluster.posts) {
                for (let p of this.header_cluster.posts) {
                    if (p.loot) {
                        return true
                    }
                }
            }
            return false
        },

        buildTitle() {
            let title = ""
            if (this.header_cluster.posts.length > 0) {
                title = this.header_cluster.posts[0].title;

                if (this.trans_settings && this.trans_settings[this.header_cluster.posts[0].language] && this.trans_settings[this.header_cluster.posts[0].language].available.length > 0) {
                    for (let ol of this.trans_settings[this.header_cluster.posts[0].language].available) {
                        if (ol.trans_active) {
                            if (this.header_cluster.posts.length > 0 && this.header_cluster.posts[0].translations) {
                                for (let trans of this.header_cluster.posts[0].translations) {
                                    if (trans.output_language == ol.iso) {
                                        if (trans.title.length > 0) {
                                            title = "[" + trans.title.trim() + "]";
                                        }
                                    }
                                }
                            }
                        } 
                    }
                    
                }
            }
            return title
        },

      
        colorTitleClass() {
            if (this.header_cluster.posts.length == 1) {
                if (this.header_cluster.posts[0].is_community) {
                    return "purple--text text--accent-1"
                } else {
                    return ""
                }
            } else {
                return ""
            }
        },


        buildClusterImgUrls() {
            let cl_img_urls = [];
            let regex = /<img[^>]*src="([^"]*)"/g;
            for (let p of this.header_cluster.posts) {
                let match;
                while ((match = regex.exec(p.content))) {
                    cl_img_urls.push(match[1]);
                }

                // from feeder
                if (p.img_url.length > 0) {
                    cl_img_urls.push(p.img_url)
                }

                // remove duplicates
                cl_img_urls = [...new Set(cl_img_urls)];
            }
            return cl_img_urls
        },


        headerColor() {

            if (this.header_cluster.is_already_saved) {
                return "purple"
            }

            if (this.header_cluster.is_saved) {
                return "teal accent-3"
            }
            for (let p of this.header_cluster.posts) {
                if (p.is_error) {
                    return "orange";
                }
                if (p.is_published) {
                    return "blue lighten-1";
                    
                }
                if (p.is_scheduled) {
                    return "lime accent-2";
                }
            }

            if (this.header_cluster.is_out) {
                return "amber"
            } else {
                return "success"
            }
        },


        findMinimumDocLength() {

            let min_doc_length = 0

            if (this.header_cluster.posts.length > 0) {
                min_doc_length = this.header_cluster.posts[0].doc_length;
            }

            for (let p of this.header_cluster.posts) {
                if (p.doc_length < min_doc_length) {
                    min_doc_length = p.doc_length
                }
            }


            return min_doc_length
        },

        buildClusterMaxCosineCentroidrRadius() {
            
            let vectors = [];
            let radius = 0;

            if (this.header_cluster.posts.length > 1) {
                // Collect all vectors
                for (let p of this.header_cluster.posts) {
                    vectors.push(p.vector);
                }

                // cosine similarity
                radius = cluster_vector_operations.maxCosineDistanceFromCentroid(this.header_cluster.centroid, vectors);
            }

           
            return Math.round(1000 * radius) / 1000;
        },

    },
}
</script>

<style>
.wordbreak {
    word-break: normal;
}
</style>