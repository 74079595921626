<template>
    <div>
        <v-sheet rounded outlined :color="sheetColor">
            <v-card flat outlined>

                <!-- loading -->
                <v-progress-linear indeterminate :color="color" :active="loading"></v-progress-linear>

                {{ message }}

                <!-- error -->
                <v-card v-if="error" flat color="transparent" key="stasher-error-message">
                    <v-card-text class="ml-5 mt-2 error--text">API Error: {{ error }}</v-card-text>
                </v-card>

                <v-card-actions>
                    
                    <v-btn v-if="clusters_count > 1" x-small fab outlined class="font-weight-bold text-caption pink--text text--lighten-2 mr-1">#{{ cl_index }}</v-btn>
                    <v-btn x-small fab outlined class="font-weight-bold teal--text text--accent-3 text-caption">{{ cluster.post_ids.length }}</v-btn>
                    <v-btn small  outlined color="white">{{ cluster.id }}</v-btn>
                    <v-btn @click="copyClusterId"  small depressed icon><v-icon small>mdi-content-copy</v-icon></v-btn>
                    
                    <v-btn small outlined color="teal accent-3">earliest age: {{ sinceDate(cluster.earliest_post_date) }}</v-btn>
                    <v-btn small @click="select" class="font-weight-bold Dialog--text" color="teal accent-3">SELECT</v-btn>
                    <!-- <v-btn small @click="stashAndKeepInDeck" class="font-weight-bold Dialog--text" color="success">STASH & KEEP IN DECK</v-btn> -->
                    
                    <v-spacer></v-spacer>
                    <v-btn small text>Created at:{{ formatDate(cluster.created_at) }}</v-btn>
                    <v-btn v-if="formatDate(cluster.created_at) != formatDate(cluster.updated_at)" small text>Updated at:{{ formatDate(cluster.updated_at) }}</v-btn>

                </v-card-actions>

                <v-divider></v-divider>

                <TagsWidget 
                    :tags="cluster.tags"
                    :allow_delete="false"
                    class="ml-3 mt-1"
                />
                <LootWidget v-if="cluster.loot && cluster.loot.length > 0" 
                    :loot="cluster.loot"
                    :allow_delete="false"
                    class="ml-3"
                />
                
                <v-expansion-panels flat tile hover>
                    <v-expansion-panel >
                        <v-expansion-panel-header class="font-weight-bold teal--text text--accent-3"> 
                            {{cluster.title}}
                            <template v-slot:actions>
                                <v-icon :color="color">
                                    mdi-chevron-down-circle
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card flat outlined>
                                <v-card-text v-if="cluster.excerpts.length > 0" class="grey--text text--lighten-3">
                                   {{cluster.excerpts[0]}}
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

               

                <!-- Images -->
                <v-card flat class="d-flex overflow-y-auto pb-3 ml-3" key="images-grid" >

                    <v-card flat tile v-for="(iu,index) in cluster.img_urls" :key="'img-' + index" :ripple="false" height="80" width="150">
                        <v-img
                            :src="iu"
                            class="ml-2"
                            min-width="135"
                            height="80"
                            contain
                        ></v-img>
                    </v-card>
                </v-card>
    
                

            </v-card>
        </v-sheet>

        <v-snackbar centered v-model="snackbar" timeout="-1" :color="color">
            {{ message }}
            <v-btn text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import moment from "moment";
import deck_to_cluster from "@/common/cluster/deck_to_cluster";
import TagsWidget from '../../Tags/TagsWidget.vue'
import LootWidget from '../../Loot/LootWidget.vue'

export default {

    name: 'ClusterProposal',

    props: ['proposal_index', 'cluster', 'cl_index', 'cluster_header', 'cluster_header_key', 'ghost_parameters', 'clusters_count'],

    components: {
        TagsWidget,
        LootWidget,
    },

    data: () => ({
        loading: false,
        error: null,
        color: "teal accent-3",
        message: "",
        snackbar: false,
    }),

    methods: {

        select() {
            console.log("append cl_id=", this.cluster.id);
            this.appender();
        },

        // this function is called when the user wants to append the deck cluster to the es cluster
        appender: async function() {
            console.log('appender')

            this.loading = true;
            this.error = null;
            this.message = "";
            
            let max_missing_excerpts = 3 - this.cluster.excerpts.length;
          
            // enrich with cluster header
            let pids= [];
            // let vectors = [];
            let excerpts = [];
            for (let [index, p] of this.cluster_header.posts.entries()) {
                pids.push(p.id);
                if (index <  max_missing_excerpts) {
                    excerpts.push(deck_to_cluster.buildPostExcerpt(p, this.ghost_parameters.gpt_excerpt_tokens));
                }
            }

            let payload = {
                cluster_id: this.cluster.id,
                post_ids: pids,
                img_urls: deck_to_cluster.buildClusterImgUrls(this.cluster_header),
                tags: deck_to_cluster.buildClusterTags(this.cluster_header),
                loot: deck_to_cluster.buildClusterLoot(this.cluster_header),
                excerpts: excerpts,
            }

            //NOTE: no need of sending the deck cluster centroid

            try {
                let res = await this.$api.post("/ghost/recaller/append", payload);
                this.message = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data;
            } finally {
                this.loading = false;
            }

            // no change happened
            if (this.message == "no change happened") {
                this.snackbar = true;
            }

            // all good
            if (!this.error) {
                // remove header cluster from deck
                this.$emit("eventRemoveClusterFromDeck",this.cluster_header_key);
                // when done forget proposal
                this.$emit("eventForgetProposal", this.proposal_index);
            }
        },

        copyClusterId() {
            navigator.clipboard.writeText(this.cluster.id);
        },

        formatDate(date) {
            return moment(date).format("DD/MM/YY HH:mm");
        },

        sinceDate(date) {
            return moment(date).locale(this.$auth.retriveUserField("lang")).fromNow();
        },
    },

    computed: {
        sheetColor() {
            if (this.cluster.is_published) {
                return "blue";
            }

            return this.color;
        }
    }
}
</script>

<style>

</style>