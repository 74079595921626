<template>
    <div>
        <v-card tile flat v-if="post" class="d-flex" key="widgets-post" color="">
            
            <!-- Widgets -->
            <v-card flat class="mt-2" color="transparent" key="cluster-widgets">
                <VoteWidget 
                    v-if="post"
                    @eventVote="eventVote" 
                    :post="post" 
                    :index="p_index"
                    tab="subscriber"
                    stream="post"  
                    class="mt-1"
                ></VoteWidget>
            </v-card>

            <!-- Post -->
            <Post v-if="post"
                :key='"post_" + post.id'
                :post="post" 
                :index="p_index"
                :colorView ="color"
                :color_title_class = "colorTitleClass"
                tab="deck"
                @eventGoSingle="eventOpenSingleDialog"
                @eventGoTags="eventGoTags"
                @eventGoLoot="eventGoLoot"
            />

        </v-card>

    </div>
</template>

<script>


import VoteWidget from '../../../Vote/VoteWidget.vue'
import Post from '../../../Profiles/components/Post.vue'

export default {

    name: 'ClusterPost',

    props: ['cl_index','p_index', 'post','color'],

    components: {
        VoteWidget,
        Post,
    },

    data: () => ({
        title: null,
        description: null,
    }),

    created() {
    },


    methods: {

        // Vote
        // in this case only trash button activate the event
        eventVote() {
            this.$emit("eventVote", this.cl_index, this.p_index)
        },

        //* FRAMER
        // needed for publisher widget
        eventGoFramer() {
            this.$emit("eventGoFramer", this.cl_index, this.p_index);
        },

        // Go tools Tags
        eventGoTags() {
            this.$emit("eventGoTags", this.cl_index, this.p_index);
        },

        // Go tools Loot
        eventGoLoot() {
            this.$emit("eventGoLoot", this.cl_index, this.p_index);
        },

        // Go Source
        eventGoSource() {
            this.$emit("eventGoSource", this.cl_index, this.p_index);
        },

    
        //* Dialog Post Single
        eventOpenSingleDialog() {
            this.$emit("openClusterPostsSingleDialog", this.cl_index, this.p_index)
        },

    },

    computed: {

        colorTitleClass() {
            if (this.post.is_community) {
                return "purple--text text--accent-1"
            } else {
                return ""
            }
        }
    },
}
</script>

<style>

</style>