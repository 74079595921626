<template>
    <div>
        <v-sheet tile rounded outlined class="" :color="color">
            <v-card tile flat class="d-flex py-1" key="skimmer-cluster-header" >

                <!-- Cluster Meta-->
                <v-card flat outlined color="transparent" width="82px" class="px-1">

                    <!-- SHOW/hide cluster posts  -->
                    <v-btn v-if="cluster.posts.length > 1"  @click="switchShowCluster" small class="font-weight-bold Dialog--text ml-1 mt-0" :color="color">
                        #{{cluster.deck_cl_index}}
                    </v-btn>

                    <v-btn small class="ml-1 mt-1" outlined @click="eventUnskimmedCluster" :color="color"><v-icon color="success">mdi-alpha-f-circle</v-icon></v-btn>

                </v-card>

                <!-- avatar -->
                 <v-avatar v-if="avatar_url.length > 0"
                    rounded=""
                    size="170"
                    class="ml-2"
                >
                    <v-img v-if="cluster.posts.length > 0" :src="avatar_url" :key="avatar_url"></v-img>
                </v-avatar>

                <v-card flat class="" color="" width="100%" key="title-sources-translation">
                    
                    <!-- title -->
                    <v-card-title @click="switchShowCluster" style="cursor: pointer"  class="wordbreak text-body-1 font-weight-bold pt-1 pb-0 pl-2">
                        {{buildTitle}}
                    </v-card-title>

                    <v-card-actions class="pt-1 pb-2">

                        <!-- Cluster Posts Meta-->
                        <v-btn v-if="cluster.posts.length > 1" x-small fab class="font-weight-bold Dialog--text" color="lime">{{ cluster.posts.length }}</v-btn>

                        <!-- Skimmed count -->
                        <v-btn v-if="cluster.posts.length > 1" x-small fab class="font-weight-bold ml-1 mt-0" color="error">
                           {{skimmedPostsCount}}
                        </v-btn>

                        <!-- key -->
                        <v-btn small text color="white" outlined>{{ cluster.key }}</v-btn>

                        <!-- centroid radius -->
                        <v-btn x-small v-if="cluster.posts.length > 1" class="font-weight-bold" color="purple">Centroid Radius*1000: {{ buildClusterMaxCosineCentroidrRadius }}</v-btn>

                        <!-- Sources Widget-->
                        <SourcesWidget :posts="cluster.posts" :color="color" class="ml-2"/>
                        <v-spacer></v-spacer>
                        <!-- Translation Widget-->
                        <TranslationWidget
                            :post="cluster.posts[0]"
                            tab="cluster"
                            stream="post"
                            class="ml-4"
                            @eventTranslation="eventTranslation"
                        ></TranslationWidget>

                    </v-card-actions>

                    <DeckTagsWidget 
                        :cluster="cluster"
                        :cl_index="cl_index"
                        :tags_list="tags_list"
                        class="ml-2"
                    />
                    <DeckLootWidget
                        :cluster ="cluster"
                        :cl_index="cl_index"
                        :tags_list="tags_list"
                        class="ml-2 mt-1"
                    />

                </v-card>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
import cluster_vector_operations from "@/common/cluster/vector_operations";
import { mapGetters } from 'vuex';

import SourcesWidget from '../Deck/DeckCluster/DeckWidgetSources.vue'
import DeckTagsWidget from '../Deck/DeckCluster/DeckWidgetTags.vue'
import DeckLootWidget from '../Deck/DeckCluster/DeckWidgetLoot.vue'
import TranslationWidget from "../../Translations/TranslationWidget.vue";

export default {

    name:'SkimmerClusterHeader',

    props: ['cluster','cl_index','posts_count','color', 'tags_list'],

    components: {
        SourcesWidget,
        TranslationWidget,
        DeckTagsWidget,
        DeckLootWidget,
    },

    data: () => ({
       show_cluster: true,
    }),

    created() {
        if (this.cluster) {
            this.show_cluster = this.cluster.show;
        }
    },

    watch: {
        posts_count: {
            immediate: true,
            deep: true,
            handler() {
                // need to use a separate var to avoid infinite loop
                // can't use cluster
                if (this.posts_count == 1) {
                    this.eventUnskimmedCluster();
                }
            }
        },
    },

    methods: {

        switchShowCluster() {
            this.show_cluster = !this.show_cluster
            this.$emit("eventShowCluster", this.cluster.deck_cl_index, this.cl_index);
        },

        //* EVENTS
        eventUnskimmedCluster() {
            console.log("eventUnskimmedCluster", this.cl_index)
            this.$emit("eventUnskimmedCluster", this.cl_index)
        },

        // Translations
        eventTranslation(output_language, content, description, title) {
            console.log("eventTranslation", output_language, content.length, description.length,title)
            if (this.cluster.posts[0].translations.length > 0) {
                for (let tr of this.cluster.posts[0].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title = title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

    },

    computed: {
        
        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),

        avatar_url() {
            return this.cluster.posts.length > 0 ? this.cluster.posts[0].img_url : '';
        },

        buildTitle() {
            let title = ""
            if (this.cluster.posts.length > 0) {
                title = this.cluster.posts[0].title;

                if (this.trans_settings && this.trans_settings[this.cluster.posts[0].language] && this.trans_settings[this.cluster.posts[0].language].available.length > 0) {
                    for (let ol of this.trans_settings[this.cluster.posts[0].language].available) {
                        if (ol.trans_active) {
                            if (this.cluster.posts.length > 0 && this.cluster.posts[0].translations) {
                                for (let trans of this.cluster.posts[0].translations) {
                                    if (trans.output_language == ol.iso) {
                                        if (trans.title.length > 0) {
                                            title = "[" + trans.title.trim() + "]";
                                        }
                                    }
                                }
                            }
                        } 
                    }
                    
                }
            }
            return title
        },

        findMinimumDocLength() {
            let min_doc_length = 0;
            if (this.cluster.posts.length > 0) {
                min_doc_length = this.cluster.posts[0].doc_length;
            }
            for (let p of this.cluster.posts) {
                if (p.doc_length < min_doc_length) {
                    min_doc_length = p.doc_length
                }
            }
            return min_doc_length
        },

        buildClusterMaxCosineCentroidrRadius() {
            
            let vectors = [];
            let radius = 0;

            if (this.cluster.posts.length > 1) {
                // Collect all vectors
                for (let p of this.cluster.posts) {
                    vectors.push(p.vector);
                }

                // cosine similarity
                radius = cluster_vector_operations.maxCosineDistanceFromCentroid(this.cluster.centroid, vectors);
            }

           
            return Math.round(10000 * radius) / 10000;
        },

        skimmedPostsCount() {
            if (!this.cluster || !this.cluster.posts) return 0;

            return this.cluster.posts.filter(post => post.skimmed === true).length;
        },

    },

}
</script>

<style>
.wordbreak {
    word-break: normal;
}
</style>