const messages = {

    // Roles
    jjmaster: "admin",
    admin: "Admin_en",
    trainer_subscriber: "Coach Subscriber",
    trainer: "Trainer",
    deck: "Deck",
    subscriber: "Subscriber",
    reader: "Reader",
    slaver: "Slaver",
    tagger: "Tags",
    labeller: "Labelling",
    publisher: "Publisher",
    canvas: "Canvas",
    ghost: "Ghost",
    bank: "Bank",
    layers: "Layers",

    // NAV LIST
    Drawer: {
        home: "Home",
        sources: "Sources",
        dashboard: "Dashboard",
        classifiers: "Classers",
        settings: "Settings",
        packages: "Packages",
        packages_sets: "Packages Sets",
        trans_sets: "Translation Sets",
        manager: "Manager",
        tools: "Tools",
        engine: "Engine",
        logs: "Logs",
        tags: "Tags",
        blender: "Blender",
       
    },

    // sources
    sources: "Sources",
    sourcesNew: "New Source",
    sourcesEdit: "Edit Source",
    sourceRef: "Reference (Ex: EN012)",
   
    sourcesLanguages: "Languages (Ex: fr/en/...)",
    sourcesFamily: "Family (Ex: class1,class2,..)",
    sourcesAddurl: "Url prefix",
    sourcesFilterout: "Don't keep feed if terms in url ('term1,term2,...)",


    // project vocab
    country: "Country | Countries",
    city: "City | Cities",
    description: "Description",
    done: "checked | checked",
    folders: "My Folders",
    news: "News",
    noMorePost: "No post",
    pool: "Bookmarked",
    remaining: "remaining | remaining",
    set: "set",
    standby: "Standby",
    subscription: "Subscription",
    trash: "Trash",
    trashBatchButton: "trash all the posts ?",
    newBatch: "new batch ?",

    // SEARCH
    search: "Search",
    atLeast: "At least one of these",
    allThese: "All these",
    exact: "Exact phrase",
    none: "None of these",
    postNumber: "Posts Numbers",
    categories: "Categories",
    noResult: "No results",
    postFoundWith: "post(s) found with :",

    avatars: "Avatars",
    calendar: "Calendar",
    
}

// 👇️ default export
export default messages;