<template>
    <div>
        <v-list nav dense flat>
            <v-list-item link>
                <img style="cursor:pointer" @click="goHome" src="../../assets/logo_no_circle.png" height="40" class="mt-3"/> 
            </v-list-item>
        </v-list>
        
        <v-list
            nav dense flat 
            v-for="item in filterItems" :key="item.name"
            >
                <!-- no child item -->
                <v-list-item
                    v-if="!item.children"
                    :to="item.path"
                    link
                    color="teal accent-3"
                >
                    <v-list-item-icon>
                        <v-icon>{{item.mdi}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{getTitle(item.title)}}</v-list-item-title>
                </v-list-item>
                
                <!-- item with children -->
                <v-list-group v-else
                v-model="item.active"
                :prepend-icon="item.mdi"
                @click="goToView(item)"
                >
                    
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>{{getTitle(item.title)}}</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item 
                        dense nav link
                        v-for="child in item.children" :key="child.name"
                        :to="child.path"
                    >
                        <v-list-item-icon>
                            <v-icon>{{child.mdi}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{getTitle(item.title)}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
    </div>
</template>

<script>
import roles from '@/roles/roles'

export default {

    name: "NavList",

    data: () => ({
        isMobile: false,
        items: [

            //* Profiles

            // Trainer
            {
                name: "trainer", 
                path: "/main/trainer", 
                role: [roles.retrive().admin.tokenCode,roles.retrive().trainer_subscriber.tokenCode,roles.retrive().trainer.tokenCode,],
                title: "trainer", 
                mdi:"mdi-school",
                profile: true,
            },

            //* SLAVER
            {
                name: "slaver", 
                path: "/main/slaver", 
                role: [roles.retrive().admin.tokenCode,],
                title: "slaver", 
                mdi:"mdi-hospital-box-outline",
                profile: true,
            },

            //* Ghost
            {
                name: "ghost", 
                path: "/main/ghost", 
                role: [roles.retrive().admin.tokenCode,],
                title: "ghost", 
                mdi:"mdi-ghost",
            },

            //* Calendar
            {
                name: "calendar", 
                path: "/main/calendar", 
                role: [roles.retrive().admin.tokenCode,],
                title: "calendar", 
                mdi:"mdi-calendar-month",
                profile: true,
            },

            //Reader
            {
                name: "subscriber", 
                path: "/main/subscriber", 
                role: [roles.retrive().admin.tokenCode,roles.retrive().trainer_subscriber.tokenCode,roles.retrive().subscriber.tokenCode],
                title: "reader", 
                mdi:"mdi-shield-star",
                profile: true,
            },


            //* Layers
            {
                name: "layers", 
                path: "/main/layers", 
                role: [roles.retrive().admin.tokenCode,],
                title: "layers", 
                mdi:"mdi-layers-triple",
            },

            //* Bank
            {
                name: "banks", 
                path: "/main/bank", 
                role: [roles.retrive().admin.tokenCode,],
                title: "bank", 
                mdi:"mdi-web",
            },
            
            //* Searcher
            {
                name: "searcher", 
                path: "/main/searcher", 
                role: [roles.retrive().admin.tokenCode,],
                title: "search", 
                mdi:"mdi-magnify",
                profile: true,
            },

            //* Tags
             {
                name: "tags", 
                path: "/main/tags", 
                role: [roles.retrive().admin.tokenCode,],
                title: "Drawer.tags", 
                mdi:"mdi-tag",
                profile: true,
            },
            
            //* Sources
            {
                name: "sources", 
                path: "/main/sources", 
                role: [roles.retrive().admin.tokenCode,],
                title: "Drawer.sources", 
                mdi:"mdi-rss-box",
            },

            //* Classifiers
            {
                name: "classifiers", 
                path: "/main/classifiers", 
                role: [roles.retrive().admin.tokenCode,],
                title: "Drawer.classifiers", 
                mdi:"mdi-robot",
            },

            //* TOOLS
            {
                name: "tools_main", 
                path: "/main/tools_main", 
                role: [roles.retrive().admin.tokenCode,],
                title: "Drawer.tools", 
                mdi:"mdi-tools",
            },

            //* MANAGER
            {
                name: "manager", 
                path: "/main/manager", 
                role: [roles.retrive().admin.tokenCode,],
                title: "Drawer.manager", 
                mdi:"mdi-file-cog",
            },
    
            //* USERS
            {
                name: "users", 
                path: "/main/users", 
                role: [roles.retrive().admin.tokenCode,],
                title: "users", 
                mdi:"mdi-account-multiple",
            },

        ],

    }),

    created() {
        this.isMobile = this.$app.retriveIsMobile();
    },

    methods: {
        goToView(item) {
            this.$router.push({path: item.path});
        },

        getTitle(title) {
            return this.$i18n.t(title);
        },

        goHome() {
            this.$router.push("/");
        },
    },

    computed: {

        // mobile not display:
        // add item.hide_mobile = true
        // will bypass item with role && hide_mobile = true
        filterItems() {
            let fitems = [];
           
            Array.from(this.items).forEach(
                (item) => {
                    if (item.role) {
                        if (!item.hide_mobile || !this.isMobile) {
                            if(this.$rbac.drawerAuthorize(item.role)) {
                                if(item.children) {
                                    //children filter
                                    let fchildren = [];
                                    Array.from(item.children).forEach(
                                        (child) => {
                                            if(this.$rbac.drawerAuthorize(child.role)) {
                                                fchildren.push(child);
                                            }
                                        }
                                    )
                                    item.children = fchildren;              
                                }

                                //* FILTERS

                                // hide_desktop
                                if (item.hide_desktop && !this.isMobile) {
                                    return
                                }

                                // hide_mobile
                                if (item.hide_mobile && this.isMobile) {
                                    return
                                }

                                fitems.push(item);
                            }
                        }
                    } else {
                        //* if item.role = null => filters
                      
                        // filter 'hide_logged_in_user'
                        if (item.hide_logged_in_user && this.$rbac.is_user_logged_in()) {
                            return
                        }

                        // hide_desktop
                        if (item.hide_desktop && !this.isMobile) {
                            return
                        }

                        // hide_mobile
                        if (item.hide_mobile && this.isMobile) {
                            return
                        }

                        // hide if user not allowed to use this specifi kit

                        fitems.push(item);

                    }
                }
            );
            return fitems;
        },
        
    },

}
</script>

<style>

</style>