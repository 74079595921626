<template>
    <div :key="key">

        <!-- HOVER FOOTER -->
        <div  v-if="!dialogs" key="hover-footer">
            
            <!-- progress -->
            <v-progress-linear
                v-model="progress"
                height="36"
                color="amber"
                class="fixed-icon-progress Dialog--text"
                background-color="Dialog"
            >
                <v-btn depressed rounded class="font-weight-bold Dialog--text" color="amber">{{progress}}% - CL: {{outClustersCount}}/{{ ghost_clusters.length }}</v-btn>
            </v-progress-linear>
            
            <!-- metrics -->
            <v-btn
                tile
                color="grey darken-3"
                class="fixed-icon-deck pa-0"
            >
                <!-- Visibility -->
                <v-btn small v-if="see_out" @click="seeDeck" class="font-weight-bold Dialog--text" color="success">DECK</v-btn>
                <v-btn small v-else @click="seeDeck" class="font-weight-bold" outlined color="success">DECK</v-btn>
                <v-btn small v-if="!see_out" @click="seeOut" class="font-weight-bold Dialog--text ml-2" color="amber">OUT</v-btn>
                <v-btn small v-else  @click="seeOut" class="font-weight-bold ml-2" outlined color="amber">OUT</v-btn>
                
                <v-btn small depressed color="Dialog">Posts: {{totalPostsCount}}</v-btn>
                <v-switch dense class="ml-2 mr-1" @click="only_clusters = !only_clusters" color="success" :label='deckClustersWithMoreThanOnePostCount'></v-switch>
            </v-btn>
        </div>

        <!-- HOVER CLUSTER SWITH -->
        <v-card @click="switchToClusters" tile class="fixed-icon-cluster-switch" width="3%" height="100%" color="teal accent-3" key="hover-cluster-switch">
            
            <!-- <v-btn block class="font-weight-bold Dialog--text" color="teal accent-3">CLUSTER</v-btn> -->
        </v-card>


        <!-- DECK -->
        <v-sheet rounded outlined :color="see_out? 'amber' : 'success'" width="97%">
            <v-card flat outlined key="deck-tab" class="pt-0">
    
                <!-- cluster saving -->
                <v-progress-linear indeterminate color="teal accent-3" :active="clusters_loading"></v-progress-linear>
    
                <v-card v-if="parameters.deck_clusterer == 'w2c'" flat outlined class="my-1 mx-2">
                    <v-card-actions class="py-1">
                        <v-btn small color="purple"> max deck cosine distance: {{ 1 - buildClustersTotalMaxCosineDistance.cosine_distance }}</v-btn>
                        <v-btn small color="purple"> max deck angle: {{ buildClustersTotalMaxCosineDistance.angle }}°</v-btn>
                    </v-card-actions>
                </v-card>
    
                <v-card v-if="issues.length > 0" flat outlined class="mx-2">
                    
                    <v-btn small color="error">Batch Issues: {{ issues.length }}</v-btn>
                    <v-card-text v-for="i in issues" :key="'issue-' + i.key" class="error--text">
                        <v-row>
                            <v-col cols="12" class="error--text">{{ i }}</v-col>
                        </v-row>
                    </v-card-text>
                    
                </v-card>
    
                 <!-- cluster_error -->
                <v-card v-if="clusters_error" flat color="transparent" key="deck-error-message">
                    <!-- error msg -->
                    <v-card-text class="ml-5 mt-2 error--text">API Error: {{ clusters_error }}</v-card-text>
                </v-card>
    
                <!-- Clusters -->
                <div v-if="clusters && clusters.length > 0" key="draggable-clusters">
                    <v-card v-for="(cluster, cl_index) in clusters" :key="'cl_' + cl_index" class="">
                       
                        <!-- Show according to continents -->
                        <span v-if="parameters.continent == 'all' || cluster.continent_name == parameters.continent">
    
                            <!-- Show Only Clusters with multiple posts -->
                            <span v-if="(only_clusters && cluster.posts.length > 1) || !only_clusters">


                                <!-- Cluster -->
                                <v-sheet :key="'sheet-cluster-' + cl_index" outlined rounded
                                    v-if="(cluster.posts.length > 1 && cluster.is_out == see_out) || (cluster.posts.length == 1 && cluster.posts[0].is_out == see_out)"
                                    :color="sheetClusterColor(cl_index)" 
                                    class="ma-1"
                                >
                                    
                                    <v-card flat class="pb-0" color="" :key="'cluster-' + cl_index">
                                        
                                        <!-- cluster header-->
                                        <ClusterHeader v-if="cluster.posts.length > 0" :key="'cluster-header-' + cl_index"
                                            :clusters="clusters" 
                                            :cluster="cluster" 
                                            :cl_index="cl_index"
                                            :tags_list="tags_list" 
                                            :parameters="parameters"
                                            @eventShowCluster="eventShowCluster" 
                                            @eventSwitchDeckOut="eventSwitchDeckOut"
                                            @eventChangeContinent="eventChangeContinent"
                                            @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                                            @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                                            @eventMergeClusters="eventMergeClusters"
                                            @eventOpenPublisherDialog="eventOpenPublisherDialog"
                                            @eventSortClusters="eventSortClusters"
                                            @eventUpdateCluster="eventUpdateCluster"
                                        />
    
                                        <!-- Posts -->
                                        <div v-if="cluster.show" 
                                           
                                            key="draggable-cluster-posts"
                                        >
    
                                            <div v-for="(post, p_index) in cluster.posts" :key="'cl' + cl_index + '-p_' + post.id" class="pb-1">
    
                                                <!-- Post -->
                                                <v-sheet outlined class="mx-1 mt-1" 
                                                    v-if="post.is_out == see_out" 
                                                    :color="sheetPostColor(cl_index, p_index)"
                                                >
                                                    <v-card tile flat color="" class="d-flex" key="cluster-post-and-widget">
                                                        <!-- cluster widget -->
                                                        <ClusterPostWidget :key="'widget_' + cl_index + '-p_' + post.id"
                                                            :post="post" 
                                                            :cl_index="cl_index" 
                                                            :p_index="p_index"
                                                            :cluster_posts_count="cluster.posts.length"
                                                            :pids_to_add="cluster.pids_to_adds"
                                                            @eventPostInNewCluster="eventPostInNewCluster" />
    
                                                        <ClusterPost :key="'post_' + cl_index + '-p_' + post.id"
                                                            :post="post" :cl_index="cl_index" :p_index="p_index"
                                                            :color="sheetPostColor(cl_index, p_index)"
                                                            @eventGoPublisher="eventGoPublisher" 
                                                            @eventGoLoot="eventGoLoot"
                                                            @eventGoTags="eventGoTags" 
                                                            @eventVote="eventVote"
                                                            @openClusterPostsSingleDialog="openClusterPostsSingleDialog" />
                                                    </v-card>
                                                </v-sheet>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-sheet>
    
                            </span>
    
                        </span>
                    </v-card>
                </div>
            </v-card>
        </v-sheet>

        

        <!-- Dialogs -->
        <v-dialog v-model="dialogs" key="deck-dialogs" fullscreen overlay-opacity="">
            <v-card flat color="" key="deck-dialogs-card">

                <!-- Cluster Post Single -->
                <DialogClusterSingle v-if="cluster_single_dialog" 
                    :cluster_single="cluster_single"
                    @eventCloseClusterPostDialog="eventCloseClusterPostDialog" 
                    @eventGoPublisher="eventGoPublisher"
                    @eventGoTags="eventGoTags" 
                    @eventGoLoot="eventGoLoot" 
                    @eventVote="eventVote"
                    @eventGoSource="eventGoSource"
                    :color="sheetPostColor(cluster_single.cl_index, cluster_single.p_index)" 
                />

                <!-- Publisher Dialog -->
                <DialogPublisher v-if="publisher_dialog" 
                    :cluster="publisher.cluster" 
                    :cl_index="publisher.cl_index"
                    :from_calendar="from_calendar" 
                    @eventClosePublisherDialog="eventClosePublisherDialog" 
                />

            </v-card>
        </v-dialog>

        <!-- snackbar no last posts-->
        <v-snackbar centered v-model="last_snackbar" timeout="1000" color="blue">
            <span class="font-weight-bold Dialog--text">No Last Posts</span>
            <template v-slot:action="{ attrs }">
                <v-btn small class="Dialog--text" text v-bind="attrs" @click="snackbar = false">
                    {{ $i18n.t("close") }}
                </v-btn>
            </template>
        </v-snackbar>

        <!-- snackbar: go cluster batch -->
        <v-snackbar centered outlined v-model="cluster_batch_snackbar" timeout="-1" color="teal accent-3">
            <!-- Create clusters batch -->
            <v-btn 
                small
                @click="createClustersBatch" 
                class="Dialog--text font-weight-bold ml-2" 
                color="teal accent-3"
                :loading="clusters_loading" 
                :disabled="clusters_loading"
            >
                BATCH CLUSTERS
            </v-btn>
            <template v-slot:action="{ attrs }">
                <v-btn small class="teal--text text--accent-3" v-bind="attrs" @click="cluster_batch_snackbar = !cluster_batch_snackbar">
                    {{ $i18n.t("close") }}
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar centered v-model="ok_snackbar" color="success">
            <span class="font-weight-bold Dialog--text">OK</span>
        </v-snackbar>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ghost from "@/common/ghost";
import deck from "@/common/deck";
import deck_to_cluster from "@/common/cluster/deck_to_cluster";
import vector_operations from '../../../common/cluster/vector_operations';

import ClusterHeader from './DeckCluster/DeckClusterHeader.vue'
import ClusterPostWidget from './DeckCluster/DeckClusterPostWidget.vue'
import ClusterPost from './DeckCluster/DeckClusterPost.vue'

import DialogClusterSingle from './DialogClusterSingle.vue'
import DialogPublisher from './DialogPublisher.vue'


export default {

    name: 'DeckTab',

    components: {
        ClusterHeader,
        ClusterPostWidget,
        ClusterPost,
        DialogClusterSingle,
        DialogPublisher,
    },

    props: ['ghost_clusters','parameters','calendar','tags_list', 'tab'],

    data: () => ({

        loading: false,
        error: null,

        last_snackbar: false,
        cluster_batch_snackbar: false,

        clusters_loading: false,
        clusters_error: null,
        clusters_batch_done: false,
        ok_snackbar: false,

        // paging
        scrolling: 0,

        // deck visibility
        see_out: false,
        only_clusters: false,

        //* Deck
        clusters: [],
        post_ids: [],

        //* Persistence
        storage_clusters: null,

        // Dialogs
        dialogs: false,

        //cluster post
        cluster_single_dialog: false,
        cluster_single: {
            cl_index: null,
            p_index: null,
            post: null,
        },

        // publisher
        publisher_dialog: false,
        publisher: {
            cluster: null,
            cl_index: null,
        },

        from_calendar: false,

        // cluster
        logs: [],
        issues: [],

        // force re-render
        key: 0,
    }),

    created() {
        // this.parameters = deck.retriveParameters();
        this.scrolling = ghost.retriveDeckScroll()
        window.scrollTo(0, this.scrolling)
    },


    watch: {

        ghost_clusters: {
            immediate: true,
            deep: true,
            handler() {
                this.scrolling = ghost.retriveDeckScroll();
                setTimeout(() => {
                    this.clusters = this.ghost_clusters;
                    window.scrollTo(0, this.scrolling);
                }, 500);

            },
        },


        calendar:  {
            immediate: true,
            deep: true,
            handler() {
                // console.log("watcher calendar=", this.calendar)
                if (this.calendar.post_id.length > 0) {
                    this.buildClusterFromPostIds(this.calendar.post_id);
                    this.publisher_dialog = true;
                    this.dialogs = true;
                    this.from_calendar = true;
                } else if (this.calendar.post_ids.length > 0) {
                    this.buildClusterFromPostIds(this.calendar.post_ids);
                    this.publisher_dialog = true;
                    this.dialogs = true;
                    this.from_calendar = true;
                }
            },
        },

        progress: {
            immediate: true,
            handler() {
                if (this.progress == 100 && this.cluster_batch_snackbar == false && this.clusters_batch_done == false) {
                    this.cluster_batch_snackbar = true;
                }
            }
        },

        tab: {
            immediate: true,
            handler() {
                if (this.tab == 3) {
                    this.scrolling = ghost.retriveDeckScroll()
                    window.scrollTo(0, this.scrolling)
                }
            }
        }
    },

    methods: {

        //* Dialogs

        // Cluster Post
        openClusterPostsSingleDialog(cl_index, p_index) {
            console.log("openClusterPostsSingleDialog", cl_index, p_index);
            this.dialogs = true;
            this.cluster_single_dialog = true;
            this.cluster_single.post = this.clusters[cl_index].posts[p_index];
            this.cluster_single.cl_index = cl_index;
            this.cluster_single.p_index = p_index;
            this.scrolling = window.scrollY;
            deck.setClusterDialog(true);
            deck.setClusterSingle(this.clusters[cl_index].posts[p_index]);
            deck.setClusterIndex(cl_index);
            deck.setClusterPostIndex(p_index);
            ghost.setDeckScroll(window.scrollY);
        },

        eventCloseClusterPostDialog() {
            console.log("eventCloseClusterPostDialog");
            this.dialogs = false;
            this.cluster_single_dialog = false;
            this.cluster_single.post = null;
            this.cluster_single.cl_index = 0;
            this.cluster_single.p_index = 0;
            deck.setClusterDialog(false);
            deck.setClusterSingle(null);
            deck.setClusterIndex(0);
            deck.setClusterPostIndex(0);
            this.scrolling = window.scrollY;
        },


        eventGoPublisher(cl_index, p_index) {
            console.log("eventGoPublisher", this.clusters[cl_index].posts[p_index].id)
            ghost.setDeckScroll(window.scrollY);
            this.$router.push({ path: "/main/publisher/" + this.clusters[cl_index].posts[p_index].id })
        },

        eventGoTags(cl_index, p_index) {
            ghost.setDeckScroll(window.scrollY);
            this.$router.push({ path: "/main/tools_post_tags_tab/" + this.clusters[cl_index].posts[p_index].id })
        },

        eventGoLoot(cl_index, p_index) {
            ghost.setDeckScroll(window.scrollY);
            this.$router.push({ path: "/main/tools_post_loot_tab/" + this.clusters[cl_index].posts[p_index].id })
        },

        eventGoSource(cl_index, p_index) {
            ghost.setDeckScroll(window.scrollY);
            const routeData = this.$router.resolve({ path: "/main/source/" + this.clusters[cl_index].posts[p_index].source_ref });
            window.open(routeData.href, '_blank');
        },

        eventMergeClusters(from_cl_index, to_cl_index) {
            ghost.setDeckScroll(window.scrollY);
            console.log("Deck - eventMergeClusters", from_cl_index, to_cl_index);
            this.$emit("eventMergeClusters", from_cl_index, to_cl_index);
        },

        eventSortClusters() {
            this.$emit("eventSortClusters");
        },

        eventUpdateCluster(cl_index, cluster) {
            this.key ++;
            this.$emit("eventUpdateCluster", cl_index, cluster);
        },


        // publisher
        eventOpenPublisherDialog(cl_index) {
            ghost.setDeckScroll(window.scrollY);
            this.dialogs = true;
            this.publisher_dialog = true;
            this.publisher.cluster = this.clusters[cl_index];
            this.publisher.cl_index = cl_index;
        },

        eventClosePublisherDialog() {
            ghost.setDeckScroll(window.scrollY);
            console.log("eventClosePublisherDialog")
            this.dialogs = false;
            this.publisher_dialog = false
            this.publisher.cluster = null;
            this.publisher.cl_index = null;
            this.$emit("eventFetchUpdatedPosts")
        },


        // * Calendar function
        // when we are building a design cluster for editing a event in calendar or publishing a post from news
        // publisher dialog would be open
        // we only look for the posts since we have already the clusters' tree
        buildClusterFromPostIds: async function (pids) {

            this.loading = true;
            this.error = null;
            
            let cluster = {
                posts: [],
            }

            for (let id of pids) {
                try {
                    let res = await this.$api.get("/posts/" + id);
                    // the api returns an array
                    for (let p of res.data) {
                        cluster.posts.push(p)
                    }

                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = data.message;
                }
            }

            this.loading = false;
            this.publisher.cluster = cluster;
            this.publisher.cl_index = -1;
        },

        //* DRAGGABLE

        //* Visibility
        seeOut() {
            this.see_out = true;
        },

        seeDeck() {
            this.see_out = false;
        },

        //* CLUSTERS

        // new cluster form one single post
        eventPostInNewCluster(cl_index, p_index, post) {
            ghost.setDeckScroll(window.scrollY);
            this.$emit("eventPostInNewCluster", cl_index, p_index, post);
        },

        eventShowCluster(cl_index) {
            ghost.setDeckScroll(window.scrollY);
            this.$emit("eventShowCluster", cl_index);
        },

        eventSwitchDeckOut(cl_index) {
            ghost.setDeckScroll(window.scrollY);
            this.clusters[cl_index].is_out = !this.clusters[cl_index].is_out;
            this.clusters[cl_index].show = false;
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                p.is_out = this.clusters[cl_index].is_out;
                posts.push(p);
            }
            this.clusters[cl_index].posts = posts;
            this.$emit("eventUpdateClusters")
        },

        eventChangeContinent(selected_continent, order, cl_index) {
            ghost.setDeckScroll(window.scrollY);
            console.log("eventChangeContinent", selected_continent, order, cl_index);
            this.clusters[cl_index].continent_name = selected_continent;
            this.clusters[cl_index].continent_order = order;
            this.$emit("eventUpdateClusters");
            this.$emit("eventSortClusters");
        },

        eventRemoveTagFromCluster(cl_index, ref) {
            ghost.setDeckScroll(window.scrollY);
            this.$emit("eventRemoveTagFromCluster", cl_index, ref);
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            ghost.setDeckScroll(window.scrollY);
            this.$emit("eventRemoveTagFromCluster", cl_index, ref);
        },

        eventVote(cl_index, p_index) {
            this.$emit("eventVote", cl_index, p_index)
            this.eventCloseClusterPostDialog();
        },


        // SWITCH
        switchToClusters() {
            console.log("switchToClusters")
            ghost.setDeckScroll(window.scrollY);
            this.$emit("eventSwitchToClusters");
        },

        //* COLORS
        sheetClusterColor(cl_index) {
            let c = ""

            if (this.clusters[cl_index].is_already_saved) {
                c = "purple";
                return c
            }

            if (this.clusters[cl_index].is_saved) {
                c = "teal accent-3";
                return c
            }

            for (let p of this.clusters[cl_index].posts) {
                if (p.is_error) {
                    c = "orange";
                    return c
                }
                if (p.is_published) {
                    c = "blue lighten-1";
                    return c
                }
                if (p.is_scheduled) {
                    c = "lime accent-2";
                    return c
                }
            }

            if (!this.clusters[cl_index].is_out) {
                c = "success";
            } else {
                c = "amber";
            }

            return c
        },

        sheetPostColor(cl_index, p_index) {
            
            if (this.clusters[cl_index].is_saved) {
                return "teal accent-3"
            }

            if (this.clusters[cl_index].posts[p_index]) {

                let p = this.clusters[cl_index].posts[p_index];

                if (p.is_error) {
                    return "orange"
                }

                if (p.is_published) {
                    return "blue lighten-1"
                }

                if (p.is_scheduled) {
                    return "lime accent-2"
                }

            }

            if (this.clusters[cl_index].is_out) {
                return "amber"
            } else {
                return "success"
            }

        },

        //* CLUSTERS BATCH
        createClustersBatch: async function () {
            console.log("creating clusters");
            
            this.clusters_loading = true
            this.clusters_error = null;
            this.cluster_batch_snackbar = false;
            this.see_out = true;

            if (this.clusters.length > 0) {

                // build a dict[key]=cl_index
                let clusters_dict = {}
                for (let i = 0; i < this.clusters.length; i++) {
                    clusters_dict[this.clusters[i].key] = i
                }

                let clusters = this.buildClustersPayload(this.clusters);

                if (clusters.length == 0) {
                    this.clusters_error = "clusters length == 0"
                    this.clusters_loading = false
                    return
                }

                let payload = {
                    clusters: clusters,
                }
                
                console.log(payload.clusters.length)

             
                try {
                    let res = await this.$api.post("/clusters/deck_save", payload);
                    this.logs = res.data;
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.clusters_error = data.message;
                } finally {
                    this.clusters_loading = false
                    this.clusters_batch_done = true;
                    this.ok_snackbar = true;
                    
                    // process logs using dict
                    for (let l of this.logs) {
                        let cl_index = clusters_dict[l.key]
                        if (l.already) {
                            this.clusters[cl_index].is_already_saved = true;
                            this.clusters[cl_index].is_saved = true;
                        } else if (l.success) {
                            this.clusters[cl_index].is_saved = true;
                        } else if (l.pids_to_add.length > 0) {
                            this.clusters[cl_index].pids_to_add = l.pids_to_add.length;
                        } else {
                            this.clusters[cl_index].error = l.error;
                        }
                    }
                }
                this.key ++;
            } else {
                this.clusters_error = "clusters length == 0"
            }

        },

        buildClustersPayload(clusters) {

            let payload = []
            for (let cl of clusters) {
                if (cl.is_out && !cl.is_saved) {
                    let payload_cluster = deck_to_cluster.build(cl, this.parameters.gpt_excerpt_tokens);
                    payload.push(payload_cluster);
                }
            }

            return payload
        },

        batchLogsAnalyzers() {
            if (this.logs.length > 0) {
                for (let l of this.logs) {
                    if (l.error && l.error.length > 0) {
                        this.issues.push(l);
                    }
                }
            }
        },

    },

    computed: {

        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),

        

        deckClustersCount() {
            return this.clusters.filter(c => !c.is_out).length;
        },

        outClustersCount() {
            return this.clusters.filter(c => c.is_out).length;
        },

        deckPostsCount() {
            return this.clusters.reduce((count, c) => count + c.posts.filter(p => !p.is_out).length, 0);
        },
        
        totalPostsCount() {
            return this.clusters.reduce((count, cluster) => count + cluster.posts.length, 0);
        },

        outPostsCount() {
            return this.clusters.reduce((count, c) => count + c.posts.filter(p => p.is_out).length, 0);
        },

        deckClustersWithMoreThanOnePostCount() {
            return String(this.clusters.filter(c => !c.posts || c.posts.length > 1).length);
        },

        progress() {
            let dcc = this.deckClustersCount;
            return Math.round(100 * (this.clusters.length - dcc) /this.clusters.length);
        },

        buildClustersTotalMaxCosineDistance() {

            let vectors = [];
            let cosine = 0;

            if (this.clusters.length > 0) {
                for (let cl of this.clusters) {
                    if (cl.posts.length > 1) {
                        // Collect all vectors
                        for (let p of cl.posts) {
                            vectors.push(p.vector);
                        }
                    }
                }

                // cosine similarity
                cosine = vector_operations.maxCosineDistance(vectors)
            }

            return {
                cosine_distance: Math.round(1000 * cosine) / 1000,
                angle: Math.round(10 * Math.acos(1 - cosine) * (180 / Math.PI)) / 10,
            }
        }


    }

}
</script>

<style>
.fixed-icon-deck {
    position: fixed;
    bottom: 0%; /* Adjust this to control vertical positioning */
    right: 0%;  /* Adjust this to control horizontal positioning */
    z-index: 1000; /* Ensure it's above other content */
    width: 24%;
}

.fixed-icon-progress {
    position: fixed;
    bottom: 0%; /* Adjust this to control vertical positioning */
    left: 0px;  /* Adjust this to control horizontal positioning */
    z-index: 900; /* Ensure it's above other content */
    width: 76%;
}

.fixed-icon-cluster-switch {
    position: fixed;
    bottom: 0%;
    z-index: 900;
    right: 0px;
}
</style>