export default {

    //* VECTOR OPERATIONS

    buildClusterCentroid(cl) {
        // Handle edge cases
        if (cl.posts.length === 0) {
            return [];
        } else if (cl.posts.length === 1) {
            return cl.posts[0].vector;
        }
    
        // Initialize centroid with the same length as the vectors
        const vectorLength = cl.posts[0].vector.length;
        const centroid = new Array(vectorLength).fill(0);
    
        // Sum up all the vectors
        cl.posts.forEach(p => {
            p.vector.forEach((val, index) => {
                centroid[index] += val;
            });
        });
    
        // Divide by the number of posts to get the average
        const numPosts = cl.posts.length;
        for (let i = 0; i < centroid.length; i++) {
            centroid[i] /= numPosts;
        }
    
        return centroid;
    },
    

    // Calculate the centroid of a set of vectors
    buildCentroidWithVectors(vectors) { 
        
        if (!vectors || vectors.length === 0 || vectors[0].length === 0) {
            throw new Error("Vectors array is empty or invalid.");      
        }

        const vectorLength = vectors[0].length;
        
        // Step 1: Calculate the centroid
        const centroid = new Array(vectorLength).fill(0);
        vectors.forEach(vector => {
            vector.forEach((val, index) => {
                centroid[index] += val; 
            }
            );
        });

        for (let i = 0; i < centroid.length; i++) { 
            centroid[i] /= vectors.length;
        }

        return centroid;
    },


    // Calculate the maximum pairwise cosine distance
    cosineDistance(vectorA, vectorB) {
        if (vectorA.length !== vectorB.length) {
            throw new Error("Vectors must be of the same length.");
        }
    
        // Step 1: Compute the dot product of vectorA and vectorB
        let dotProduct = 0;
        for (let i = 0; i < vectorA.length; i++) {
            dotProduct += vectorA[i] * vectorB[i];
        }
    
        // Step 2: Compute the magnitude (norm) of each vector
        let magnitudeA = Math.sqrt(vectorA.reduce((sum, val) => sum + val * val, 0));
        let magnitudeB = Math.sqrt(vectorB.reduce((sum, val) => sum + val * val, 0));

        if (magnitudeA === 0 || magnitudeB === 0) {
            return 1;  // If either vector has zero magnitude, treat them as completely dissimilar
        }        
    
        // Step 3: Compute cosine similarity
        let cosineSimilarity = dotProduct / (magnitudeA * magnitudeB);
    
        // Step 4: Compute cosine distance
        return 1 - cosineSimilarity;
    },

    // returns the maximum cosine distance from the provided centroid for a set of vectors
    maxCosineDistanceFromCentroid(centroid, vectors) {
        
        if (!centroid || centroid.length === 0 || vectors.length === 0) {
            throw new Error("Centroid or vectors array is empty or invalid.");
        }
    
        let maxCosineDistance = 0;
    
        for (let vector of vectors) {
            const cosineDist = this.cosineDistance(vector, centroid);
            if (cosineDist > maxCosineDistance) {
                maxCosineDistance = cosineDist;
            }
        }
    
        return maxCosineDistance;
    },

    // among a set of vectors, returns the maximum radius from the calculated centroid
    maxClusterRadiusFromVectors(vectors) {
        if (!vectors || vectors.length === 0 || vectors[0].length === 0) {
            throw new Error("Vectors array is empty or invalid.");
        }
    
        const centroid = this.buildCentroidWithVectors(vectors);
    
        // Step 2: Calculate the maximum cosine distance from the centroid
        let maxCosineDistance = 0;
        for (let vector of vectors) {
            const cosineDist = this.cosineDistance(vector, centroid);
            if (cosineDist > maxCosineDistance) {
                maxCosineDistance = cosineDist;
            }
        }
    
        return maxCosineDistance;
    },

    maxCosineDistanceFromVectors(vectors) {
        if (!vectors || vectors.length === 0 || vectors[0].length === 0) {
            throw new Error("Vectors array is empty or invalid.");
        }
    
        // Calculate pairwise cosine distances
        let maxCosineDistance = 0;
    
        // Compare each pair of vectors
        for (let i = 0; i < vectors.length; i++) {
            for (let j = i + 1; j < vectors.length; j++) {
                const cosineDist = this.cosineDistance(vectors[i], vectors[j]);
                if (cosineDist > maxCosineDistance) {
                    maxCosineDistance = cosineDist;
                }
            }
        }
    
        return maxCosineDistance; // This is the maximum pairwise cosine distance (similar to epsilon)
    },
    

    // Calculate the maximum pairwise cosine distance
    maxCosineDistance(vectors) {

        let maxDistance = 0; // Initialize to the lowest possible cosine distance (0)

        for (let i = 0; i < vectors.length; i++) {
            for (let j = i + 1; j < vectors.length; j++) {
                const cosineDist = this.cosineDistance(vectors[i], vectors[j]);
                if (cosineDist > maxDistance) {
                    maxDistance = cosineDist;
                }
            }
        }

        return maxDistance;
    },

    
    spreadAngle(cosine) {
        if (cosine < -1) cosine = -1;
        if (cosine > 1) cosine = 1;
        return 1 / Math.acos(1 - cosine) * (180 / Math.PI);
    },

    // Calculate the maximum pairwise distance
    maxDistance(vectors) {

        let max_distance = 0;

        for (let i = 0; i < vectors.length; i++) {
            for (let j = i + 1; j < vectors.length; j++) {
                const distance = Math.sqrt(vectors[i].reduce((sum, val, index) => sum + (val - vectors[j][index]) ** 2, 0));

                if (distance > max_distance) {
                    max_distance = distance;
                }
            }
        }

        return max_distance
    }

}