export default {

    //* WHen we want to see a single post associated with a specific cluster
    //* Cluster posts single
    cluster_dialog: false,
    cluster_single: null,
    cluster_index: 0,
    post_index: 0,

    setClusterDialog(bool) {
        this.cluster_dialog = bool;
    },

    setClusterSingle(obj) {
        this.cluster_single = obj;
    },

    setClusterIndex(i) {
        this.cluster_index = i;
    },

    setClusterPostIndex(i) {
        this.post_index = i;
    },

    retriveClusterDialog() {
        return this.cluster_dialog;
    },

    retriveClusterSingle() {
        return this.cluster_single;
    },

    retriveClusterIndex() {
        return this.cluster_index;
    },

    retriveClusterPostIndex() {
        return this.post_index;
    },

}