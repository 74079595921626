<template>
    <div>
            <v-card flat class="d-flex justify-start flex-wrap">
              
                <v-chip v-if="posts.length > 0" small outlined class="mr-1" :color="color">{{ sinceDate(posts[0].date)}}</v-chip>

                <v-chip v-for="(s,index) in buildSources" :key="index" @click="goArticle(s.url)" small outlined class="mr-1 mb-1" :color="color">
                    <country-flag :country='s.country' size='small' :rounded="true" class=""/> 
                    <span class="pTagText--text">
                        {{s.name}} 
                        <span v-if="s.count > 1">({{s.count}})</span>
                    </span>
                    <span class="ml-1">{{ s.country }}</span>
                </v-chip>
              
            </v-card>
    </div>
</template>

<script>
import moment from "moment";

export default {

    name: 'SourcesWidget',

    props: ['posts', 'cl_index', 'color'],

    data: () => ({

    }),

    methods: {
        formatPostDate(post) {
            return moment(post.date).format("DD/MM/YY")
        },

        sinceDate(date) {
            return moment(date).locale(this.$auth.retriveUserField("lang")).fromNow();
        },

        goArticle(url) {
            window.open(url, '_blank');
        },

    },

    computed: {
        buildSources() {
            let sources =[]
            if (this.posts) {
                for (let p of this.posts) {

                    let existingSources = sources.find(s => s.ref === p.source_ref);
                    
                    if (existingSources) {
                        existingSources.count += 1;
                    } else {
                        sources.push(
                            {
                                ref: p.source_ref,
                                name: p.source_name,
                                country: p.country,
                                count: 1,
                                url: p.url
                            }
                        )
                    }
                }
            }

            return sources
        }
    }

}
</script>

<style>
/* .pTagText--text defines like Dialog--text */
</style>