const state = {
    parameters: {
        search: "",
        page: 1,
        publications: [],
        total: 0,
        scroll: 0,
    }

};

const getters = {

    getPublicationsParameters() {
        return state.parameters;
    },

};

const actions = {

    updatePublicationsParameters: function({ commit }, obj) {
        commit('setPublicationsParameters', obj);
    },

    resetPublicationsParameters: function({ commit }) {
        commit('setResetPublicationsParameters');
    },

};

const mutations = {

    setPublicationsParameters: function(state, obj) {
        state.parameters = obj;
    },

    setResetPublicationsParameters: function(state) {
        
        state.parameters = {
            search: "",
            page: 1,
            publications: [],
            total: 0,
            scroll: 0,
        }
        
    },

};


export default {
    state,
    getters,
    actions,
    mutations,
};
