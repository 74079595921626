<template>
    <div>
        <v-sheet tile rounded outlined class="" :color="color">
            <v-card tile flat class="d-flex py-1" key="skimmer-cluster-header" >

                <!-- Cluster Meta-->
                <v-card flat outlined color="transparent" width="82px" class="px-1">

                    <!-- SHOW/hide cluster posts  -->
                    <v-btn @click="switchShowMergedCluster" small class="font-weight-bold Dialog--text ml-1 mt-0" :color="color">
                        #{{cl_index}}
                    </v-btn>

                    <v-btn small outlined @click="forget" class="ml-1 mt-1" :color="color"><v-icon color="success">mdi-alpha-f-circle</v-icon></v-btn>

                </v-card>

                <!-- avatar -->
                <v-avatar v-if="avatar_url.length > 0"
                    rounded=""
                    size="140"
                    class="ml-2"
                >
                    <v-img v-if="cluster.posts.length > 0" :src="avatar_url" :key="avatar_url"></v-img>
                </v-avatar>

                <v-card flat class="" color="" width="100%" key="title-sources-translation">
                    
                    <!-- title -->
                    <v-card-title @click="switchShowMergedCluster" style="cursor: pointer"  class="wordbreak text-body-1 font-weight-bold pt-1 pb-0 pl-2">
                        {{buildTitle}}
                    </v-card-title>

                    <v-card-actions class="pt-1 pb-2">

                        <!-- Cluster Posts Meta-->
                        <v-btn x-small fab class="font-weight-bold Dialog--text" color="lime">{{ cluster.posts.length }}</v-btn>

                        <!--Merge -->
                        <v-btn x-small @click="dialog = true" :color="color" class="Dialog--text font-weight-bold">Merge</v-btn>

                        <!-- key -->
                        <v-btn small text outlined color="white">{{ cluster.key }}</v-btn>

                        <!-- centroid radius -->
                        <v-btn x-small v-if="cluster.posts.length > 1" color="purple">Cluster Centroid Radius: {{ buildClusterMaxCosineCentroidRadius }}</v-btn>

                        <!-- Sources Widget-->
                        <SourcesWidget :posts="cluster.posts" :color="color" class="ml-2"/>
                        <v-spacer></v-spacer>
                        <!-- Translation Widget-->
                        <TranslationWidget
                            :post="cluster.posts[0]"
                            tab="cluster"
                            stream="post"
                            class="ml-4"
                            @eventTranslation="eventTranslation"
                        ></TranslationWidget>

                    </v-card-actions>
                </v-card>

            </v-card>
        </v-sheet>

        <v-dialog v-model="dialog" width="400">
            <v-sheet rounded outlined :color="color">
                
                <v-card v-if="proposals && ghost_clusters" flat class="pa-2">
                    
                    <div small v-for="(proposal, pr_index) in proposals" :key="'proposal_' + pr_index">
                        pr{{ pr_index }}-
                        <v-btn small v-for="cl_index in proposal" :key="'cluster_' + cl_index" 
                            class="font-weight-bold ml-1 mb-1" 
                            :color="clusterColor(cl_index)"
                            @click="eventMergeClusters(ghost_clusters[cl_index].key)"
                        >
                            {{ ghost_clusters[cl_index].key }}
                        </v-btn>
                    </div>
                    <v-divider></v-divider>
                    <v-card-actions class="py-1">
                        <v-spacer></v-spacer>
                        <v-btn small
                            :color="color"
                            text
                            @click="dialog = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-dialog>
    </div>
</template>

<script>
import cluster_vector_operations from "@/common/cluster/vector_operations";
import { mapGetters } from 'vuex';

import TranslationWidget from "../../Translations/TranslationWidget.vue";
import SourcesWidget from '../Deck/DeckCluster/DeckWidgetSources.vue';

export default {

    name: 'MergerClusterHeader',

    components: {
        SourcesWidget,
        TranslationWidget,
    },

    props: ['ghost_clusters', 'cluster','cl_index','pr_rank', 'pr_index', 'posts_count','proposals','color'],

    data: () => ({
        show_cluster: true,
        dialog: false,
    }),


    methods: {

        switchShowMergedCluster() {
            this.show_cluster = !this.show_cluster
            this.$emit("eventShowMergedCluster", this.cluster.key)
        },

        eventMergeClusters(target_key) {
            if (target_key != this.cluster.key) {
                this.$emit("eventMergeClusters", this.cluster.key, target_key, this.pr_rank, this.pr_index)
                this.dialog = false;
            }
        },

        forget() {
            this.$emit("eventForgetMergeProposal", this.pr_rank, this.pr_index)
        },

        // Translations
        eventTranslation(output_language, content, description, title) {
            console.log("eventTranslation", output_language, content.length, description.length,title)
            if (this.cluster.posts[0].translations.length > 0) {
                for (let tr of this.cluster.posts[0].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title = title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

        clusterColor(cl_index) {
            if (cl_index == this.cl_index ) {
                return "error"
            } else {
                return this.color
            }
        },
    },

    computed: {

        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),
        
        avatar_url() {
            return this.cluster.posts.length > 0 ? this.cluster.posts[0].img_url : '';
        },


        buildTitle() {
            let title = ""
            if (this.cluster.posts.length > 0) {
                title = this.cluster.posts[0].title;

                if (this.trans_settings && this.trans_settings[this.cluster.posts[0].language] && this.trans_settings[this.cluster.posts[0].language].available.length > 0) {
                    for (let ol of this.trans_settings[this.cluster.posts[0].language].available) {
                        if (ol.trans_active) {
                            if (this.cluster.posts.length > 0 && this.cluster.posts[0].translations) {
                                for (let trans of this.cluster.posts[0].translations) {
                                    if (trans.output_language == ol.iso) {
                                        if (trans.title.length > 0) {
                                            title = "[" + trans.title.trim() + "]";
                                        }
                                    }
                                }
                            }
                        } 
                    }
                    
                }
            }
            return title
        },

        findMinimumDocLength() {
            let min_doc_length = 0;
            if (this.cluster.posts.length > 0) {
                min_doc_length = this.cluster.posts[0].doc_length;
            }
            for (let p of this.cluster.posts) {
                if (p.doc_length < min_doc_length) {
                    min_doc_length = p.doc_length
                }
            }
            return min_doc_length
        },

        buildClusterMaxCosineCentroidRadius() {
            
            let vectors = [];
            let radius = 0;

            if (this.cluster.posts.length > 1) {
                // Collect all vectors
                for (let p of this.cluster.posts) {
                    vectors.push(p.vector);
                }

                // cosine similarity
                radius = cluster_vector_operations.maxCosineDistanceFromCentroid(this.cluster.centroid, vectors);
            }

           
            return Math.round(1000 * radius) / 1000;
        },

    }

}
</script>

<style>
.wordbreak {
    word-break: normal;
}
</style>