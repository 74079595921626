export default {

    tab: 0,

    setTab(value) {
        this.tab = value;
    },

    retriveTab() {
        return this.tab;
    },


    // Paramaters
    parameters: {

        //DBSCAN with "w2v" or "gpt"
        ghost_clusterer:  "gpt", 
        
        doc_epsilon: 0.012,
        gpt_excerpt_tokens: 150,

        day_offset: 1,
        daily_batch_hour: 18,

        // DBSCAN
        min_points: 1,
        continent: "all",
        
        // W2V
        w2c_dimensions: 50,
        w2c_model: "glove",

        // Skimmer
        skimmer_centroid_distance: 0.016,
        
        // Merger
        merger_epsilon: 0.011,
    },

    setParameters(params) {
        this.params = params;
    },

    retriveParameters() {
        return this.parameters;
    },


    // RECALLER
    recaller_scroll: 0,

    setRecallerScroll(val) {
        this.recaller_scroll = val;
    },

    retriveRecallerScroll() {
        return this.recaller_scroll;
    },

    //* SKIMMER
    skimmer_scroll: 0,

    setSkimmerScroll(val) {
        this.skimmer_scroll = val;
    },

    retriveSkimmerScroll() {
        return this.skimmer_scroll;
    },

    //* MERGER
    merger_scroll: 0,

    setMergerScroll(val) {      
        this.merger_scroll = val;
    },  

    retriveMergerScroll() {
        return this.merger_scroll;
    },


    //* DECK
    deck_scroll: 0,

    setDeckScroll(val) {
        this.deck_scroll = val;
    },

    retriveDeckScroll() {
        return this.deck_scroll;
    },

    //* CLUSTERS
    clusters_scroll: 0,

    setClustersScroll(val) {
        this.clusters_scroll = val;
    },

    retriveClustersScroll() {
        return this.clusters_scroll;
    },
}