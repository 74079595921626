<template>
    <div>
        <v-card tile flat>
              <!-- post in a new cluster-->
              <v-btn x-small @click="eventPostInNewCluster" class="mt-2 ml-2" color="purple accent-2"><v-icon small color="white">mdi-moon-new</v-icon></v-btn>
        </v-card>

    </div>
</template>

<script>
export default {

    name: 'MergerPostWidget',

    props: ['post', 'cl_index', 'p_index'],

    methods: {

        eventPostInNewCluster() {
            this.$emit("eventPostInNewCluster", this.cl_index, this.p_index, this.post);
        }
    },

}
</script>

<style>

</style>