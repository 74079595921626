import Vuex from 'vuex';
import Vue from 'vue';
import translation from './translation';
import search from './search';
import languages from './languages'
import countries from './countries'
import labeller from './labeller'
import tags from './tags'
import currencies from './currencies'
import calendar from './calendar'
import publications from './publications'
import clusters from './clusters';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        translation,
        search,
        languages,
        countries,
        labeller,
        tags,
        currencies,
        calendar,
        publications,
        clusters,
    }
});