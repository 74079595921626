<template>
    <div>
        <!-- HEADER-->
        <v-sheet rounded outlined :color="color" class="ma-1">
            <v-card flat >
                
                <!-- loading -->
                <v-progress-linear 
                    indeterminate 
                    :color="color"
                    :active="loading"
                ></v-progress-linear>
                
                <v-card-actions>
    
                    <!-- Search -->
                    <v-text-field
                        dense
                        v-model="parameters.search"
                        @keydown.enter.prevent="fetchPublications"
                        outlined
                        hide-details
                        clearable
                        @click:clear="resetPublicationsParameters"
                        class=""
                        :color="color"
                        style="max-width: 30%;"
                    ></v-text-field>

                    <v-btn small @click="fetchPublications" class="font-weight-bold Dialog--text ml-3" :color="color"><v-icon>mdi-magnify</v-icon></v-btn>
                    <v-btn small text outlined class="font-weight-bold Dialog--text ml-3" :color="color">{{ parameters.total }} found</v-btn>
                    
                    <!-- Pagination -->
                    <v-pagination v-if="parameters.total > options.itemsPerPage && parameters.total > 0"
                        v-model="parameters.page" 
                        @input="inputPage" 
                        :length="paginationLength" 
                        total-visible="6"
                        :color="color" circle>
                    </v-pagination>
                    <v-spacer></v-spacer>
    
                    <!-- total -->
                    <v-btn small depressed class="font-weight-bold Dialog--text" :color="color">Publications: {{ response.total_publications_count }} </v-btn>
                    <v-btn small depressed class="font-weight-bold" color="blue">Live: {{ response.total_published_count }} </v-btn>
                   
                </v-card-actions>
            </v-card>
        </v-sheet>

        <v-sheet outlined rounded v-for="(publication, index) in response.publications" :key="'pub_' + publication.id" class="my-2 mx-1" :color="colorStatus(publication)">
            <Publication 
                :publication="publication"
                :index="index"
                :sheet_color="colorStatus(publication)"
                @eventDeletePublication="eventDeletePublication"
                @eventDeleteTarget="eventDeleteTarget"
                @eventGoPublisher="eventGoPublisher"
            />
        </v-sheet>

        <!-- error msg -->
        <span v-if="error" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>

        <!-- Pagination -->
        <div v-if="parameters.total > options.itemsPerPage" class="text-center my-3">
            <v-pagination
                v-model="parameters.page"
                @input="inputPage"
                :length="paginationLength"
                total-visible="7"
                :color="color"
                circle
            ></v-pagination> 
        </div>

        <!-- snack bar -->
        <v-snackbar centered v-model="snackbar" timeout="500" color="success">
        <span class="font-weight-bold Dialog--text">Saved !</span>
        <template v-slot:action="{ attrs }">
            <v-btn
            small
            class="Dialog--text"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
                Done !
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import Publication from './PublicationPost.vue';
import { mapGetters, mapActions } from 'vuex';

export default {

    name: 'Publications',

    components: {
        Publication,
    },

    data: () => ({
        loading: false,
        error: null,
        key: 0,

        parameters: {
            search: "",
            page: 1,
            publications: [],
            total: 0,
            scroll: 0,
        },

        response: {
            publications: [],
            total_publications_count: 0,
            total_published_count: 0,
        },

        snackbar: false,


        //* PAGING
        paginationLength: null,
        options: {
            itemsPerPage: 15,
        },

        color:"red accent-1",
    }),

    created() {
        // this.fetchPublications();    
    },

    watch: {
        parametersStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.parametersStore != this.parameters) {
                    this.parameters= this.parametersStore
                    this.paginationLength = Math.ceil(this.parameters.total / this.options.itemsPerPage);
                    setTimeout(
                    () => this.fetchPublications()
                        .then(() => window.scrollTo(0, this.parameters.scroll)),
                        500
                    )
                }
            }
        },
    },

    methods: {

        ...mapActions(['updatePublicationsParameters','resetPublicationsParameters']),

        fetchPublications: async function() {

            console.log("fetch publications")

            let payload = {
                search: this.parameters.search,
            }

            // init
            this.loading = true;
            this.error = null;
            this.parameters.total = 0;
            this.parameters.publications = [];
            this.paginationLength= null;
            
            this.response = {
                publications: [],
                publications_count: 0,
                published_count: 0,
            }

            let filter = (
                "?size=" + this.options.itemsPerPage + 
                "&page=" + (this.parameters.page - 1)
            );


            try {
                let res = await this.$api.post("/publications" + filter, payload);
                this.response = res.data;
                this.parameters.publications = this.response.publications;
                this.parameters.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.parameters.total / this.options.itemsPerPage);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error =  data.message;
            } finally {
                
                // update store
                this.updatePublicationsParameters(this.parameters);
                this.loading = false
            }

        },


        // color sheet
        colorStatus(p) {
            let successArr = p.success === "" ? [] : p.success.split(",");
            let targetsArr = p.targets === "" ? [] : p.targets.split(",");
            let d = targetsArr.length - successArr.length
            let c = ""
            switch (p.status) {
                case "published":
                    return "blue"
        
                case "scheduled":
                    return "green lighten-2"
                case "draft":
                   return "grey"
                   
                case "error":
                    switch (d) {
                        case 1:
                            return "warning"
                        case 2: 
                            return "warning darken-4"
                    }
                    return "error"
                  
                case "purged":
                    return "blue-grey"
                case "purge error":
                    return "purple accent-1"
            }
            return c
        },


        //* NAVIGATION
        inputPage(pg) {
            this.parameters.page = pg;
            this.updatePublicationsParameters(this.parameters);
            this.fetchPublications();
        },

        goPage() {
            console.log("go page", this.number_page)
            this.parameters.publications= [];
            this.inputPage(this.number_page)
        },

        eventGoPublisher(pub) {
            this.parameters.scroll = window.scrollY;
            this.updatePublicationsParameters(this.parameters);
            this.$router.push({
                name: "ghost",
                params: {
                    post_id: pub.post_id,
                    post_ids: pub.post_ids,
                }
            }); 
        },

        // Events
        eventDeletePublication(index) {
            console.log("delete publication event:", index)
            this.deletePublication(index)
        },

        eventDeleteTarget(index, target) {
            console.log("deleting target", target, "- index=", index)
            this.deleteTarget(index, target);
        },


        // CRUD
        deletePublication: async function(index) {
            console.log("deleting publication:", this.publications[index].post_id)

            if (!confirm("Remove Publication and associated targets  \"" + this.publications[index].post_id + "\"?")) {
                return;
            }

            this.loading =true;
            this.error = null;

            try {
                await this.$api.delete("/publisher/publication/" + this.publications[index].post_id);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (!this.error) {
                    this.snackbar = true;
                }
                this.fetchPublications();
            }
        },



        deleteTarget: async function(index, target) {
            
            let payload = {
                "post_id": this.publications[index].post_id,
                "target": target,
            };

            //console.log("payload=", payload);

            if (!confirm("Remove Publication target \"" + target + "\"?")) {
                return;
            }

            this.loading =true;
            this.error = null;

            try {
                await this.$api.post("/publications/delete/target", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                if (!this.error) {
                    this.snackbar = true;
                }
                // wait for the pubication to update in es
                setTimeout(() => {
                    this.fetchPublications();
                }, 1000);
                
            }

        },
    },


    computed: {
        ...mapGetters({
            parametersStore: 'getPublicationsParameters',
        }),
    }
}
</script>

<style>

</style>