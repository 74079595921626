<template>
    <div>
        
        <v-card flat outlined class="mb-3 pb-3">
            
            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="purple lighten-2"
                :active="loading">
            </v-progress-linear>
            
            
            <v-card-actions class="">

                <v-spacer></v-spacer>
                <!-- POST ID -->
                <v-text-field
                    v-model="id"
                    label="Post ID"
                    required
                    hide-details
                    color="purple lighten-2"
                ></v-text-field>

                <!-- eye -->
                <v-spacer></v-spacer>
                <span v-if="post"><a :href="post.url" target="_blank"><v-icon large color="purple lighten-2">mdi-eye</v-icon></a></span>

                <v-spacer></v-spacer>
                <v-btn
                small
                @click="fetchPostTags" 
                class="ml-5" 
                color="purple lighten-2">
                    G0
                </v-btn>

                <v-spacer></v-spacer>
                <span v-if="post">Src: {{post.source_name}} · {{formatDate}} · {{post.country}}</span>
                <v-spacer></v-spacer>
            </v-card-actions>
            

                <!-- error msg -->
                <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>
            
        </v-card>
        
        <!-- Old Post tags Widget -->
        <v-row>
            <v-col cols="1">
               <span class="font-weight-bold ml-6">POST</span>
            </v-col>
            <v-col cols="11">
                <Tags v-if="post" 
                    :tags="old_post_tags" 
                    :pid="id" :language="post.language"
                    @eventFetchTags="eventFetchTags"
                    @eventTags="eventTags"
                    stream="tagsPostTab" 
                    status="old" class="" 
                    allow_delete="true"
                    key="old"/>
            </v-col>
        </v-row>

        <!-- New Post tags Widget -->
         <v-row>
            <v-col cols="1">
                <span class="font-weight-bold ml-6">TAGGER</span>
            </v-col>
            <v-col cols="11">
                <Tags v-if="post" 
                    :tags="new_post_tags" 
                    :pid="id" 
                    :language="post.language" 
                    stream="tagsPostTab" 
                    status="new"
                    allow_delete="true"  
                    key="new"
                />
            </v-col>
        </v-row>
        

        <!-- channels selected keys + keys-->
        <v-card flat outlined class="d-flex justify-start my-3">
            <v-card outlined v-for='channel_weights in channels_weights' :key="channel_weights.name" class="ma-1" width="20%">
                <v-card-title class="accent--text py-1">{{channel_weights.name}}.keys = {{channel_weights.selected_keys}}</v-card-title>
                <v-card-text v-if="channel_weights.name == 'country'" class="py-0">- Bonus: {{channel_weights.bonus}}</v-card-text>
                <v-card-text v-for="(pw,index) of channel_weights.post_weight_map" :key="index" class=" ml-5 py-0 my-0">
                    - {{pw.tag_ref}}: {{pw.key_count}},
                </v-card-text>
            </v-card>
        </v-card>


        <v-card v-for="bck in blocks_list" flat outlined class="my-2" color="" :key="bck">
            <v-card-title class="accent--text text-body-1 font-weight-bold py-1">{{$tools.capitalizeWord(bck)}}</v-card-title>
            <v-divider></v-divider>
            
            <!-- corpus -->
            <v-card-text class="accent--text py-2">Tokens Corpus (no stopwords or stemming) [x4,x2,x1]</v-card-text>
            <v-card-subtitle class="py-1"><span v-html="blocks[bck].corpus"></span></v-card-subtitle>

            <v-card-subtitle v-if="post && post.translations" class="green--text">{{ noHtml(post.translations[0][bck])}}</v-card-subtitle>

            <!-- channels -->
            <v-card flat v-if="labels[bck].length > 0" class="d-flex justify-start my-1">
                <v-card flat outlined v-for="channel_weights in blockChannelWeights(bck)" :key="bck + '-channel-' + channel_weights.name" class="ma-1 pb-2 pt-0" width="25%">
                    <v-card-text :class="colorChannelOrIndexText(channel_weights.name) + ' py-1'">channel: {{channel_weights.name}}</v-card-text>
                    <!-- <v-card-subtitle :class="colorChannelOrIndexText(channel_weights.name) + ' py-0 mb-3'">{{channel_weights[bck + '_weight_map']}} </v-card-subtitle>-->
                    <v-card-text class="py-0">
                        <v-chip small v-for="(item,key) in  channel_weights[bck + '_weight_map']" :key="key" :color="colorChip(channel_weights.name)" class="mr-1 mb-2">
                        {{key}} ({{item.key_count}}) [{{item.tag_ref.toUpperCase()}}]
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-card>

            <!-- labels -->
            <v-card-text v-if="labels[bck].length > 0" class="accent--text py-1">Labels</v-card-text>
            <v-card-subtitle v-for="(c,index) in labels[bck]" class="py-0" :key='"title_" + index'>
                <span v-if="c.index.includes('city')" class="light-blue--text text--lighten-2">key: {{c.key}} - tag_ref: {{c.tag_ref}} - count: {{c.count}} - name: {{c.name}} - token: {{c.token}} 
                    <v-icon @click="goToLabel(c.id,'city')" small color="light-blue lighten-2">mdi-alpha-l-circle-outline</v-icon>
                </span>
                <span v-if="c.index.includes('country')" class="light-blue--text text--darken-2">key: {{c.key}} - tag_ref: {{c.tag_ref}} - count: {{c.count}} - name: {{c.name}} - token: {{c.token}} 
                    <v-icon @click="goToLabel(c.id,'country')" small color="light-blue darken-2">mdi-alpha-l-circle-outline</v-icon>
                </span>
                <span v-if="c.index.includes('market')" class="green--text text--lighten-2">key: {{c.key}} - tag_ref: {{c.tag_ref}} - count: {{c.count}} - name: {{c.name}} - token: {{c.token}} 
                    <v-icon @click="goToLabel(c.id,'market')" small color="green lighten-2">mdi-alpha-l-circle-outline</v-icon>
                </span>
                <span v-if="c.index.includes('crimorg')" class="yellow--text text--darken-2">key: {{c.key}} - tag_ref: {{c.tag_ref}} - count: {{c.count}} - name: {{c.name}} - token: {{c.token}} 
                    <v-icon @click="goToLabel(c.id,'crimorg')" small color="yellow darken-2">mdi-alpha-l-circle-outline</v-icon>
                </span>
                <span v-if="c.index.includes('mean')" class="red--text text--darken-2">key: {{c.key}} - tag_ref: {{c.tag_ref}} - count: {{c.count}} - name: {{c.name}} - token: {{c.token}} 
                    <v-icon @click="goToLabel(c.id,'mean')" small color="red darken-2">mdi-alpha-l-circle-outline</v-icon>
                </span>
                 <span v-if="c.index.includes('law')" class="deep-purple--text text--lighten-3">key: {{c.key}} - tag_ref: {{c.tag_ref}} - count: {{c.count}} - name: {{c.name}} - token: {{c.token}} 
                    <v-icon @click="goToLabel(c.id,'law')" small color="deep-purple lighten-3">mdi-alpha-l-circle-outline</v-icon>
                </span>
            </v-card-subtitle>

        </v-card>


    </div>
</template>

<script>
import moment from "moment";
import Tags from '../Tags/TagsWidget.vue'

export default {

    name: 'TagsPostTab',

    data: ()=> ({
        loading: false,
        error: null,
        id: null,
        post: null,
        corpus_stopwords: null,
        channels_weights: [],

        blocks_list : [
            "title",
            "description",
            "content"
        ],

        blocks: {
            title: {
                corpus: null,
                tokens : [],
            },
            description: {
                corpus: null,
                tokens : [],
            },
            content: {
                corpus: null,
                tokens : [],
            },
        },

        labels:{
            title: [],
            description: [],
            content: [],
        },

        old_post_tags: [],
        new_post_tags: [],

    }),

    components: {
        Tags,
    },

    created() {
        if (this.$route.params.pid) {
            this.fetchCorpus(this.$route.params.pid);
            this.id = this.$route.params.pid.trim()
        }
    },

    methods: {

        fetchPostTags() {
            this.fetchCorpus(this.id);
        },
        
        fetchCorpus: async function(id) {
            this.loading = true;
            this.error = null;
            this.title_cards = [];
            this.description_cards = [];
            this.content_cards = [];
            this.pipeline_country = {};

            let filter = (
                "?pid=" + id.trim() + 
                "&user_lang=" + this.$auth.retriveUserField("language_ui")
            );

            try {
                let res = await this.$api.get("/tooler/post_tags" + filter);
                
                // corpus
                this.blocks.title.corpus = res.data.corpus_title
                this.blocks.description.corpus = res.data.corpus_description
                this.blocks.content.corpus = res.data.corpus_content

                // block_labels

                // reset
                this.labels = {
                    title: [],
                    description: [],
                    content: [],
                };

                for (let b of this.blocks_list) {
                    if (res.data.blocks_labels[b]) {
                        this.labels[b] = res.data.blocks_labels[b];
                        this.labels[b].sort((a, b) => b.index.localeCompare(a.index));
                    }
                }

                // highlighting
                this.buildBlockTokens();

                // channel weights
                if (res.data.channels_weights) {
                    this.channels_weights = res.data.channels_weights;
                }

                // order channel_weights[channel].post_weight_map by count
                for (let w of this.channels_weights) {

                    // post_weight_mpa is a map, to order it we nedd to mutate it to an array
                    var items = Object.keys(w.post_weight_map).map(function(key) {
                        return {tag_ref: key, key_count: w.post_weight_map[key].key_count};
                    });

                    items.sort((a, b) => b.key_count - a.key_count)
                    w.post_weight_map = items;
                }

                // post
                if (res.data.post) {
                    this.post = res.data.post;
                }

                // post tags
                if (res.data.new_post_tags) {
                    this.new_post_tags = res.data.new_post_tags;
                }
                if (res.data.old_post_tags) {
                    this.old_post_tags = res.data.old_post_tags;
                }

            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // filter v-if / v-for
        blockChannelWeights(bck) {
            let cws = []
            for (let cw of this.channels_weights) {
                if (Object.keys(cw.block_weights_map[bck]).length > 0) {
                    cws.push(cw)
                }
            }
            return cws
        },

        // go to label
        goToLabel(id,index) {
            this.$router.push({name: "label", params : {id: id, index: index}});
        },

        goArticle() {
            window.open(this.post.url, '_blank');
        },

        eventFetchTags() {
            console.log("eventTag")
            this.sleep(1000).then(() => this.fetchPostTags());
        },

        eventTags() {
            console.log("eventTags");
            this.sleep(1000).then(() => this.fetchPostTags());
        },

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        buildBlockTokens() {
            for (let b of this.blocks_list ) {
                
                // build list tokens in corpus to be highlighted
                for (let l of this.labels[b]) {
                    this.blocks[b].tokens.push({token: l.token, index: l.index})
                }

                // remove duplicates
                this.blocks[b].tokens = [...new Set(this.blocks[b].tokens)];

                // replace token with highlighted token
                for (let t of this.blocks[b].tokens) {
                    // replace words not string 
                    const regex = new RegExp(`(?<=^|\\W)${this.escapeRegExp(t.token)}(?=\\W|$)`, "gu");
                    if (this.blocks[b].corpus.length > 0) {
                        this.blocks[b].corpus = this.blocks[b].corpus.replace(regex, "<span class='font-weight-bold text-decoration-underline " + this.colorChannelOrIndexText(t.index) + "'>" + t.token + "</span>")
                    }
                }
                
            }
        },

        // chat GPT: 
        // This function replaces any special characters in the input string 
        // with their escaped versions using a regular expression, 
        // so that the string can be safely used as a literal string in a regular expression.
        escapeRegExp(string) {
            return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
        },

        // hightlight tokens in corpus
        // set color of channels title 
        colorChannelOrIndexText(channelNameOrIndex) {
            let c = "accent"
            if (channelNameOrIndex.includes("crimorg")) {
                c = "yellow--text text--darken-1"
            }

            if (channelNameOrIndex.includes("market")) {
                c = "green--text text--lighten-2"
            }

            if (channelNameOrIndex.includes("country")) {
                c = "light-blue--text text--darken-2"
            }

            if (channelNameOrIndex.includes("city")) {
                c = "light-blue--text text--lighten-2"
            }

            if (channelNameOrIndex.includes("mean")) {
                c = "red--text text--lighten-2"
            }

             if (channelNameOrIndex.includes("law")) {
                c = "deep-purple--text text--lighten-3"
            }

            return c
        },

        colorChip(channelName) {
            let c = "accent"
            switch (channelName) {
                case "crimorg":
                    c = "yellow darken-3"
                    break;
                case "market":
                    c = "green darken-2"
                    break;
                case "country":
                    c = "light-blue darken-2"
                    break;
                case "mean":
                    c = "red darken-2"
                    break;
                case "law":
                    c = "deep-purple lighten-3"
                    break;
            
                default:
                    break;
            }
            return c
        },

        noHtml(text) {
            let output = ""
            if (text.length > 0) {
                output = text.replace(/<[^>]+>/g, "")
            }
            return output
        }
    },
        
    

    computed: {
     formatDate() {
            return moment(this.post.created_at).format("DD/MM/YY HH:MM")
        },
    },
}
</script>

<style>

</style>