<template>
    <div>
        <v-card tile flat color="grey darken-2" width="50" height="100%" class="">
            
            <!-- CL/POSTS -->
            <v-btn v-if="cluster_posts_count> 1" small text rounded class="mt-1" >{{cl_index}}.{{p_index}}</v-btn>
            
            
            <!-- drag button -->
            <v-btn x-small class="mt-3 ml-2" color="purple accent-2"><v-icon color="" small>mdi-drag</v-icon></v-btn>
           
            <!-- post in a new cluster-->
            <v-btn x-small @click="eventPostInNewCluster" class="mt-2 ml-2" color="purple accent-2"><v-icon small color="white">mdi-moon-new</v-icon></v-btn>
            
            <!-- imgs/posts count -->
            <v-btn x-small text class="mt-1">{{ imgsPostCount }}/{{wordsCount}}</v-btn>

            <!-- Publication Softmax-->
            <!--<v-btn x-small text class="mt-2">{{roundSoftmax}}</v-btn>-->

            <!-- Is glove clustered -->
            <v-btn v-if="post.is_w2c_clustered" x-small class="mt-1 ml-1 font-weight-bold" color="blue">W2C</v-btn>

            <!-- Is Loot clustered -->
            <v-btn v-if="post.is_loot_clustered" x-small class="mt-0 ml-2 Dialog--text font-weight-bold" color="amber">LT</v-btn>

            <v-btn x-small v-if="addThePostToCluster" class=" font-weight-bold Dialog--text mt-1 ml-1" color="teal accent-3">ADD</v-btn>
           

        </v-card>

    </div>
</template>

<script>
export default {

    name: 'ClusterPostWidget',

    props: ['post', 'cl_index', 'p_index', 'cluster_posts_count', 'see_out', "pids_to_add"],

    methods: {

        eventPostInNewCluster() {
            this.$emit("eventPostInNewCluster", this.cl_index, this.p_index, this.post);
        }
    },

    computed: {

        color() {
            if (this.post.is_out) {
                return "amber"
            } else {
                return"success"
            }
        },

        imgsPostCount() {
            let regex = /<img[^>]*src="([^"]*)"/g;
            let matches = this.post.content.match(regex);  // Find all matches for the regex
            let numberOfImages = matches ? matches.length : 0;  // Get the number of matches or 0 if none

            // from feeder
            if (this.post.img_url && this.post.img_url.length > 0) {
                numberOfImages += 1;
            }
            return numberOfImages
        },

        wordsCount() {
            let noHtml = this.post.content.replace(/<[^>]+>/g, "")
            return noHtml.split(" ").length;
        },

        roundSoftmax() {
            if (typeof this.post.publication_softmax === 'number') {
                return this.post.publication_softmax.toFixed(2);  // Keeps two decimal places as a string
            } else {
                return 0;  // or handle the case where the value is not a number
            }
        },

        addThePostToCluster() {
            if (Array.isArray(this.pids_to_add) && this.pids_to_add.includes(this.post.id)) {
                return true;
            }
            return false
        }
    }
}
</script>

<style>

</style>