<template>
    <div>
        <!-- error msg -->
        <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>
        
        <!-- TABLE NOT PAGED OBJECTS-->
        <v-data-table
            :headers="[
                { text: 'id', value: 'id', align:'center', class:'text-body-2' },
                { text: 'Ref', value: 'ref', align:'center', class:'text-body-2' },
                { text: 'Prod', value: 'prod', align:'center', class:'text-body-2' },
                { text: 'Alert', value: 'alert', align:'center', class:'text-body-2' },
                { text: 'Nom', value: 'name', align:'left', class:'text-body-2' },
                { text: '', value: 'country_flag', align:'right', class:'text-body-2' },
                { text: 'Pays', value: 'country', align:'center', class:'text-body-2' },
                { text: 'Type', value: 'type', align:'center', class:'text-body-2' },
                { text: 'Level', value: 'level', align:'center', class:'text-body-2' },
                { text: 'Description', value: 'description', align:'center', class:'text-body-2' },
                { text: 'Lang', value: 'lang', align:'center', class:'text-body-2' },
                { text: 'Stream', value: 'stream_type', align:'center', class:'text-body-2' },
                { text: 'Depth', value: 'depth', align:'center', class:'text-body-2' },
                { text: 'OK AT', value: 'ok_at', align:'center', class:'text-body-2' },
            ]"
            :items="sources"
            :loading="loading"
            @click:row="selectRow"
            :search="search"
            :footer-props="{
                itemsPerPageText:'sources par page',
                itemsPerPageOptions: [80,200,-1],
            }"
            :value="selected"
            @input="enterSelect($event)"
            show-select
            dense
        >
            <v-progress-linear slot="progress" color="accent" indeterminate></v-progress-linear>
            
            <!-- TOP BAR -->
            <template v-slot:top>
                <v-toolbar flat class="mb-3" color="Dialog">

                    <!-- TABLE TITLE -->
                    <v-toolbar-title class="">SOURCES:<span v-if="total > 1" class="ml-2">{{total}} | </span> <span class="warning--text">{{prod_off_count}}</span> | <span class="yellow--text">{{alert_count}}</span></v-toolbar-title>

                    <v-spacer></v-spacer>
                    
                    <!-- TABLE SEARCH -->
                    <v-text-field
                        dense
                        flat rounded solo single-line
                        v-model="search"
                        :label='$tools.capitalizeWord($i18n.t("search"))'
                        hide-details
                        clearable
                    ></v-text-field>
                    <v-spacer></v-spacer>
                   
                    
                    <!-- NEW SOURCE BUTTON -->
                    <v-btn small fab color="accent" @click="create()" elevation="0">
                        <v-icon color="Dialog">
                            mdi-plus
                        </v-icon>
                    </v-btn>

                   

                </v-toolbar>
            </template>

            <!-- SWITCH PROD-->
            <template v-slot:[`item.prod`]="{ item }">
               <v-icon left :color="item.prod ? 'success':'warning'">mdi-circle-medium</v-icon>
            </template>


            <!-- Alert -->
            <template v-slot:[`item.alert`]="{ item }">
               <v-icon v-if="item.alert" left color="error" class="white">mdi-alert-circle</v-icon>
            </template>

            <!-- Name-->
            <template v-slot:[`item.name`]="{ item }">
                <v-btn small outlined v-if="item.ref == 'test'" class="yellow--text">{{item.name}}</v-btn>
                <v-btn small outlined v-else-if="item.ref == 'FR046'" class="purple--text text--accent-2">{{item.name}}</v-btn>
                <v-btn small outlined v-else-if="item.is_community" class="deep-orange--text text--accent-2">{{item.name}}</v-btn>
                <span v-else>{{item.name}}</span>
            </template>

            <!-- PATH --> 
            <template v-slot:[`item.level`]="{ item }">
                <v-btn rounded x-small elevation="0" :color="patchLevel(item.level).color" :class="patchLevel(item.level).class + ' ml-3 my-2'">
                    <v-icon>mdi-{{ patchLevel(item.level).icon }}</v-icon>
                </v-btn>
            </template>

            <!-- Country Flag -->
            <template v-slot:[`item.country_flag`]="{ item }">
                <country-flag :country='item.country' size='normal' :rounded="true" class="mb-0"/>
            </template>

            <!-- Stream Type -->
            <template v-slot:[`item.stream_type`]="{ item }">
                <v-icon :color="colorIconStream(item.stream_type)">{{`mdi-${iconStream(item.stream_type)}`}}</v-icon>
            </template>

            <!-- ok_at -->
            <template v-slot:[`item.ok_at`]="{ item }">
                {{formatDate(item.ok_at)}}
            </template>

        </v-data-table>

    </div>
</template>

<script>
import moment from "moment";
import sources from "@/common/sources";

export default {
    name: 'Sources',

    data: () => ({
        sources: [],
        selected: [],
        prod_off_count: 0,
        alert_count: 0,
       
        //set up
        loading: false,
        total: 0,
        options: {
            itemsPerPage: 15,
        },
        search: null,
        scrolling: 0,

        // api error
        error: null,
    }),

    created() {
        this.search = sources.retriveSearch();
        this.scrolling = sources.retriveScroll();
        this.fetchItems().then( () => {
            window.scrollTo(0,this.scrolling);
            
        });
    },

    watch: {

        options: {
            deep: true,
            immediate: false,
            handler() {
                this.fetchItems();
            }
        },

        search: {
            deep: true,
            immediate: false,
            handler() {
                sources.setSearch(this.search);
            }
        }
    },


    methods: {

        // select all / show-select
        // each input we store selections in selected
        enterSelect(values) {
            this.selected = values;
        },

        fetchItems: async function() {
            console.log("fetching Sources")
            this.error = null;
            this.loading = true;
            
            try {
                let sources = [];
                this.prod_off_count = 0;
                this.alert_count = 0;
                let res = await this.$api.get("/sources");
                for (let es of res.data) {
                    let s = es.source;
                    s.alert = es.alert
                    if (!s.prod) {
                        this.prod_off_count ++
                    }
                    if (s.alert) {
                        this.alert_count ++
                    }
                    sources.push(s)
                }
                // move test to front
                sources = this.moveTestToFront(sources, 'ref', 'test');
                this.sources = sources;
            
                this.total = parseInt(res.headers["x-paginated-items"]);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        // Function to move the object with ref='test' to the front
        moveTestToFront(arr, field, value) {
            const index = arr.findIndex(obj => obj[field] === value);
        
            if (index > -1) {
                const [obj] = arr.splice(index, 1); // Remove the object from its current position
                arr.unshift(obj); // Add the object to the front of the array
            }

            return arr;
        },

        // go to Source.vue
        selectRow(row) {
            sources.setScroll(window.scrollY);
            this.$router.push({path: "/main/source/" + row.ref})
        },

        create() {
            sources.setScroll(window.scrollY);
            this.$router.push({path: "/main/source/new"})
        },

        patchLevel(level) {
            let patch = {
                icon: null,
                color: null,
                class: null,
            }

            switch (level) {
                case 0:
                patch = {
                    icon: "alpha-h",
                    color: "Dialog",
                    class: " accent--text",
                }
                break;

                case 1:
                patch = {
                    icon: "alpha-p",
                    color: "red",
                    class: "white--text",
                }
                break;

                case 2:
                patch = {
                    icon: "alpha-f",
                    color: "white",
                    class: "red--text",
                }
                break;
            }

            return patch;
        },

        iconStream(stream) {
            let icon = stream
            switch (stream) {
                case "rss":
                    icon = "rss-box"
                    break;
                case "xml":
                    icon = "alpha-x-circle";
                    break;
                case "html":
                    icon = "alpha-h-box";
                    break;
                case "chromedp":
                    icon = "alpha-c-box";
                    break;
            }
            return icon
        },

        colorIconStream(stream) {
            switch (stream) {
                case "chromedp":
                   return "purple accent-1";
                case "html":
                    return "cyan accent-3";
                default:
                    return "warning";
            }
        },

        formatDate(date) {
            return moment(date).locale("fr").format("DD/MM/YY");
        },
    },

}
</script>

<style lang="scss">
</style>
