<template>
  <div>

     <!-- HOVER CLUSTER SWITH -->
     <v-card @click="switchToDeck" tile class="fixed-icon-deck-switch" width="3%" height="100%" color="success" key="hover-deck-switch">
            
            <!-- <v-btn block class="font-weight-bold Dialog--text" color="teal accent-3">CLUSTER</v-btn> -->
        </v-card>

    <v-card flat color="Background" key="clusters" width="97%">
        
        <!-- HEADER-->
        <v-sheet rounded outlined :color="color" class="ma-1">
            <v-card flat >
                <!-- loading -->
                <v-progress-linear 
                    indeterminate 
                    :color="color"
                    :active="loading"
                ></v-progress-linear>

                <v-card-actions>
                    <!-- Search -->
                    <v-text-field
                        dense
                        v-model="parameters.search"
                        outlined
                        @keydown.enter.prevent="fetchClusters"
                        hide-details
                        clearable
                        @click:clear="resetClustersParameters"
                        class=""
                        :color="color"
                        style="max-width: 20%;"
                    ></v-text-field>

                    <v-combobox 
                        v-model="parameters.selected_tags" 
                        :items="tags_list" 
                        item-text="name" 
                        clearable 
                        multiple 
                        dense 
                        outlined
                        chips
                        :color="color"
                        hide-details
                        style="max-width: 30%;"
                        class="ml-2">
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip small v-bind="attrs" :input-value="selected" close @click="select" class="Dialog--text" :color="color"
                                @click:close="removeSelectedTag(item)">
                                {{ $tools.capitalizeString(item.name) }}
                            </v-chip>

                        </template>
                    </v-combobox>
                    
                    <v-btn small @click="fetchClusters" class="font-weight-bold Dialog--text ml-3" :color="color"><v-icon>mdi-magnify</v-icon></v-btn>
                    <v-btn small text outlined class="font-weight-bold Dialog--text ml-3" :color="color">{{ parameters.total }} found</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Pagination -->
                    <v-pagination v-if="parameters.total > options.itemsPerPage && parameters.total > 0"
                        v-model="parameters.page" 
                        @input="inputPage" 
                        :length="paginationLength" 
                        total-visible="5"
                        :color="color" 
                        circle>
                    </v-pagination>
                    <v-spacer></v-spacer>
                    <!-- total -->
                    <v-btn small depressed class="font-weight-bold Dialog--text" :color="color">Clusters: {{ total_clusters_count }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-sheet>


        <!-- error msg -->
        <span v-if="error && error != 'empty'" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>

        <!-- no cluster msg -->
        <v-card v-if="error == 'empty'" flat color="transparent">
            <v-card-text class="text-center">No Cluster</v-card-text>
        </v-card>

        <v-sheet outlined rounded v-for="(cluster, index) in parameters.clusters" :key="'cluster_' + cluster.id" class="ma-1" :color="sheetColor(index)">
           <!-- CLUSTER -->
            <Cluster 
                :ghost_clusters="ghost_clusters"
                :ghost_parameters="ghost_parameters"
                :cluster="cluster" 
                :index="index"
                :color="color"
                @eventRemoveCluster="eventRemoveCluster"
                @eventRemoveClusterFromDeck="eventRemoveClusterFromDeck"
            />
            
        </v-sheet>

    </v-card>


  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Cluster from './Cluster.vue'
import ghost from "@/common/ghost";

export default {

    name: 'ClustersTab',

    components: {
        Cluster,
    },

    props: ['ghost_clusters','ghost_parameters', 'tags_list', 'tab'],

    data: () => ({
        
        loading: false,
        error: null,
        clusters: [],

        parameters: {
            search: "",
            selected_tags: [],
            page: 1,
            clusters: [],
            total: 0,
        },

        total_clusters_count: 0,

        color: "teal accent-3",

        //* PAGING
        paginationLength: null,
        options: {
            itemsPerPage: 20,
        },

        scrolling: 0,
    }),

    created() {
        // this.fetchClusters();
    },

    watch: {
        parametersStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.parametersStore != this.parameters) {
                    this.parameters = this.parametersStore
                    this.paginationLength = Math.ceil(this.parameters.total / this.options.itemsPerPage);
                    console.log("watch parametersStore", this.parameters.clusters.length)
                    if (this.parametersStore.clusters.length == 0) {
                        setTimeout(
                            () => this.fetchClusters()
                            .then(() => window.scrollTo(0, this.scrolling)),
                            500
                        );
                    } else {
                        this.scrolling = ghost.retriveClustersScroll();
                        window.scrollTo(0, this.scrolling);
                    }
                   
                }
            }
        },

        tab: {
            immediate: true,
            handler() {
                if (this.tab == 4) {
                    this.scrolling = ghost.retriveClustersScroll();
                    window.scrollTo(0, this.scrolling);
                }
            }
        }
    },


    methods: {

        ...mapActions(['updateClustersParameters','resetClustersParameters']),

        fetchClusters: async function () {

            console.log("fetching clusters")

            let payload = {
                search: this.parameters.search,
                tags_ref_list: [],
            }

            // tags ref list if any
            if (this.parameters.selected_tags && this.parameters.selected_tags.length > 0 ) {
                for (let t of this.parameters.selected_tags) {
                    payload.tags_ref_list.push(t.ref);
                }
            }

            // update store
            this.updateClustersParameters(this.parameters);

            // init
            this.loading = true;
            this.error = null;
            this.parameters.total = 0;
            this.parameters.clusters =[];
            this.paginationLength= null;

            this.response = {
                clusters: [],
                clusters_count: 0,
            };

         
            let filter = (
                "?size=" + this.options.itemsPerPage +
                "&page=" + (this.parameters.page - 1)
            );

        
            try {
                let res = await this.$api.post("/clusters" + filter, payload);
                this.parameters.clusters = res.data.clusters;
                this.parameters.total = parseInt(res.headers["x-paginated-items"]);
                this.paginationLength = Math.ceil(this.parameters.total / this.options.itemsPerPage);
                this.total_clusters_count = res.data.total_clusters_count;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                // update store
                this.updateClustersParameters(this.parameters);
                this.loading = false
            }
        },

        //* NAVIGATION
        inputPage(pg) {
            this.parameters.page = pg;
            this.updateClustersParameters(this.parameters);
            this.fetchClusters();
        },

        switchToDeck() {
            console.log("switchToDeck")
            ghost.setClustersScroll(window.scrollY);
            this.$emit("eventSwitchToDeck");
        },

        ///* TAGS
        removeSelectedTag(item) {
            this.parameters.selected_tags.splice(this.parameters.selected_tags.indexOf(item), 1)
        },

        //* EVENTS
        eventRemoveCluster(index) {
            this.clusters.splice(index, 1);
            this.parameters.total --;
        },

        eventRemoveClusterFromDeck(cl_key) {
            this.$emit("eventRemoveClusterFromDeck", cl_key);
        },


        sheetColor(index) {
            if (this.parameters.clusters[index].is_published) {
                return "blue";
            }
            return this.color;
        },

    },

    computed: {
        ...mapGetters({
            parametersStore: 'getClustersParameters',
        }),


    }


    
}
</script>

<style>
.fixed-icon-deck-switch {
    position: fixed;
    bottom: 0%;
    z-index: 1000;
    right: 0px;
}
</style>