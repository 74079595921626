<template>
    <div>
        <v-card tile flat :color="post.skimmed ? 'deep-orange darken-4':''" width="75" height="100%" class="">
            
            <!-- CL/POSTS -->
            <v-btn v-if="cluster_posts_count> 1" x-small text class="mt-1 font-weight-bold ml-2" >#{{deck_cl_index}}.{{p_index}}</v-btn>

            <!-- skim post -->
            <v-btn x-small @click="skimPost" class="mt-1 ml-3" color="purple accent-2"><v-icon small>mdi-moon-new</v-icon></v-btn>
            
            <!-- If skimmed new cluster centroid Radius -->
            <v-btn x-small text class="mt-2 text-caption" color="white">CR1000</v-btn>
            <v-btn v-if="cluster_posts_count > 1" x-small @click="skimPost" depressed class="mt-0 font-weight-bold ml-1" color="purple accent-2">[{{new_max_centroid_radius}}]</v-btn>
            <v-btn v-if="cluster_posts_count > 1" x-small @click="skimPost" depressed class="mt-0 font-weight-bold ml-1" color="purple accent-2">{{centroid_radius}}</v-btn>
        </v-card>
    </div>
</template>

<script>

export default {

    name: 'SkimmerPostWidget',

    props: ['post', 'cl_index', 'p_index', 'deck_cl_index', 'cluster_posts_count','new_max_centroid_radius','centroid_radius', 'color'],

    methods: {
        
        skimPost() {
            console.log("Skimming post: " + this.p_index + " in index: " + this.deck_cl_index);
            // post in a new cluster
            // update cluster centroid (origin + new sigle post cluster)
            this.$emit("eventPostInNewCluster", this.deck_cl_index, this.p_index, this.post, this.cl_index);
        },

    },

    computed: {

    }
}
</script>

<style>

</style>