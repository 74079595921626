<template>
    <div>

        <v-card v-if="publication" flat color="" class="d-flex">

            <v-card flat color="">
                <v-card-title class="text-subtitle-1">
                    created {{sinceDate}} · {{formatCreatedAt}} 
                    <v-btn small outlined class="ml-1 font-weight-bold" :color="sheet_color">{{publication.status}}</v-btn>
                    <span v-if="publication.status == 'scheduled'" class="ml-1">{{formatDate(publication.scheduled_at)}}</span>
                    <span v-if="publication.status == 'published'" class="ml-1">{{formatDate(publication.published_at)}}</span>
                </v-card-title>
                <v-card-subtitle class="py-1 text-subtitle-2"> {{publication.id}} · pid {{publication.post_id}}</v-card-subtitle>
                <v-card-actions>
                    <v-btn x-small @click="goPublisher" class="font-weight-bold Dialog--text mx-2" color="lime">EDIT</v-btn>
                    <!-- chips -->
                    <v-icon
                        v-for="(t, index) in publication.targets.split(',')" 
                        :key="'t_' + index"
                        @click="eventDeleteTarget(t)"
                        :color="chipColor(t)">
                        mdi-{{chipMdi(t)}}
                    </v-icon>
                    <v-spacer></v-spacer>
                    <v-icon class="mx-3" color="error" @click="eventDeletePublication">mdi-delete-circle-outline</v-icon>
                </v-card-actions>


                <v-divider></v-divider>
                <v-card-actions v-if="img_urls.length > 0" >
                    
                    <!-- imgs -->
                    <v-card flat v-for="(i,index) in img_urls" :key="'i_' + index" class="ml-2 pa-2">
                        <a :href="i">
                            <v-img eager contain width="300" max-height="200" :src="i" ></v-img>
                        </a>
                    </v-card>
                    
                </v-card-actions>
                <v-card-text  v-for="(t,index) in threads" :key="index" class="py-1">{{t}}</v-card-text>            </v-card>
        </v-card>
    </div>
</template>

<script>
import moment from "moment";


export default {

    name: 'Publication',

    props: ['publication',"index","sheet_color"],

    watch: {
        publication: {
            immediate: true,
            handler() {
                if (this.publication.img_urls.length > 0) {
                    this.img_urls = this.publication.img_urls.split("#,#");
                }

                if (this.publication.text.length >0) {
                    this.threads = this.publication.text.split('\n\n')
                }
            }
        },
    },

    data: () => ({
        img_urls: [],
        theads: [],
    }),

    

    methods: {

        eventDeletePublication() {
            this.$emit("eventDeletePublication", this.index);
        },

        eventDeleteTarget(target) {
            this.$emit("eventDeleteTarget", this.index, target);
        },

        goPublisher() {
           this.$emit("eventGoPublisher", this.publication);
        },

        formatDate(date) {
            return moment(date).format("DD/MM/YY@HH:MM")
        },

        chipColor(t){
            if (this.publication.success.includes(t)) {
                return "blue"
            } else {
                return "warning"
            }
        },

        chipMdi(t) {
            let i = "unknown"
            switch (t) {
                case "twitter":
                    i = "alpha-x-circle-outline"
                    break;
                case "bluesky":
                    i = "alpha-b-circle-outline"
                    break;
                case "instagram":
                     i = "alpha-i-circle-outline"
                    break;
                case "threads":
                    i = "alpha-t-circle-outline"
                    break;
                default:
                    break;
            }
            return i
        }

    },

    computed: {

        formatCreatedAt() {
            return moment(this.publication.created_at).format("DD/MM/YY@HH:MM")
        },

        sinceDate() {
            return moment(this.publication.created_at).locale(this.$auth.retriveUserField("lang")).fromNow();
        },


    },

}
</script>

<style>

</style>