<template>
    <div>
        <!-- TERMS BLOCK  -->
        <v-card flat tile outlined class="pa-3">

            <!-- Loading -->
            <v-progress-linear indeterminate color="accent" :active="loading" class="mb-2">
            </v-progress-linear>

            <!-- Exact  -->
            <v-row align="center" no-gutters>
                <v-col cols="12" md="3">
                    {{ $i18n.t("exact") }}:
                </v-col>
                <v-col cols="12" md="7">
                    <v-text-field dense v-model="exactPhrase" @keydown.enter.prevent="launchSearch" outlined
                        hide-details clearable class="mb-3"></v-text-field>
                </v-col>
                <v-col cols="2" class="text-center pb-3">

                </v-col>
            </v-row>

            <!-- OR  -->
            <v-row align="center" no-gutters>
                <v-col cols="12" md="3">
                    {{ $i18n.t("atLeast") }}:
                </v-col>
                <v-col cols="12" md="7">
                    <v-text-field dense v-model="ORtermsList" @keydown.enter.prevent="launchSearch" outlined
                        hide-details clearable class="mb-3"></v-text-field>
                </v-col>
                <v-col cols="2" class="text-center pb-3">

                </v-col>
            </v-row>

            <!-- AND  -->
            <v-row align="center" no-gutters>
                <v-col cols="12" md="3">
                    {{ $i18n.t("allThese") }}:
                </v-col>
                <v-col cols="12" md="7">
                    <v-text-field dense v-model="ANDtermsList" @keydown.enter.prevent="launchSearch" outlined
                        hide-details clearable class="mb-3"></v-text-field>
                </v-col>
                <v-col cols="2" class="text-center pb-3">

                </v-col>
            </v-row>



            <!-- NOT  -->
            <v-row align="center" no-gutters>
                <v-col cols="12" md="3">
                    {{ $i18n.t("none") }}:
                </v-col>
                <v-col cols="12" md="7">
                    <v-text-field dense v-model="NOTtermsList" @keydown.enter.prevent="launchSearch" outlined
                        hide-details clearable class="mb-3"></v-text-field>
                </v-col>
                <v-col cols="2" class="text-center pb-3">

                </v-col>
            </v-row>

            <!-- TAGS -->
            <v-row align="center" no-gutters>
                <v-col cols="12" md="3">
                    Tags:
                </v-col>
                <v-col cols="12" md="7">
                    <v-combobox v-model="selectedTags" :items="tags" item-text="name" clearable multiple dense outlined
                        chips>
                        <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip small v-bind="attrs" :input-value="selected" close @click="select"
                                @click:close="removeSelectedTag(item)">
                                {{ $tools.capitalizeString(item.name) }}
                            </v-chip>

                        </template>
                    </v-combobox>
                </v-col>
                <v-col cols="2" class="text-center pb-3">
                    <v-switch :label="tagsOperatorIsAnd ? 'AND' : 'OR'" v-model="tagsOperatorIsAnd"
                        class="ml-3"></v-switch>
                </v-col>
            </v-row>

            <!-- SOURCES -->
            <v-row align="center" no-gutters>
                <v-col cols="12" md="3">
                    Sources:
                </v-col>
                <v-col cols="12" md="7">
                    <v-autocomplete dense outlined clearable v-model="selectedSource" :items="sources" item-text="name"
                        item-value="ref"></v-autocomplete>
                </v-col>
            </v-row>

            <!-- ACTIONS -->
            <v-card-actions class="">
                <v-spacer></v-spacer>
                <v-switch v-model="switchCO" label="CO ?"></v-switch>
                <v-spacer></v-spacer>
                <span v-if="total">{{ total }} posts</span>
                <span v-if="page > 1" class="ml-5">[<v-icon small color="warning" class="mx-1">mdi-alert</v-icon>page
                    {{ searchStore.page }} in store]</span>
                <v-btn v-if="page > 1" text color="accent" class="ml-2" @click="eventResetPage">Reset page</v-btn>
                <v-spacer></v-spacer>
                <v-checkbox v-model="search_by_date" label="SORT BY DATE"></v-checkbox>
                <v-spacer></v-spacer>
                <v-checkbox v-model="search_by_score" label="SORT BY SCORE"></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn small @click="launchSearch" class="Drawer--text font-weight-bold"
                    :color="headerColor">{{ $i18n.t("search") }}</v-btn>
                <v-btn small outlined @click="eraseSearch" class="" :color="headerColor">{{ $i18n.t("delete") }}</v-btn>
            </v-card-actions>

        </v-card>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {

    name: 'Header',

    data: () => ({
        loading: false,
        headerColor: "accent darken-3",

        // dont mutate props
        headerTotal: 0,

        // text fields
        ANDtermsList: null,
        ORtermsList: null,
        exactPhrase: null,
        NOTtermsList: null,

        tags: null,
        selectedTags: null,
        tagsOperatorIsAnd: true,

        // options
        switchCO: true,
        search_by_date: true,
        search_by_score: false,

        // sources
        sources: null,
        selectedSource: null,
    }),

    props: ['total', 'page'],

    created() {
        if (!this.tagsStore) {
            this.fetchTags();
        } else {
            this.tags = this.tagsStore;
        }
        this.fetchSources();
        this.headerTotal = this.total
    },

    watch: {

        ANDtermsList: {
            deep: true,
            immediate: true,
            handler() {
                if (this.ANDtermsList && this.ANDtermsList.length > 0) {
                    // reset others text fields
                    this.exactPhrase = null;
                    this.ORtermsList = null;
                }
            }
        },

        ORtermsList: {
            deep: true,
            immediate: true,
            handler() {
                // specific reset after clicking on clearable
                if (this.ORtermsList && this.ORtermsList.length > 0) {
                    // reset others text fields
                    this.exactPhrase = null;
                    this.ANDtermsList = null;
                }
            }
        },

        exactPhrase: {
            deep: true,
            immediate: true,
            handler() {
                // specific reset after clicking on clearable
                if (this.exactPhrase && this.exactPhrase.length > 0) {
                    // reset others text fields
                    this.ORtermsList = null;
                    this.ANDtermsList = null;
                }
            }
        },

        // tricky
        // in order for teh component to detect any change in store
        // we need to send it props to update components and launch the whatch
        // if no props, when changing pages on advancedTab, no change detected => no wath for searchStore
        searchStore: {
            deep: true,
            immediate: true,
            handler() {
                if (this.searchStore) {
                    switch (this.searchStore.operator) {
                        case "or":
                            this.ORtermsList = this.searchStore.search;
                            break;
                        case "and":
                            this.ANDtermsList = this.searchStore.search;
                            break;
                        case "phrase":
                            this.exactPhrase = this.searchStore.search;
                            break;
                        default:
                            break;
                    }

                    this.switchCO = false;
                    if (this.searchStore.only_co == 1) {
                        this.switchCO = true;
                    }

                    if (this.searchStore.tags && this.searchStore.tags.length > 0) {
                        this.selectedTags = this.searchStore.tags;
                    }

                    if (this.searchStore.tags_operator == "and") {
                        this.tagsOperatorIsAnd = true;
                    }

                    if (this.searchStore.total) {
                        this.headerTotal = this.searchStore.total;
                    }

                    this.page = this.searchStore.page;
                }
            },
        },

        search_by_date: {
            deep: true,
            immediate: true,
            handler() {
                this.search_by_score = !this.search_by_date;
            }
        },

        search_by_score: {
            deep: true,
            immediate: true,
            handler() {
                this.search_by_date = !this.search_by_score;
            }
        },

    },

    methods: {

        ...mapActions(['updateTags']),

        launchSearch() {

            let search = {
                tags_operator: "or",
                search_by_date: false,
            };

            if (this.ANDtermsList != null) {
                search = {
                    operator: "and",
                    search: this.ANDtermsList,
                }
            }

            if (this.ORtermsList != null) {
                search = {
                    operator: "or",
                    search: this.ORtermsList,
                }
            }

            if (this.exactPhrase != null) {
                search = {
                    operator: "phrase",
                    search: this.exactPhrase,
                }
            }

            search.only_co = false;
            if (this.switchCO) {
                search.only_co = true;
            }

            if (this.NOTtermsList != null) {
                search.must_not = this.NOTtermsList;
            }

            if (this.tagsOperatorIsAnd) {
                search.tags_operator = "and"
            }

            if (this.search_by_date) {
                search.search_by_date = true;
            }

            // tags
            if (this.selectedTags && this.selectedTags.length > 0) {
                search.tags = this.selectedTags;
            }

            if (this.selectedSource && this.selectedSource.length > 0) {
                search.source = this.selectedSource;
            }

            this.$emit('eventLaunchSearch', search);

        },

        eraseSearch() {
            this.ANDtermsList = null;
            this.ORtermsList = null;
            this.exactPhrase = null;
            this.NOTtermsList = null;
            this.selectedTags = null;
            this.$emit('eventResetSearch');
        },

        fetchTags: async function () {
            this.loading = true;
            this.error = null;
            this.tags = [];
            try {
                let res = await this.$api.get("/tags");
                this.tags = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                // update store
                this.updateTags(this.tags);
                this.loading = false;
            }
        },


        fetchSources: async function () {
            this.loading = true;
            this.error = null;
            this.sources = [];
            try {
                let res = await this.$api.get("/search/sources");
                this.sources = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        removeSelectedTag(item) {
            this.selectedTags.splice(this.selectedTags.indexOf(item), 1)
        },

        eventResetPage() {
            console.log("event reset page header")
            this.$emit("eventResetPage")
        },


    },

    computed: {
        ...mapGetters({
            searchStore: 'getAdvancedSearch',
            tagsStore: 'getTags'
        }),
    }
}
</script>

<style></style>