<template>
    <div>
        BANK MAIN
    </div>
</template>

<script>
export default {

    name: 'BankMain',
    
}
</script>

<style>

</style>