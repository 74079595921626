<template>
    <div>
        <v-sheet rounded outlined :color="color">
            <v-card flat>
                <v-card-actions class="py-2">
                    <v-spacer></v-spacer>
                    <v-btn v-if="ghost_clusters.length > 0" small @click="dbscan" class="font-weight-bold Dialog--text" :color="color">MERGE</v-btn>
                    <span v-else small class="font-weight-bold blue--text text--lighten-2" :color="color">NO GHOST CLUSTERS</span>
                    
                    <!-- no merging message -->
                    <v-btn text v-if="error == 'empty'" class="font-weight-bold text-center blue--text text--lighten-2">NO PROPOSITION</v-btn>
                    <span v-if="response.proposals.length > 0" class="ml-2">{{ response.proposals.length }} proposals: {{ response.proposals }}</span>
                    <v-btn small>{{ response.delay  }} ms</v-btn>

                    <v-spacer></v-spacer>
                </v-card-actions>
    
                <!-- loading -->
                <v-progress-linear indeterminate :color="color" :active="loading"></v-progress-linear>
    
                <!-- error -->
                <v-card v-if="error && error != 'empty'" flat color="transparent" key="merger-error-message">
                    <!-- error msg -->
                    <v-card-text class="ml-5 mt-2 error--text">API Error: {{ error }}</v-card-text>
                </v-card>

                <!-- Proposals-->
                <div  v-for="(pr, pr_rank) in response.proposals" :key="'proposal-' + pr_rank">
                    <v-sheet v-if="ghost_clusters && ghost_clusters.length > 0" rounded outlined :color="color" class="ma-1">
                        <v-card  flat class="pb-1">
                            <v-card-title class="Dialog--text blue lighten-2 py-0 font-weight-bold"> Proposal #{{ pr_rank }}: Merge clusters {{ pr }} <v-btn depressed small class="ml-2" color="teal darken-4">{{ proposalEpsilon(pr) }}</v-btn></v-card-title>
        
                            <v-sheet rounded outlined :color="color" v-for="(cl_index, pr_index) in pr" :key="'proposal-cl-' + cl_index" class="mt-1 mx-1">
                                <v-card flat class="pb-0" color="" :key="'cluster-' + cl_index">
                                    <ClusterHeader
                                        :ghost_clusters="ghost_clusters"
                                        :cluster="ghost_clusters[cl_index]"
                                        :pr_rank="pr_rank"
                                        :pr_index="pr_index"
                                        :cl_index="cl_index"
                                        :color="color"
                                        :posts_count="ghost_clusters[cl_index].posts.length"
                                        :proposals="response.proposals"
                                        @eventShowMergedCluster="eventShowMergedCluster"
                                        @eventMergeClusters="eventMergeClusters"
                                        @eventForgetMergeProposal="eventForgetMergeProposal"
                                    />
        
                                    <div v-if="ghost_clusters[cl_index].show">
                                        <div v-for="(p, p_index) in ghost_clusters[cl_index].posts" :key="'cl' + cl_index + '-p_' + p.id">
                                            
                                            <!-- Post -->
                                            <v-sheet  outlined class="mx-1 mt-1" :color="color">
                                                <v-card tile flat color="" class="d-flex" key="cluster-post-and-widget">
                                                    
                                                    <ClusterPostWidget :key="'widget_' + cl_index + '-p_' + p.id"
                                                        :post="p" 
                                                        :cl_index="cl_index" 
                                                        :p_index="p_index"
                                                        :color="color" 
                                                        @eventPostInNewCluster="eventPostInNewCluster"
                                                    />
                                                    
                                                    <ClusterPost :key="'post_' + cl_index + '-p_' + p.id"
                                                        :post="p" 
                                                        :cl_index="cl_index" 
                                                        :p_index="p_index"
                                                        :color="color"
                                                        @openClusterPostsSingleDialog="openClusterPostsSingleDialog"
                                                        @eventGoTags="eventGoTags" 
                                                        @eventGoLoot="eventGoLoot" 
                                                    ></ClusterPost>
        
                                                </v-card>
                                            </v-sheet>
        
                                        </div>
                                    </div>
                                </v-card>
                            </v-sheet>
                           
                        </v-card>
        
                    </v-sheet>
                </div>
    
            </v-card>
        </v-sheet>

        <!-- Dialogs -->
        <v-dialog v-model="dialogs" key="merger-dialogs" fullscreen overlay-opacity="">
            <v-card flat color="" key="merger-dialogs-card">
                <!-- Cluster Post Single -->
                <DialogClusterSingle v-if="cluster_single_dialog" 
                    :cluster_single="cluster_single"
                    @eventCloseClusterPostDialog="eventCloseClusterPostDialog"
                    @eventGoTags="eventGoTags" 
                    @eventGoLoot="eventGoLoot" 
                    :color="color" 
                />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ghost from "@/common/ghost";
import deck from "@/common/deck";
import cluster_vector_operations from "@/common/cluster/vector_operations";


import ClusterHeader from './MergerClusterHeader.vue';
import ClusterPost from '../Deck/DeckCluster/DeckClusterPost.vue'
import DialogClusterSingle from '../Deck/DialogClusterSingle.vue'
import ClusterPostWidget from './MergerPostWidget.vue'

export default {

    name: 'MergerTab',

    components: {
        ClusterHeader,
        ClusterPost,
        DialogClusterSingle,
        ClusterPostWidget,
    },

    props: ['ghost_clusters','parameters'],

    data: () => ({

        loading: false,
        error: null,
        color: "blue lighten-2",
        response: {
            proposals: [],
            delay: 0,
        },

        dialogs: false,
        scrolling: 0,

        //cluster post
        cluster_single_dialog: false,
        cluster_single: {
            cl_index: null,
            p_index: null,
            post: null,
        },

    }),

    created() {
        this.proposals = [];
        this.error = null;
    },


    methods: {

        dbscan: async function () {

            console.log("Merger DBSCAN clusters len=", this.ghost_clusters.length);

            if (this.ghost_clusters.length < 2) {
                return;
            }

            this.loading = true;
            this.error = null;
            this.response = {
                proposals: [],
                delay: 0,
            };


            let vectors = [];
            for (let cl of this.ghost_clusters) {
                vectors.push(cl.centroid);
            }

            let payload = {
                vectors: vectors,
                epsilon: this.parameters.merger_epsilon,
            }

            if (vectors.length == 0) {
                alert("No vectors to merge");
                return
            }

            if (this.parameters.merger_epsilon == 0) {
                alert("Epsilon must be greater than 0");
                return
            }   

            try {
                let res = await this.$api.post("/ghost/merger/dbscan", payload);
                this.response = res.data;
                this.delay = Math.round( 10 * this.response.delay) / 10;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
            
        },

        //* EVENTS
        eventShowMergedCluster(cl_key) {
            ghost.setSkimmerScroll(window.scrollY);
            console.log("Merger - eventShowMergedCluster key=", cl_key," cl_index=", this.clusterKeyGivesIndex(cl_key));
            this.$emit("eventShowMergedCluster",this.clusterKeyGivesIndex(cl_key))
        },

        eventMergeClusters(from_key, to_key, pr_rank, pr_index) {
            ghost.setMergerScroll(window.scrollY);
            this.response.proposals[pr_rank].splice(pr_index, 1);
            console.log("Merger - eventMergeClusters from", from_key, " to", to_key);
            this.$emit("eventMergeClusters", this.clusterKeyGivesIndex(from_key), this.clusterKeyGivesIndex(to_key));
        },

        clusterKeyGivesIndex(key) {
            for (let cl of this.ghost_clusters) {
                if (cl.key == key) {
                    return this.ghost_clusters.indexOf(cl);
                }
            }
        },

        eventForgetMergeProposal(pr_rank, pr_index) {
            console.log("eventForgetMergeProposal", pr_rank, pr_index);
            this.response.proposals[pr_rank].splice(pr_index, 1);
        },

        eventPostInNewCluster(cl_index, p_index, post) {
            console.log("eventPostInNewCluster", cl_index, p_index, post);
            this.$emit("eventPostInNewCluster", cl_index, p_index, post);
        },

        // Cluster Post
        openClusterPostsSingleDialog(cl_index, p_index) {
            console.log("openClusterPostsSingleDialog", cl_index, p_index);
            this.dialogs = true;
            this.cluster_single_dialog = true;
            this.cluster_single.post = this.ghost_clusters[cl_index].posts[p_index];
            this.cluster_single.cl_index = cl_index;
            this.cluster_single.p_index = p_index;
            this.scrolling = window.scrollY;
            deck.setClusterDialog(true);
            deck.setClusterSingle(this.ghost_clusters[cl_index].posts[p_index]);
            deck.setClusterIndex(cl_index);
            deck.setClusterPostIndex(p_index);
            ghost.setMergerScroll(window.scrollY);
        },

        eventCloseClusterPostDialog() {
            console.log("eventCloseClusterPostDialog");
            this.dialogs = false;
            this.cluster_single_dialog = false;
            this.cluster_single.post = null;
            this.cluster_single.cl_index = 0;
            this.cluster_single.p_index = 0;
            deck.setClusterDialog(false);
            deck.setClusterSingle(null);
            deck.setClusterIndex(0);
            deck.setClusterPostIndex(0);
            this.scrolling = window.scrollY;
        },

        eventGoTags(cl_index, p_index) {
            ghost.setMergerScroll(window.scrollY);
            this.$router.push({ path: "/main/tools_post_tags_tab/" + this.clusters[cl_index].posts[p_index].id })
        },

        eventGoLoot(cl_index, p_index) {
            ghost.setMergercroll(window.scrollY);
            this.$router.push({ path: "/main/tools_post_loot_tab/" + this.clusters[cl_index].posts[p_index].id })
        },


        // Clusters proposals epsilon
        proposalEpsilon(proposal) {
            let vectors =[];
            for (let pr of proposal) {
                vectors.push(this.ghost_clusters[pr].centroid);
            }
            if (vectors.length < 2) {
                return 0;
            }
            let epsilon = cluster_vector_operations.maxCosineDistanceFromVectors(vectors);
            return Math.round(epsilon * 1000) / 1000;
        }

    },

    computed: {

    },

}
</script>

<style>

</style>