<template>
    <div>

        <!-- no skimming message -->
        <v-sheet rounded outlined :color="color">
            <v-card flat key="skimmer-empty-message">
                <v-card-actions  class="py-2">
                    <v-spacer></v-spacer>
                    <v-btn v-if="ghost_clusters.length > 0" small @click="skimmer" class="font-weight-bold Dialog--text" :color="color">SKIM</v-btn>
                    <v-btn v-if="skimmer_clusters.length > 0" small class="font-weight-bold Dialog--text ml-2" :color="color">{{ skimmer_clusters.length }} proposals</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-sheet>
       
        <v-sheet v-if="skimmer_clusters.length > 0" rounded outlined :color="color" class="mt-2" key="skimmer-clusters-list">
            <v-card flat>
                <v-card v-for="(cluster, cl_index) in skimmer_clusters" :key="'cl_' + cl_index" class="">
                    
                    <!-- Cluster -->
                    <v-sheet :key="'sheet-cluster-' + cl_index" outlined rounded :color="color" class="">
                        <v-card flat class="pb-1" color="" :key="'cluster-' + cl_index">

                            <ClusterHeader v-if="cluster.posts.length > 0" :key="'cluster-header-' + cl_index"
                                :cluster="cluster" 
                                :color="color"
                                :posts_count="cluster.posts.length"
                                :cl_index="cl_index"
                                :tags_list="tags_list"
                                @eventShowCluster="eventShowCluster"
                                @eventUnskimmedCluster="eventUnskimmedCluster"
                            ></ClusterHeader>

                            <div v-if="cluster.show">
                                <div v-for="(p, p_index) in cluster.posts" :key="'cl' + cl_index + '-p_' + p.id">
                                    <!-- Post -->
                                    <v-sheet  outlined class="mx-1 mt-1" :color="color">
                                        <v-card tile flat color="" class="d-flex" key="cluster-post-and-widget">
        
                                            <ClusterPostWidget :key="'widget_' + cl_index + '-p_' + p.id"
                                                :post="p" 
                                                :cl_index="cl_index" 
                                                :p_index="p_index"
                                                :deck_cl_index="cluster.deck_cl_index"
                                                :cluster_posts_count="cluster.posts.length"
                                                :new_max_centroid_radius="newMaxCentroidRadiusAfterSkimming(cl_index, p_index)"
                                                :centroid_radius="centroidRadius(cl_index, p_index)"
                                                :color="color" 
                                                @eventPostInNewCluster="eventPostInNewCluster"
                                            />
        
                                            <ClusterPost :key="'post_' + cl_index + '-p_' + p.id"
                                                :post="p" 
                                                :cl_index="cl_index" 
                                                :p_index="p_index"
                                            
                                                @openClusterPostsSingleDialog="openClusterPostsSingleDialog"
                                                @eventGoTags="eventGoTags" 
                                                @eventGoLoot="eventGoLoot"
                                                @eventVote="eventVote"
                                            />
                                        
        
                                        </v-card>
                                    </v-sheet>
                                </div>
                            </div>
                        </v-card>
                    </v-sheet>
                </v-card>
            </v-card>
        </v-sheet>

        <!-- Dialogs -->
        <v-dialog v-model="dialogs" key="skimmer-dialogs" fullscreen overlay-opacity="">
            <v-card flat color="" key="skimmer-dialogs-card">
                <!-- Cluster Post Single -->
                <DialogClusterSingle v-if="cluster_single_dialog" 
                    :cluster_single="cluster_single"
                    :color="color" 
                    @eventCloseClusterPostDialog="eventCloseClusterPostDialog"
                    @eventGoTags="eventGoTags" 
                    @eventGoLoot="eventGoLoot" 
                    @eventVote="eventVote"
                />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import cluster_vector_operations from "@/common/cluster/vector_operations";
import ghost from "@/common/ghost";
import deck from "@/common/deck";

import ClusterHeader from './SkimmerClusterHeader.vue'
import ClusterPost from '../Deck/DeckCluster/DeckClusterPost.vue'
import ClusterPostWidget from './SkimmerPostWidget.vue'
import DialogClusterSingle from '../Deck/DialogClusterSingle.vue'

export default {

    name: 'SkimmerTab',

    props: ['ghost_clusters','parameters', 'tags_list'],

    components: {
        ClusterHeader,
        ClusterPost,
        ClusterPostWidget,
        DialogClusterSingle
    },

    data: () => ({
        color: "orange accent-2",
        dialogs: false,
        scrolling: 0,

        skimmer_clusters: [],

        //cluster post
        cluster_single_dialog: false,
        cluster_single: {
            cl_index: null,
            p_index: null,
            post: null,
        },
    }),

    watch:  {
        ghost_clusters: {
            immediate: true,
            deep: true,
            handler() {
                if (this.ghost_clusters.length == 0) {
                    this.skimmer_clusters = [];
                }
            }
        }
    },


    methods: {

        //* SKIMMER
        skimmer() {
            console.log("skimming ghost_clusters len=", this.ghost_clusters.length);
            this.skimmer_clusters = [];

            if (this.parameters.skimmer_centroid_distance == 0) {
                alert("Skimmer Centroid Distance must be greater than 0");
                return
            }
           
            for (let [cl_index, cl] of this.ghost_clusters.entries()) {
                if (cl.posts.length > 1) {
                    
                    // Create a deep copy of `cl` to avoid modifying the original cluster
                    let updated_cluster = JSON.parse(JSON.stringify(cl));
                    updated_cluster.skimmed = false;
                    updated_cluster.posts = []; // Clear posts to avoid duplication
                    
                    for (let p of cl.posts) {
                        let cosine_distance = cluster_vector_operations.cosineDistance(p.vector, cl.centroid)
                        let updated_post = JSON.parse(JSON.stringify(p)); // Safely clone each post
                        if (cosine_distance > this.parameters.skimmer_centroid_distance) {
                            updated_cluster.skimmed = true;
                            updated_post.skimmed = true
                            updated_post.cosine_distance = Math.round(1000 * cosine_distance);
                        }
                        updated_cluster.posts.push(updated_post);
                    }

                    if (updated_cluster.skimmed) {
                        updated_cluster.deck_cl_index = cl_index;
                        this.skimmer_clusters.push(updated_cluster);
                    }
                }
            }
            
        },


        // return the radius of a vector from the centroid
        newMaxCentroidRadiusAfterSkimming(cl_index, p_index) {
            let vectors = [];
            for (let [index,p] of this.skimmer_clusters[cl_index].posts.entries()) {
                if (index != p_index) {
                    vectors.push(p.vector);
                }
            }

            let radius = 0;
            if (vectors.length > 1) {
                let new_centroid = cluster_vector_operations.buildCentroidWithVectors(vectors);
    
                radius = cluster_vector_operations.maxCosineDistanceFromCentroid(new_centroid, vectors);
            }
            return Math.round(10000 * radius) / 10000;
        },


        centroidRadius(cl_index, p_index) {
            let radius = cluster_vector_operations.maxCosineDistanceFromCentroid(this.skimmer_clusters[cl_index].centroid, [this.skimmer_clusters[cl_index].posts[p_index].vector]);
            return Math.round(10000 * radius) / 10000;
        },


        //* EVENTS
        eventShowCluster(deck_cl_index, cl_index) {
            console.log("Skimmer", deck_cl_index, cl_index);
            this.skimmer_clusters[cl_index].show = !this.skimmer_clusters[cl_index].show;
            this.$emit("eventShowCluster", deck_cl_index)
        },

        eventPostInNewCluster(deck_cl_index, p_index, post, cl_index) {
            // remove locally from skimmed clusters
            this.skimmer_clusters[cl_index].posts.splice(p_index, 1);
            this.$emit("eventPostInNewCluster", deck_cl_index, p_index, post);
        },

        eventUnskimmedCluster(cl_index){
            this.skimmer_clusters.splice(cl_index, 1);
        },

        //* Dialogs

        // Cluster Post
        openClusterPostsSingleDialog(cl_index, p_index) {
            console.log("openClusterPostsSingleDialog", cl_index, p_index);
            this.dialogs = true;
            this.cluster_single_dialog = true;
            this.cluster_single.post = this.skimmer_clusters[cl_index].posts[p_index];
            this.cluster_single.cl_index = cl_index;
            this.cluster_single.p_index = p_index;
            this.scrolling = window.scrollY;
            deck.setClusterDialog(true);
            deck.setClusterSingle(this.skimmer_clusters[cl_index].posts[p_index]);
            deck.setClusterIndex(cl_index);
            deck.setClusterPostIndex(p_index);
            ghost.setSkimmerScroll(window.scrollY);
        },

        eventCloseClusterPostDialog() {
            console.log("eventCloseClusterPostDialog");
            this.dialogs = false;
            this.cluster_single_dialog = false;
            this.cluster_single.post = null;
            this.cluster_single.cl_index = 0;
            this.cluster_single.p_index = 0;
            deck.setClusterDialog(false);
            deck.setClusterSingle(null);
            deck.setClusterIndex(0);
            deck.setClusterPostIndex(0);
            this.scrolling = window.scrollY;
        },

        eventGoTags(cl_index, p_index) {
            ghost.setSkimmerScroll(window.scrollY);
            this.$router.push({ path: "/main/tools_post_tags_tab/" + this.skimmer_clusters[cl_index].posts[p_index].id })
        },

        eventGoLoot(cl_index, p_index) {
            ghost.setSkimmerScroll(window.scrollY);
            this.$router.push({ path: "/main/tools_post_loot_tab/" + this.skimmer_clusters[cl_index].posts[p_index].id })
        },

        eventVote(cl_index, p_index) {
            this.$emit("eventVote", cl_index, p_index)
            this.skimmer_clusters[cl_index].posts.splice(p_index, 1);
            this.eventCloseClusterPostDialog();
        },



    },

    computed: {

     
    },

}
</script>

<style>

</style>