const state = {
    parameters: {
        search: "",
        selected_tags: [],
        page: 1,
        clusters: [],
        total: 0,
    }

};

const getters = {

    getClustersParameters() {
        return state.parameters;
    },

};

const actions = {

    updateClustersParameters: function({ commit }, obj) {
        commit('setClustersParameters', obj);
    },

    resetClustersParameters: function({ commit }) {
        commit('setResetClustersParameters');
    },

};

const mutations = {

    setClustersParameters: function(state, obj) {
        state.parameters = obj;
    },

    setResetClustersParameters: function(state) {
        
        state.parameters = {
            search: "",
            selected_tags: [],
            page: 1,
            clusters: [],
            total: 0,
        }
        
    },

};


export default {
    state,
    getters,
    actions,
    mutations,
};
