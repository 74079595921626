<template>
    <div>
        <v-card outlined flat color="" class="ma-1" key="images-publication-main-card">
            <Draggable v-model="publication_catalogue" group="imgs"
                class="d-flex overflow-y-auto transparent"
                key="draggable"
                @change="onDragEnd"
            >
                <v-sheet v-for="(img,i_index) of publication_catalogue" 
                    :key="'img-' + i_index"   
                    outlined rounded 
                    :color="img.is_community ? 'purple accent-1':''" width="280"
                    class="ma-1"
                >
                    <v-card flat >
                        <v-img 
                            size="140"
                            class=""
                            rounded
                            :src="img.url"
                            contain
                            min-width="250"
                            height="160"
                            min-height="140"
                        >
                        <v-overlay 
                            absolute
                            opacity="0"
                            class="d-flex align-start justify-end pa-1"
                        >
                            
                            <v-icon @click="unselectImage(i_index)" color="lime">mdi-close</v-icon>
                           
                        </v-overlay>

                    
                        </v-img>           
                        
                    </v-card>
                </v-sheet>
            </Draggable>
        </v-card>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {

    name: 'ImagesForPublication',

    components: {
        Draggable,
    },

    props: ['catalogue_selected_images'],

    data: () => ({
        publication_catalogue:[],
    }),

    created() {
        this.publication_catalogue = this.catalogue_selected_images;
        this.$emit("eventPublicationCatalogue", this.publication_catalogue)
    },

    watch: {
        catalogue_selected_images: {
            deep: true,
            immediate: true,
            handler() {
                this.publication_catalogue = this.catalogue_selected_images;
                this.$emit("eventPublicationCatalogue", this.publication_catalogue)
            },
        },
    },

    methods: {
        onDragEnd() {
            this.$emit("eventPublicationCatalogue", this.publication_catalogue)
        },

        unselectImage(index) {
            this.publication_catalogue.splice(index,1);
            this.$emit("eventPublicationCatalogue", this.publication_catalogue)
        },
    },

}
</script>

<style>

</style>