<template>
    <div>
        <v-sheet rounded outlined :color="color">
            <v-card flat outlined key="recaller-parameters">
                <v-card-actions class="pt-3">

                    <v-spacer></v-spacer>
                    
                    <!-- day offset -->
                    <v-text-field
                        outlined dense
                        v-model.number="parameters.days_depth"
                        label="Days Depth"
                        hide-details=""
                        :color="color"
                        class="mx-2"
                        style="max-width: 90px;"
                    ></v-text-field>

                    <!-- min_score -->
                    <v-text-field
                        outlined dense
                        v-model.number="parameters.min_score"
                        label="Min Score [0,2]"
                        hide-details=""
                        :color="color"
                        class="mx-2"
                        style="max-width: 130px;"
                    ></v-text-field>

                    <!-- Recaller -->
                    <v-btn v-if="ghost_clusters.length > 0" small @click="recaller" class="font-weight-bold Dialog--text" :color="color">RECALL</v-btn>

                    <v-btn small>{{ delay  }} s</v-btn>

                    <v-btn small class="font-weight-bold Dialog--text" :color="color">{{ response.proposals.length }} proposals</v-btn>

                    <v-spacer></v-spacer>
                </v-card-actions>

                <!-- loading -->
                <v-progress-linear indeterminate :color="color" :active="loading"></v-progress-linear>

                <!-- error -->
                <v-card v-if="error && error != 'empty'" flat color="transparent" key="recaller-error-message">
                    <!-- error msg -->
                    <v-card-text class="ml-5 mt-2 error--text">API Error: {{ error }}</v-card-text>
                </v-card>
            </v-card>
        </v-sheet>
        
        <!-- Proposals -->
        <div  v-if="response.proposals.length > 0 && recaller_clusters && recaller_clusters.length > 0">
            <v-sheet v-for="(proposal, pr_index) in response.proposals" :key="pr_index" rounded outlined class="mt-2" :color="color">

                <!-- Proposal -->
                <v-card flat color="">
                    
                    <DeckClusterHeader :key="recaller_clusters[proposal.ghost_cluster_index].key"
                        :cluster="recaller_clusters[proposal.ghost_cluster_index]"
                        :cl_index="proposal.ghost_cluster_index"
                        :proposal_index="pr_index"
                        :tags_list="tags_list" 
                        :color="color"
                        @eventShowRecalledCluster="eventShowRecalledCluster"
                        @eventForgetProposal="eventForgetProposal"
                        @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                        @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                    />

                    <div v-if="recaller_clusters[proposal.ghost_cluster_index].show">
                        <div v-for="(p, p_index) in recaller_clusters[proposal.ghost_cluster_index].posts" :key="'cl' + proposal.ghost_cluster_index + '-p_' + p.id">
                            <v-sheet outlined color="yellow" class="mx-1">
                                <ClusterPost :key="'post_' + proposal.ghost_cluster_index + '-p_' + p.id"
                                    :post="p" 
                                    :cl_index="proposal.ghost_cluster_index" 
                                    :p_index="p_index"
                                    :color="color"
                                    @openClusterPostsSingleDialog="openClusterPostsSingleDialog"
                                />

                            </v-sheet>
                            
                        </div>
                    </div>
                    
                    <div v-for="(cluster,cl_index) in proposal.clusters" :key="cluster.id" class="py-2">
                        <ClusterProposal 
                            :ghost_parameters="ghost_parameters"
                            :proposal_index="pr_index"
                            :cluster="cluster"
                            :cluster_header="recaller_clusters[proposal.ghost_cluster_index]"
                            :cluster_header_key="recaller_clusters[proposal.ghost_cluster_index].key"
                            :cl_index="cl_index"
                            :clusters_count="proposal.clusters.length"
                            @eventForgetProposal="eventForgetProposal"
                            @eventRemoveClusterFromDeck="eventRemoveClusterFromDeck"
                            class="ml-2 mr-1"
                        />

                    </div>

                </v-card>
            
            </v-sheet>
        </div>

        <!-- Dialogs -->
        <!-- Cluster Post Single -->
        <v-dialog v-model="dialog" key="recaller-dialogs" fullscreen overlay-opacity="">
            <DialogClusterSingle v-if="cluster_single"
                :cluster_single="cluster_single"
                :color="color"
                @eventCloseClusterPostDialog="eventCloseClusterPostDialog" 
            />
        </v-dialog>
        
       

    </div>
</template>

<script>
import ghost from "@/common/ghost";

import DeckClusterHeader from './RecallerDeckClusterHeader.vue';
import ClusterProposal from './RecallerClusterProposal.vue';
import ClusterPost from '../Deck/DeckCluster/DeckClusterPost.vue'
import DialogClusterSingle from '../Deck/DialogClusterSingle.vue'

export default {

    name: 'RecallerTab',

    props: ['ghost_clusters', 'ghost_parameters', 'tags_list'],

    components: {
        DeckClusterHeader,
        ClusterProposal,
        ClusterPost,
        DialogClusterSingle,
    },

    data: () => ({

        loading: false,
        error: null,

        parameters: {
            days_depth: 30,
            min_score: 1.99,
        },

        recaller_clusters: [],

        response: {
            proposals: [],
            delay: 0,
        },

        delay: 0,
       
        scrolling: 0,
        color: "pink lighten-2",

        dialog: false,
        cluster_single: {
            cl_index: null,
            p_index: null,
            post: null,
        },
    }),

    created() {

        this.response = {
            proposals: [],
            delay: 0,
        },
        this.error = null;

        // this.scrolling = ghost.retriveRecallerScroll()
        // window.scrollTo(0, this.scrolling)
    },


    watch:  {
        // ghost_clusters: {
        //     deep: true,
        //     immediate: true,
        //     handler() {
        //         console.log("recaller watcher ghost_clusters", this.ghost_clusters.length);
        //     }
        // },

        // recaller_clusters: {
        //     deep: false,
        //     immediate: false,
        //     handler() {
        //         console.log("recaller watcher recaller_clusters", this.recaller_clusters.length);
        //     }
        // }
    },


    methods: {

        recaller: async function () {
            
            console.log("recaller");
            
            // WARNING:
            // deep copy of the ghost clusters
            // if simple =, when ghost clusters changed recaller clusters as well !!
            // they are linked
            this.recaller_clusters = JSON.parse(JSON.stringify(this.ghost_clusters));   

            if (this.parameters.days_depth == 0) {
                alert("Days Depth must be greater than 0");
                return
            }


            if (this.parameters.min_score == 0) {
                
                alert("Min Score must be greater than 0");
                return
            }
            
            let vectors = [];
            for (let cl of this.ghost_clusters) {
                vectors.push(cl.centroid);
            }

            if (vectors.length == 0) {
                alert("No vectors to recall");
                return
            }

            this.response = {
                proposals: [],
                delay: 0,
            }


            this.loading = true;
            this.error = null;
            this.proposals = [];

            let payload = {
                vectors: vectors,
                min_score: this.parameters.min_score,
                days_depth: this.parameters.days_depth,
            }

            try {
                let res = await this.$api.post("/ghost/recaller", payload);
                this.response = res.data;
                this.delay = Math.round(10 * res.data.delay) / 10;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }
        },

        //* Events
        eventShowRecalledCluster(cl_index) {
            console.log("eventShowRecalledCluster", cl_index);
            this.recaller_clusters[cl_index].show = !this.recaller_clusters[cl_index].show;
        },

        eventForgetProposal(proposal_index) {
            console.log("eventForgetProposal", proposal_index);
            this.response.proposals.splice(proposal_index, 1);
        },

        eventRemoveClusterFromDeck(cl_header_key) {
            this.$emit("eventRemoveClusterFromDeck", cl_header_key);
        },

        eventRemoveTagFromCluster(cl_index, ref) {
            ghost.setRecallerScroll(window.scrollY);
            this.$emit("eventRemoveTagFromCluster", cl_index, ref)
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            ghost.setRecallerScroll(window.scrollY);
            this.$emit("eventRemoveLootFromCluster", cl_index, ref)
        },

        // Cluster Post
        openClusterPostsSingleDialog(cl_index, p_index) {
            console.log("openClusterPostsSingleDialog", cl_index, p_index);
            this.dialog = true;
            this.cluster_single.post = this.ghost_clusters[cl_index].posts[p_index];
            this.cluster_single.cl_index = cl_index;
            this.cluster_single.p_index = p_index;
        },

        eventCloseClusterPostDialog() {
            console.log("eventCloseClusterPostDialog");
            this.dialog = false;
            this.cluster_single.post = null;
            this.cluster_single.cl_index = 0;
            this.cluster_single.p_index = 0;
        },
    }

}
</script>

<style>

</style>