<template>
    <div>
        <Single v-if="cluster_single.post"
            :single="cluster_single.post"
            :index="cluster_single.p_index"
            :isUserProfileTrainer="true"
            :colorView="color"
            tab="deck"
            @eventCloseSingleDialog="eventCloseSingleDialog"
            @eventGoFramer="eventGoFramer"
            @eventGoTags="eventGoTags"
            @eventGoLoot="eventGoLoot"
            @eventVote="eventVote"
            @eventGoSource="eventGoSource"
            @eventTranslation="eventTranslation"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Single from '../../Profiles/Subscriber/SubscriberDialogSingle.vue'

export default {

    name: 'ClusterPostDialog',

    components: {
        Single,
    },

    props: ['cluster_single','color'],


    methods: {

        eventCloseSingleDialog(){
            this.$emit("eventCloseClusterPostDialog");
        },

        //* FRAMER
        eventGoFramer() {
            this.$emit("eventGoFramer", this.cluster_single.cl_index, this.cluster_single.p_index);
        },

        // Go tools Tags
        eventGoTags() {
            this.$emit("eventGoTags", this.cluster_single.cl_index, this.cluster_single.p_index);
        },

        // Go tools Loot
        eventGoLoot() {
            this.$emit("eventGoLoot", this.cluster_single.cl_index, this.cluster_single.p_index);
        },

        // Vote
        eventVote() {
            this.$emit("eventVote", this.cluster_single.cl_index, this.cluster_single.p_index)
        },

        // go source
        eventGoSource() {
            this.$emit("eventGoSource", this.cluster_single.cl_index, this.cluster_single.p_index)
        },


        //* Translations
        eventTranslation(output_language, content, description, title) {
            console.log("eventTranslation",  output_language, content.length, description.length, title)
            if (this.cluster_single.post.translations.length > 0) {
                for (let tr of this.cluster_single.post.translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title =title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },

        setTranslations(language) {
            if (this.cluster_single.post.translations) {
                for (let trans of this.cluster_single.post.translations) {
                    if (trans.output_language == language) {
                        if (trans.title.length > 0) {
                            this.title = "[" + trans.title.trim() + "]";
                        }
                        if (trans.description.length > 0) {
                            this.description = "[" + trans.description.trim() + "]";
                        }
                    }
                }
            }
        },
    },

    computed: {
        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),
    },
}
</script>

<style>

</style>