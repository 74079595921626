<template>
    <div>
        <v-card flat outlined :color="noContinentColor" width="220px">

            <v-autocomplete
                dense
                outlined
                :items="continents"
                v-model="selected_continent"
                hide-details
                @change="eventChangeContinent"
                :color="color"
                :key="key"
            >
                <template v-slot:selection="data">
                    {{ data.item }}
                </template>
            
            </v-autocomplete>
           
        </v-card>
    </div>
</template>

<script>
export default {

    name: 'ContinentWidget',

    props: ['cluster','color'],

  
    

    data: () => ({

        continents: [
            "oceania",
            "down-under",
            "asia",
            "middle-east",
            "soviet",
            "turkiye",
            "europe",
            "africa",
            "north-america",
            "mexico",
            "caribbean",
            "brazil",
            "south-america",
            "antartica",
        ],

        selected_continent: null,

        key: 0,

    }),

    created() {
        if (this.cluster) {
            this.selected_continent = this.cluster.continent_name
        }
    },


    watch: {
        cluster: {
            deep: true,
            immediate: true,
            handler() {
                this.selected_continent = this.cluster.continent_name
                this.key = this.key + 1
            },
        },
    },


    methods: {
        eventChangeContinent() {
            this.$emit("eventChangeContinent", this.selected_continent, this.continents.indexOf(this.selected_continent));
        },
    },

    computed: {
        noContinentColor() {
            if(!this.selected_continent) {
                return "red"
            } else {
                return "transparent"
            }
        }
    }

}
</script>

<style>

</style>