<template>
    <div>

        <v-card flat color="">
            <!-- add -->
            <v-chip x-small @click="openDialogAddTag" color="blue" class="Dialog--text mr-1">
                <v-icon small >mdi-plus</v-icon>
            </v-chip>

            <!-- posts tags -->
            <span v-for="(t, index) in builClusterTags" :key="'t-' + index" :class="chipClass(t)">
                <!-- description tool tip -->
                <v-tooltip v-if="t.description.length > 0" bottom max-width="700" class="mt-3">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="goToTag(t)"
                            tile
                            outlined
                            x-small
                            v-bind="attrs"     
                            v-on="on"
                            class="ml-1 text-subtitle-2 pt-1 px-1 mr-1"
                            :color="chipColor(t)"
                        >{{ $tools.capitalizeString(t.name)}}</v-btn>
                    </template>
                    <span v-html="t.description"></span>
                </v-tooltip>
                <span v-else @click="goToTag(t)" class="ml-1 text-subtitle-2 pt-1 px-1">{{ $tools.capitalizeString(t.name) }}</span>

                <span v-if="t.count > 1" class="mr-1">({{t.count}})</span>
                <v-icon @click="removeTagfromClusterPosts(t)" small :color="chipColor(t)" class="mr-1">mdi-close-circle</v-icon>
            </span>
       
        </v-card>

        

        <!-- DIALOG ADD TAG -->
        <v-dialog v-model="dialog" width="400">
            <v-sheet rounded outlined color="purple">
                <v-card flat>

                    <v-card-title class="purple font-weight-bold">CLUSTER #{{cl_index}} TAG</v-card-title>

                    <!-- loading -->
                    <v-progress-linear
                        indeterminate
                        color="purple"
                        :active="loading">
                    </v-progress-linear>

                    <v-card-text class="">
                        
                        <v-autocomplete v-if="search_tag_by_name"
                            outlined
                            dense
                            v-model="selected_tag"
                            :items="tags_list"
                            item-text="name"
                            color="purple"
                            clearable
                            required
                            class="mt-2"
                            return-object
                            hide-details
                        ></v-autocomplete>
                        <v-autocomplete v-else
                            outlined
                            dense
                            v-model="selected_tag"
                            :items="tags_list"
                            item-text="ref"
                            color="purple"
                            clearable
                            required
                            class="mt-2"
                            return-object
                            hide-details
                        ></v-autocomplete>
                        
                    </v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn rounded small v-if="search_tag_by_name" @click="search_tag_by_name = !search_tag_by_name">Search by name</v-btn>
                    <v-btn rounded small v-else @click="search_tag_by_name = !search_tag_by_name">Search by ref</v-btn>

                    <v-spacer></v-spacer>
                    <v-btn small
                        color="purple"
                        @click="addTagToClusterPosts"
                    >
                        Add
                    </v-btn>

                    <v-btn small
                        color=""
                        text
                        @click="dialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-sheet>
        </v-dialog>
    </div>
</template>

<script>
export default {

    name: 'DeckTagsWidget',
    
    props: ['cluster','tags_list','cl_index'],

    data: () => ({
        loading: false,
        error : null,
        selected_tag: null,
        dialog: false,
        search_tag_by_name: true,
    }),

    methods: {

        //* Add Tag to post
        openDialogAddTag() {
            this.dialog = true;
            this.selected_tag = null;
        },
        
        addTagToClusterPosts: async function() {
            console.log("adding new tag=", this.selected_tag.ref);
            
            this.loading = true;
            this.error = null;


            // it needs to be sequential since all posts doesnt have the same language => index
            // so the update by query has to be sequential
            for (let p of this.cluster.posts) {

                let payload = {
                    post_id: p.id,
                    ref: this.selected_tag.ref,
                    channel: this.selected_tag.channel,
                    language: p.language,
                }

                // alerts
                if (payload.channel != "country" && payload.channel != "crimorg") {
                    window.alert("tag not in channel country/crimorg");
                    this.dialog = false
                    return 
                }

                if (payload.ref.length == 0) {
                    window.alert("tag ref empty !");
                    this.dialog = false
                    this.loading = false
                    return 
                }

               
                if (this.buildClusterTagsList().includes(payload.ref)) {
                    window.alert("tag" + payload.ref + "already in !");
                    this.dialog = false
                    this.loading = false
                    return 
                }

                try {
                    await this.$api.post("/tagger/tag/widget/add", payload);
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error += data.message + "/";
                } finally {
                    // add locally if success
                    p.tags.push(this.selected_tag)
                }

            }
            this.$emit("eventSortClusters")
            this.loading = false
            this.dialog = false
        },

        removeTagfromClusterPosts: async function(t) {
            console.log("removing tag from posts in cluster", this.cl_index, "with=", t.ref);
            this.error = null;
            // it needs to be sequential since all posts doesnt have the same language => index
            // so the update by query has to be sequential
            for (let p of this.cluster.posts) {
                
                let payload = {
                    post_id: p.id,
                    ref: t.ref,
                    channel: t.channel,
                    language: p.language,
                }

                try {
                    await this.$api.post("/tagger/tag/widget/remove", payload);
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error += data.message + ";";
                }
            }
            this.dialog = false
            this.$emit("eventRemoveTagFromCluster", this.cl_index, t.ref)
        },

        // duplicates are pushed on themself
        buildClusterTagsList() {
            let list = [];
             if (this.cluster.posts && this.cluster.posts.length > 0) {
                for (let p of this.cluster.posts) {
                    if (p.tags && p.tags.length > 0) {
                        for (let t of p.tags) {
                            list.push(t.ref);
                        }
                    }
                }
            }

            return list
        },

        goToTag(tag) {
            this.$router.push({name: "tag", params : {ref: tag.ref, channel: tag.channel}})
        },

        chipClass(t) {
            switch (t.channel) {
                case  "country":
                    return "light-blue--text text-subtitle-2"
                case  "crimorg":
                    return "yellow--text text-subtitle-2"
                case  "market":
                    return "lime--text text-subtitle-2"
                case  "mean":
                    return "red--text text-subtitle-2"
                case  "law":
                    return "deep-purple--text text--lighten-3 text-subtitle-2"
                default:
                    return "green--text text-subtitle-2"
            }
        },

        chipColor(t) {
            switch (t.channel) {
                case  "country":
                    return "light-blue"
                case  "crimorg":
                    return "yellow"
                case  "market":
                    return "lime"
                case  "mean":
                    return "red"
                case  "law":
                    return "deep-purple lighten-3"
                default:
                    return "green"
            }
        }
    },

    computed: {

        builClusterTags() {
            let tags = []
            if (this.cluster.posts && this.cluster.posts.length > 0) {
                for (let p of this.cluster.posts) {
                    for (let t of p.tags) {
                        //if (t.channel == "country" || t.channel =="crimorg" || t.channel =="law" || t.channel =="market" || t.channel =="mean") {

                            // Check if the country already exists in the tags array
                            let existingTag = tags.find(c => c.ref === t.ref);
                            if (existingTag) {
                                // If found, increment the count
                                existingTag.count += 1;
                            } else {
                                // If not found, add it to the array with count = 1
                                tags.push({
                                    ref: t.ref,
                                    name: t.name,
                                    count: 1,
                                    channel: t.channel,
                                    description: t.description,
                                });
                            }
                        //}
                    }
                }
            }

            // Sort tags first by channel alphabetically, then by count in descending order
            tags.sort((a, b) => {
                // First compare by channel alphabetically
                if (a.channel < b.channel) return -1;
                if (a.channel > b.channel) return 1;

                // If channels are the same, then compare by count in descending order
                return b.count - a.count;
            });

            
            return tags
        },

        
    },

}
</script>

<style>

</style>