<template>
    <div>
        <SplitView v-if="feed_source && !isNew"  
            key="feeder-tab-splitview" 
            direction="horizontal" 
            a-init="40%"
            :style="{ height: '90vh', overflow: 'hidden' }"
            class="mt-2">
            
            <!-- Sidebar -->
            <template #A >
                <v-card flat outlined key="feed-form">

                    
                        
                    <v-card-text>
                        <!-- feeds url -->
                        <v-text-field
                            outlined
                            dense
                            v-model="feed_source.url"
                            label="Url"
                            :rules="[]"
                            required
                        >
                            <template v-slot:append>
                                <v-icon v-if="feed_source.stream_type == 'html' || feed_source.stream_type == 'chromedp'" @click="goArticle(source.url)" color="accent">mdi-eye</v-icon>
                            </template>
                        </v-text-field>

                        <!-- select streams -->
                        <v-select
                            outlined
                            dense
                            v-model="feed_source.stream_type"
                            :items="streams"
                            label="Stream"
                        ></v-select>
                    
                        <!-- depth -->
                        <v-text-field
                            outlined
                            dense
                            v-model.number="feed_source.depth"
                            label="Depth"
                            :rules="[rules.required]"
                            required
                            hides-details
                        ></v-text-field>

                        <!-- chromedper time out -->
                        <v-text-field  v-if=" source.stream_type == 'chromedp'"
                            outlined
                            dense
                            v-model.number="feed_source.feeder_chromedp_time_out"
                            label="Time out in sec."
                            :rules="[rules.required]"
                            required
                            hides-details
                        ></v-text-field>

                        <!-- chromedper CSS body target -->
                        <v-text-field v-if=" source.stream_type == 'chromedp'"
                            outlined
                            dense
                            v-model="feed_source.feeder_chromedp_target"
                            label="CSS Body target #id .class"
                            hide-actions
                        ></v-text-field>

                        <v-card-text v-if="issueMessage.length > 0" class="warning--text pt-0">{{issueMessage}}</v-card-text>

                        <v-divider></v-divider>
                        
                        <!-- interceptors -->
                        <v-checkbox
                            v-model ="feed_source.has_feed_interceptor"
                            label="interceptor ?"
                            hide-details
                            color="warning"
                        >
                            <template v-slot:label>
                                <div><strong class="warning--text">Feeder Interceptor ?</strong></div>
                            </template>
                        </v-checkbox>
                        
                        <!-- add feed host -->
                        <v-checkbox
                            v-if="feed_source.stream_type == 'html' || source.stream_type == 'chromedp'"
                            v-model="feed_source.add_feed_host"
                            label="host before url ?"
                            hide-details
                        ></v-checkbox>

                        <!-- add skip tls certification -->
                        <v-checkbox
                            v-model="feed_source.colly_insecure_skip_verify"
                            label="TLS: Colly Insecure Skip Verify"
                            hide-details
                        ></v-checkbox>
                        <v-card-subtitle class="py-0">Sometimes some source locks when skipping TLS insecure skip verify ("Not Found"), true by default</v-card-subtitle>
                        
                        <!-- add extra slash between feed.host and feed.url -->
                        <v-checkbox
                            v-if="feed_source.stream_type == 'html' || feed_source.stream_type == 'chromedp'"
                            v-model="feed_source.feed_between_slash"
                            label="extra slash ?"
                            hide-details=""
                        ></v-checkbox>

                        <!-- do not store description (sometimes too long !)-->
                        <v-checkbox
                            v-model="feed_source.feed_no_description"
                            label="no description ? (sometimes too long)"
                        ></v-checkbox>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-text>

                        <!-- HTMLER URL -->
                        <v-text-field
                            v-if="feed_source.stream_type == 'html' || source.stream_type == 'chromedp'"
                            outlined
                            dense
                            v-model="feed_source.feed_url_selectors"
                            label="URL CSS Selectors [sel1,sel2,sel3]"
                            :rules="[noEndingComa,emptySpacesRule,maxTwoCommasRule,noConsecutiveCommasRule]"
                            required
                            hide-details
                        ></v-text-field>

                        <v-card-subtitle  v-if="feed_source.stream_type == 'html' || source.stream_type == 'chromedp'"
                            class="pt-0">Enter only the class inside a tag, collects directly the url</v-card-subtitle>

                        <!-- HTMLER TITLES -->
                        <v-text-field
                            v-if="feed_source.stream_type == 'html' || source.stream_type == 'chromedp'"
                            outlined
                            dense
                            v-model="feed_source.feed_title_selectors"
                            label="TITLE CSS Selectors [sel1,sel2,sel3]"
                            :rules="[noEndingComa,emptySpacesRule,maxTwoCommasRule,noConsecutiveCommasRule]"
                            required
                        ></v-text-field>

                        <!-- HTMLER DESC -->
                        <v-text-field
                            v-if="feed_source.stream_type == 'html' || source.stream_type == 'chromedp'"
                            outlined
                            dense
                            v-model="feed_source.feed_description_selectors"
                            label="DESC CSS Selectors [sel1,sel2,sel3]"
                            :rules="[noEndingComa,emptySpacesRule,maxTwoCommasRule,noConsecutiveCommasRule]"
                            required
                            hide-details=""
                        ></v-text-field>
                    </v-card-text>

                    <!-- SAVE FEED SETUP -->
                    <v-card-actions class="">
                        <v-spacer></v-spacer>
                        <v-btn
                            x-small
                            @click="eventSaveSource"
                            class="py-0 Dialog--text font-weight-bold"
                            color="accent"
                        >save</v-btn
                    ></v-card-actions>

                    <v-divider></v-divider>

                    <!-- FILTERS -->
                    <v-card-title class="my-0">Url String Filter</v-card-title>
                    <v-card-text>
                        <v-textarea
                            outlined
                            dense
                            auto-grow
                            v-model="feed_source.feed_filter_out"
                            label="Filter out in url [/val/,/val/,...]"
                            :rules="[noEndingComa,emptySpacesRule,noCommaAndSpaceRule,noConsecutiveCommasRule]"
                            hint="Note => term1,term2,term3 (no space, no coma at the end)"
                            hide-details
                        ></v-textarea>
                        <v-card-subtitle class="my-0 py-0">We can use class inside a tag to select specific element even for a title.</v-card-subtitle>
                    </v-card-text>

                    <!-- SAVE FEED SETUP -->
                    <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn
                            x-small
                            @click="eventSaveSource"
                            class="mr-5 py-0 Dialog--text font-weight-bold"
                            color="accent"
                        >save</v-btn
                    >
                    </v-card-actions>

                    <!-- FEED/SOURCE HOST-->
                    <v-card-text v-if="issueMessage.length > 0" class="warning--text pt-0">{{issueMessage}}</v-card-text>
                    <v-card-actions v-if="feed_source.stream_type != 'chromedp'" class="py-0">
                        <v-text-field
                            outlined
                            dense
                            v-model.number="feed_source.feed_host"
                            label="Feed Host"
                            hides-details
                            class="mr-3"
                        ></v-text-field>
                        
                        <v-text-field
                            outlined
                            dense
                            v-model.number="feed_source.source_host"
                            label="Source Host"
                            hides-details
                            class="mr-3"
                        ></v-text-field>
                    
                    </v-card-actions>
                    <v-divider></v-divider>

                    <!-- FEED CATEGORIES FILTER OUT -->
                    <v-card-title class="my-0">Categories Filter</v-card-title>
                    <v-card-text class="py-0">
                        <v-textarea
                            outlined
                            dense
                            v-model="feed_source.feed_filter_out_categories"
                            label="Categories out [sel1,sel2,sel3]"
                            :rules="[noEndingComa,noConsecutiveCommasRule,noCommaAndSpaceRule]"
                            hint
                            hide-details=""
                            auto-grow            
                        ></v-textarea>
                    </v-card-text>
                    
                    <!-- SAVE FEED SETUP -->
                    <v-card-actions class="pb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                            x-small
                            @click="eventSaveSource"
                            class="mr-5 Dialog--text font-weight-bold"
                            color="accent"
                        >save</v-btn
                    >
                    </v-card-actions>

                </v-card>
            </template>

            <template #B>
                <FeederTest
                    :source="source"
                    :isNew="isNew"
                    key="feeder-test"
                    @eventFeeds="eventFeeds"
                />
            </template>
            
        </SplitView>
    </div>
</template>

<script>
import SplitView from 'vue-split-view/dist/vue2'
import FeederTest from './FeederTest.vue'


export default {

    name: 'FeederTab',

    props: ['source','isNew'],

    components: {
        SplitView,
        FeederTest,
    },

    watch: {
        source: {
            deep: true,
            immediate: true,
            handler() {
                this.feed_source = this.source;
            }
        }
    },

    data: () => ({
       
       feed_source : null,
       feeds: null,
        
        streams: ["rss", "xml", "html", "chromedp"],

        rules: {
            required: (v) => !!v || "Field required",
        },
    }),

    methods: {

        eventFeeds(feeds) {
            this.feeds = feeds;
            this.$emit("eventFeeds", this.feeds)
        },

        eventSaveSource() {
            this.$emit("eventSaveSource", this.feed_source);
        },

        goArticle(url) {
            window.open(url, '_blank');
        },


        getHostFromUrl(urlString) {
            try {
                // Parse the URL using the URL class
                const urlObj = new URL(urlString);

                // Return the hostname (host without port) from the parsed URL
                return urlObj.hostname;
            } catch (error) {
                console.error("Invalid URL:", error.message);
                return ""; // Return an empty string if the URL is invalid
            }
        },


    },


    computed: {

        noEndingComa() {
            return (value) => {
                // Check if the value ends with a comma
                if (value.endsWith(',')) {
                    return 'Input should not end with a comma';
                }
                return true; // Validation passed
            };
        },

        emptySpacesRule() {
            return (value) => {
                if (/\s/g.test(value)) {
                    return "Empty spaces are not allowed.";
                } else {
                    return true;
                }
             };
        },

        maxTwoCommasRule() {
            return (value) => {
                const commaCount = (value.match(/,/g) || []).length;
                if (commaCount > 2) {
                    return "Only up to two commas are allowed.";
                } else {
                    return true;
                }
            };
        },

        noConsecutiveCommasRule() {
            return (value) => {
                const commaCount = (value.match(/,,/g) || []).length;
                if (commaCount > 0) {
                    return "Consecutive commas are not allowed.";
                } else {
                    return true;
                }
            };
        },

        noCommaAndSpaceRule() {
            return (value) => {
                const pattern = /,\s|,\s|,\s|\s,/;
                if (pattern.test(value)) {
                    return "Comma followed by an empty space or vice versa is not allowed.";
                } else {
                    return true;
                }
            };
        },

        issueMessage() {

            // source host and feed host different
            let feeds_source_host = this.getHostFromUrl(this.feed_source.url)
            let feed_host = ""
            if (this.feeds && this.feeds.length > 0) {
                feed_host = this.getHostFromUrl(this.feeds[0].url)
            }

            if (feed_host != feeds_source_host) {
                return "feeds_source_host=" + feeds_source_host + " - each feed host=" + feed_host
            } else {
                return ""
            }


        },


    }

}
</script>

<style>

</style>