<template>
    <div>
        <v-card flat class="pb-1">
            
            <!-- <Spectrum :vector="cluster.centroid" class="" /> -->

            <!-- loading -->
            <v-progress-linear 
                indeterminate 
                :color="color"
                :active="loading">
            </v-progress-linear>

            <!-- error msg -->
            <span v-if="error" class="ml-5 mt-2 error--text">API Error: {{ error }}</span>

            
            <v-card-actions>
                <v-btn x-small fab class="font-weight-bold Dialog--text text-caption" :color="cluster.is_published? 'blue':color">{{ cluster.post_ids.length }}</v-btn>
                <span class="mx-2 text-caption">{{ cluster.id }}</span>
                <!-- <v-btn @click="copyClusterId" small depressed icon><v-icon small>mdi-content-copy</v-icon></v-btn> -->
                <v-btn small color="lime" outlined>earliest : {{ formatDate(cluster.earliest_post_date) }}</v-btn>

                <v-spacer></v-spacer>
                
                <!-- add deck cluster -->
                
                <!-- cluster key from-->
                <v-text-field
                    dense
                    v-model="deck_cluster_key"
                    outlined
                    @keydown.enter.prevent=""
                    hide-details
                    clearable
                    class="mr-2"
                    color="success"
                    style="max-width: 170px;"
                    label="Deck Cluster Key"
                ></v-text-field>
                <v-btn x-small @click="append" class="font-weight-bold Dialog--text text-caption" color="success">Append</v-btn>
                
                <v-btn small text >{{formatDate(cluster.created_at)}}</v-btn>
                <v-btn v-if="formatDate(cluster.created_at) != formatDate(cluster.updated_at)" small text>last update: {{ formatDate(cluster.updated_at) }}</v-btn>
               
                <v-btn x-small @click="remove" class="font-weight-bold Dialog--text text-caption" color="error">Delete</v-btn>
            </v-card-actions>
            <v-divider></v-divider>
         

            <TagsWidget :tags="cluster.tags" :allow_delete="false" class="ml-3 py-1"/>
            <LootWidget v-if="cluster.loot && cluster.loot.length > 0" :loot="cluster.loot" :allow_delete="false" class="ml-1 py-1" />


            <v-expansion-panels flat tile>
                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold teal--text text--accent-3">
                        {{cluster.title}}
                        <template v-slot:actions>
                            <v-icon :color="color">
                                mdi-chevron-down-circle
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                       <v-card flat outlined>
                            <v-card-text v-for="(excerpt, index) in cluster.excerpts" :key="'excerpt-' + index" class="grey--text text--lighten-3">
                                {{index}} - {{excerpt}}
                            </v-card-text>
                       </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <!-- Images -->
            <v-card flat class="d-flex overflow-y-auto" key="images-grid" >
                <v-card flat tile v-for="(iu,index) in cluster.img_urls" :key="'img-' + index" :ripple="false" height="110" width="170">
                    <v-img
                        :src="iu"
                        class="ml-2"
                        min-width="155"
                        height="110"
                        contain
                        
                        @click="goImage(iu)"
                        style="cursor: pointer;"
                    ></v-img>
                
                </v-card>
            </v-card>
        </v-card>


        <v-dialog v-model="dialog" :close-on-content-click="false" max-width="70%">
            <v-sheet roudned outlined :color="color">
                <v-card flat>
                  
                    <!-- cluster saving -->
                    <v-progress-linear indeterminate color="teal accent-3" :active="append_loading"></v-progress-linear>
                    
                    <v-card-text class="py-1">ES cluster</v-card-text>
                    <v-btn text class="wordbreak" :color="color">{{cluster.title}}</v-btn>
                    <v-divider></v-divider>
                    
                    <v-card-text class="py-1">Deck Cluster</v-card-text>
                    <v-btn text class="wordbreak" color="success">{{buildDeckClusterTitle}}</v-btn>
                    <v-card-actions>
                        <v-btn small class="font-weight-bold Dialog--text" :color="color" @click="appender(false)">Append & Forget</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn small class="font-weight-bold Dialog--text" color="success" @click="appender(true)">Append & Keep In Deck</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn small color="purple">Cosine Distance: {{ buildCosineDistance }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :color="color" text @click="dialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-sheet>
        </v-dialog>

        <v-snackbar centered v-model="ok_snackbar" timeout="1000" color="success">
            <span class="font-weight-bold Dialog--text">OK</span>
        </v-snackbar>

        <v-snackbar centered v-model="append_snackbar" timeout="-1" :color="color">
            {{ append_error }}
            <v-btn text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from 'vuex';
import TagsWidget from '../../Tags/TagsWidget.vue'
import LootWidget from '../../Loot/LootWidget.vue'
import vector_operations from '../../../common/cluster/vector_operations';
import deck_to_cluster from "@/common/cluster/deck_to_cluster";

export default {

    name: 'Cluster',

    props: ['ghost_clusters', 'ghost_parameters',  'cluster', 'index', 'color'],

    components: {
        TagsWidget,
        LootWidget,
    },

    data: () => ({

        loading: false,
        error: null,

        append_loading: false,
        append_error: null,
        append_snackbar: false,
        ok_snackbar: false,

        // add
        deck_cluster_key: "",
        deck_cluster: null,

        dialog: false,

    }),

    methods: {

        append() {
            console.log('append deck cluster key', this.deck_cluster_key);

            if (this.deck_cluster_key == "") {
                alert("Deck Cluster Key is empty");
                return
            }

            if (this.deck_cluster_key.length != 8) {
                alert("Deck Cluster Key must be 8 characters long");
                return
            }

            
            this.deck_cluster = this.ghost_clusters.find(cluster => cluster.key === this.deck_cluster_key);
           
            this.dialog = true;
        },


        appender: async function(keep) {
            console.log('appender: keep=', keep);

            this.append_loading = true;
            this.append_error = null;

            // since we adding posts a an existing cluster...
            // sometimes it is about the same story, with the same loot
            // the deck cluster is sometimes a bit later so we add more details
            // so we take the max 2 excerpts from the deck cluster

            // enrich with cluster header
            let pids= [];
            // let vectors = [];
            let excerpts = [];
            for (let [index, p] of this.deck_cluster.posts.entries()) {
                pids.push(p.id);
                if (index < 3) {
                    excerpts.push(deck_to_cluster.buildPostExcerpt(p, this.ghost_parameters.gpt_excerpt_tokens));
                }
            }

            let payload = {
                cluster_id: this.cluster.id,
                post_ids: pids,
                img_urls: deck_to_cluster.buildClusterImgUrls(this.deck_cluster),
                tags: deck_to_cluster.buildClusterTags(this.deck_cluster),
                loot: deck_to_cluster.buildClusterLoot(this.deck_cluster),
                excerpts: excerpts,
            }

            console.log("payload", payload);


            // no need of the centroid
            try {
                let res = await this.$api.post("/ghost/recaller/append", payload);
                this.message = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.append_error = data;
                this.append_snackbar = true;
            } finally {
                this.append_loading = false;
            }

            // all good
            if (!this.append_error) {

                // ok snackbar
                this.ok_snackbar = true;

                if (!keep) {
                    // remove header cluster from deck
                    this.$emit("eventRemoveClusterFromDeck",this.deck_cluster.key);
                }
            }


        },

        copyClusterId() {
            navigator.clipboard.writeText(this.cluster.id);
        },

        
        goImage(url) {
            window.open(url, '_blank');
        },


        formatDate(date) {
            return moment(date).format("DD/MM/YY HH:mm")
        },

        //* CRUD
        remove: async function() {
            console.log('delete')

            if (!confirm('Remove cluster "' + this.cluster.id + '"?')) {
                return;
            }

            this.loading = true;
            this.error = null;

            try {
                await this.$api.delete("/clusters/" + this.cluster.id);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
                this.$emit("eventRemoveCluster", this.index); 
            }

        },

       
    },

    computed: {

        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),

        buildCosineDistance() {
            if (this.cluster && this.deck_cluster) {
                let cosine = vector_operations.cosineDistance(this.cluster.centroid,this.deck_cluster.centroid );
                return  Math.round(1000 * cosine) / 1000;
            }
            return 0;
        },


        buildDeckClusterTitle() {
            let title = ""
            if (this.deck_cluster && this.deck_cluster.posts.length > 0) {
                
                title = this.deck_cluster.posts[0].title;

                if (this.trans_settings && this.trans_settings[this.deck_cluster.posts[0].language] && this.trans_settings[this.deck_cluster.posts[0].language].available.length > 0) {
                    for (let ol of this.trans_settings[this.deck_cluster.posts[0].language].available) {
                        if (ol.trans_active) {
                            if (this.deck_cluster.posts.length > 0 && this.deck_cluster.posts[0].translations) {
                                for (let trans of this.deck_cluster.posts[0].translations) {
                                    if (trans.output_language == ol.iso) {
                                        if (trans.title.length > 0) {
                                            title = "[" + trans.title.trim() + "]";
                                        }
                                    }
                                }
                            }
                        } 
                    }
                    
                }
            }
            return title
        },

    }


}
</script>

<style>

.wordbreak {
    word-break: normal;
}

</style>