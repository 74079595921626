<template>
    <div>
        <v-card flat outlined key="widget-scheduler">

            <!-- error msg -->
            <span v-if="error && error != 'empty'" class="ml-5 red--text">API Error: {{ error }}</span>

            <!-- SELECT BUTTONS -->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn-toggle v-model="toggle" dense rounded group color="lime">
                    <v-btn :loading="loading" value="publish">PUBLISH</v-btn>
                    <v-btn :loading="loading" value="schedule">SCHEDULE</v-btn>
                    <v-btn :loading="loading" value="draft">DRAFT</v-btn>
                    <v-btn :loading="loading" value="test">TEST</v-btn>
                </v-btn-toggle>
                <v-spacer></v-spacer>
            </v-card-actions>

            <v-divider></v-divider>

            <!-- PUBLISH -->
            <v-card-actions v-if="toggle == 'publish'">
                <v-spacer></v-spacer>
                <v-btn small :loading="loading" :disabled="loading" @click="action('publish')" color="lime" class="font-weight-bold Dialog--text mr-2">
                    Publish
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>

            <!-- DRAFT -->
            <v-card-actions v-if="toggle == 'draft'">
                <v-spacer></v-spacer>
                <v-btn small :loading="loading" :disabled="loading" @click="action('draft')" color="lime" class="font-weight-bold Dialog--text mr-2">
                    Draft
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>


            <!-- TEST: print payload in console -->
            <v-card-actions v-if="toggle == 'test'">
                <v-spacer></v-spacer>
                <v-btn small :loading="loading" :disabled="loading" @click="action('test')" color="warning" class="font-weight-bold Dialog--text mr-2">
                    Test
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>


            <!-- SCHEDULE -->
            <v-card-actions v-if="toggle == 'schedule'">

                <!-- DATE PICKER-->
                <v-menu v-if="date" v-model="date_menu" :close-on-content-click="false"
                    transition="scale-transition" offset-y :nudge-right="40" min-width="auto" class="lime--text">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="Date" readonly v-bind="attrs" v-on="on" :color='warningInPast ? "error" : "lime"'>
                            <template v-slot:prepend-inner>
                                <v-icon :color='warningInPast ? "error" : "lime"'>mdi-calendar</v-icon>
                            </template>
                        </v-text-field>
                    </template>
                    <v-date-picker v-model="date" 
                        @input="date_menu = false" 
                        width="270"
                        color="lime"
                    ></v-date-picker>
                </v-menu>


                <v-spacer></v-spacer>

                <!-- TIME PICKER -->
                <v-menu 
                    v-if="time" 
                    v-model="time_menu" 
                    :close-on-content-click="false" 
                    :nudge-right="40"
                    transition="scale-transition" offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="time" :label="'Time'" readonly v-bind="attrs" v-on="on" :color='warningInPast ? "error" : "lime"' class="lime--text">
                            <template v-slot:prepend-inner>
                                <v-icon :color='warningInPast ? "error" : "lime"'>mdi-clock-time-four-outline</v-icon>
                            </template>
                        </v-text-field>
                    </template>
                    <v-time-picker v-if="time_menu" 
                        v-model="time" 
                        format="24hr" 
                        color="lime darken-2"
                    ></v-time-picker>
                </v-menu>
            </v-card-actions>

            <v-card-actions v-if="toggle == 'schedule' && !warningInPast">
                <v-spacer></v-spacer>
                <v-btn small :loading="loading" :disabled="loading" @click="action('schedule')" color="lime" class="Dialog--text mr-2">
                    Schedule
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>

        </v-card>

        <!-- snack bar: publication saved -->
        <v-snackbar centered v-model="snackbar" timeout="800" color="success">
            <span class="font-weight-bold Dialog--text">OK</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    small
                    class="Dialog--text"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    {{ $i18n.t("close") }}
                </v-btn>
            </template>
        </v-snackbar>
        
    </div>
</template>

<script>
import moment from "moment";

export default {

    name: 'PublicationSchedulerWidget',

    props: ['cluster_post_ids', 'thread', 'selected_images', 'targets', 'publication','imgs_in_thread'],

    data: () => ({
        loading: false,
        error: null, 
        date_menu: false,
        date: null,
        time_menu: false,
        time: null,
        toggle: 'schedule',
        zoneUtc: 2,
        warningInPast: false,
        snackbar: false,
    }),

    created() {
        if (this.publication && (this.publication.status == "scheduled" || this.publication.status == "error")) {
            this.date = moment(this.publication.scheduled_at).format("YYYY-MM-DD");
            this.time = moment(this.publication.scheduled_at).format("HH:mm");
        } else if(this.publication && this.publication.status == "published") {
            this.date = moment(this.publication.published_at).format("YYYY-MM-DD");
            this.time = moment(this.publication.published_at).format("HH:mm");
        } else {
            this.date = moment().format("YYYY-MM-DD");
            this.time = moment().format("HH:mm");
        }
    },

    watch: {
        
        time: {
            deep: true,
            immediate: true,
            handler() {
                if (this.date && this.time) {
                    let t = this.date + " " + this.time;
                    // check in past
                    this.warningInPast = moment(t).isBefore()
                }
            }
        },


        date: {
            deep: true,
            immediate: true,
            handler() {
                if (this.date && this.time) {
                    let t = this.date + " " + this.time;
                    // check in past
                    this.warningInPast = moment(t).isBefore()
                }
            }
        },

        publication: {
            deep: true,
            immediate: true,
            handler() {
                if (this.publication && (this.publication.status == "scheduled" || this.publication.status == "error")) {
                    this.date = moment(this.publication.scheduled_at).format("YYYY-MM-DD");
                    this.time = moment(this.publication.scheduled_at).format("HH:mm");
                } else if(this.publication && this.publication.status == "published") {
                    this.date = moment(this.publication.published_at).format("YYYY-MM-DD");
                    this.time = moment(this.publication.published_at).format("HH:mm");
                } 
            }
        },

    },

    methods: {

        action: async function (action) {
            console.log(action)

            this.loading = true;
            this.error = null;
            
            // ,alerts
            if (this.targets.length == 0) {
                window.alert("No targets");
                this.loading =false;
                return
            }

            if (this.thread.length== 0 || this.thread[0].length == 0) {
                window.alert("No text");
                this.loading =false;
                return
            }

            let payload = {
                action: action,
                text: this.thread.join("\n\n"),
                // source_url: this.post.url,
                // source_name: this.post.source_name,
                post_ids: this.cluster_post_ids.join(","),
                targets: this.targets.join(","),
                time: this.date + "T" + this.time,
               
                imgs_in_thread: this.imgs_in_thread,
            }

            if (this.selected_images.length > 0) {
                let img_urls =[];
                for (let img of this.selected_images) {
                    img_urls.push(img.url)
                }
                payload.img_urls = img_urls.join('#,#')
            }
            
            if (action == "test") {
                console.log(payload)
            } else {
                try {
                    await this.$api.post("/publisher/action", payload);
                } catch (e) {
                    let data = (e.response || {}).data || "unknown error";
                    this.error = data.message;
                } finally {
                    this.loading = false;
                    this.snackbar = true;
                }
            }


           this.loading = false;
        },
    },
}
</script>

<style></style>