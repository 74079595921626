<template>
    <div>
        <v-card flat outlined  key="deck-parameters" color="">
            <v-card-actions class="my-0 py-1">
        
                <v-spacer></v-spacer>
                <!-- day offset -->
                <v-text-field
                    outlined dense
                    v-model.number="parameters.day_offset"
                    label="Day Offset"
                    hide-details=""
                    color="success"
                    @change="eventUpdateParameters"
                    class="mx-2"
                    style="max-width: 90px;"
                ></v-text-field>

                <!-- daily hour-->
                <v-text-field
                    outlined dense
                    v-model.number="parameters.daily_batch_hour"
                    label="Start Hour"
                    hide-details=""
                    color="success"
                    class="mx-2"
                    style="max-width: 80px;"
                ></v-text-field>
                <v-spacer></v-spacer>

                <!-- Continents -->
                <v-autocomplete
                    dense
                    outlined
                    label="continents"
                    :items="continents"
                    v-model="parameters.continent"
                    hide-details
                    @change="eventUpdateParameters"
                    color="success"
                    class="mx-2"
                    style="max-width: 80px;"
                >
                    <template v-slot:selection="data">
                        {{ data.item }}
                    </template>
                
                </v-autocomplete>
               
                <v-spacer></v-spacer>
                <!-- Skimmer -->
                <v-text-field
                    outlined dense
                    v-model.number="parameters.skimmer_centroid_distance"
                    label="Skim centroid dist."
                    hide-details=""
                    color="warning"
                    class="mx-2"
                    style="max-width: 125px;"
                ></v-text-field>

                <!--MERGER -->
                <v-text-field
                    outlined dense
                    v-model.number="parameters.merger_epsilon"
                    label="Merger Ɛ"
                    hide-details=""
                    color="blue lighten-2"
                    class="mx-2"
                    style="max-width: 75px;"
                ></v-text-field>

                <v-spacer></v-spacer>

                <v-checkbox dense label="GPT" value="gpt" v-model="parameters.ghost_clusterer" color="blue-grey darken-2" ></v-checkbox>
                <v-checkbox dense label="W2V" value="w2v" v-model="parameters.ghost_clusterer" color="blue-grey darken-2" class="mx-2"></v-checkbox>
                
                <v-text-field v-if="parameters.ghost_clusterer=='w2v'"
                    outlined dense
                    v-model.number="parameters.gpt_excerpt_tokens"
                    label="Excerpt"
                    hide-details=""
                    color="success"
                    class="mx-2"
                    @change="eventUpdateParameters"
                    style="max-width: 70px;"
                ></v-text-field>
                
                <v-text-field v-if="parameters.ghost_clusterer=='w2v'"
                    outlined dense
                    v-model.number="parameters.doc_epsilon"
                    label="W2V Ɛ"
                    hide-details=""
                    color="blue"
                    class="mx-2"
                    @change="eventUpdateParameters"
                    style="max-width: 70px;"
                ></v-text-field>
                
                <!-- max spread angle cluster-->
                <v-btn v-if="parameters.ghost_clusterer=='w2v'" small color="purple">{{ cosineToAngle(parameters.doc_epsilon) }}°</v-btn>
                
                <!-- W2V dimensions model -->
                <v-text-field  v-if="parameters.ghost_clusterer=='w2v'"
                    outlined dense
                    v-model.number="parameters.w2c_dimensions"
                    label="W2V Dim"
                    hide-details=""
                    color="blue"
                    class="mx-2"
                    @change="eventUpdateParameters"
                    style="max-width: 70px;"
                ></v-text-field>
                <v-spacer></v-spacer>
               
            </v-card-actions>
            <v-divider></v-divider>
            <!-- OPEN AI + COMMANDS-->
            <v-card-actions  class="py-1">
                <v-spacer></v-spacer>
                <v-btn small>Model: {{ response.model }}</v-btn>
                <v-btn small>Finish Reason: {{ response.finish_reason }}</v-btn>
                <v-btn small>Prompt: {{ response.usage_prompt_tokens }}</v-btn>
                <v-btn small>Completion: {{ response.usage_completion_tokens }}</v-btn>
                <v-btn small color="blue-grey darken-2">{{ pricing }} $</v-btn>
                <v-spacer></v-spacer>
                <v-btn small @click="eventFetchNewDeck" color="blue-grey darken-2"  class="font-weight-bold" :loading="new_loading">NEW</v-btn>
                <v-btn small @click="eventFetchLastPosts" color="blue" class="font-weight-bold Dialog--text ml-2" :loading="last_loading">LAST</v-btn>
                <v-spacer></v-spacer>
                <v-btn rounded small depressed class="ml-2">{{delay}} s</v-btn>
                <v-btn rounded small depressed class="ml-2">max query size: {{max_posts_count}} posts</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
            
            <v-progress-linear indeterminate color="blue-grey darken-2" :active="new_loading"></v-progress-linear>
        </v-card>
 
        <!-- loading -->
    </div>
</template>

<script>
import ghost from "@/common/ghost";

export default {

    name: 'GhostParameters',

    props:['clusters','delay','max_posts_count', 'new_loading','last_loading', 'gpt_response'],

    data: () => ({
       
        parameters: null,

        response: {
            model: "",
            finish_reason: "",
            usage_prompt_tokens: 0, 
            usage_completion_tokens: 0,
        },

        continents: [
            "all",
            "down-under",
            "asia",
            "middle-east",
            "soviet",
            "turkiye",
            "europe",
            "africa",
            "north-america",
            "mexico",
            "caribbean",
            "south-america",
            "oceania",
            "antartica",
        ],
    }),

    created() {
        this.parameters = ghost.retriveParameters();
    },

    watch: {
        gpt_response: {
            deep: true,
            immediate: true,
            handler() {
                if (this.gpt_response) {
                    this.response = this.gpt_response;
                } else {
                    this.response = {
                        model: "",
                        finish_reason: "",
                        usage_prompt_tokens: 0, 
                        usage_completion_tokens: 0,
                    }
                }
            }
        }
    },

    methods: {

        eventSeeOut() {
            this.parameters.see_out = true;
            this.$emit("eventUpdateParameters", this.parameters)
        },

        eventSeeDeck() {
            this.parameters.see_out = false;
            this.$emit("eventUpdateParameters", this.parameters)
        },


        eventUpdateParameters(){
            ghost.setParameters(this.parameters);
            this.$emit("eventUpdateParameters", this.parameters)
        },

        eventFetchNewDeck() {
            this.$emit("eventFetchNewDeck");
        },

        eventFetchLastPosts() {
            this.$emit("eventFetchLastPosts");
        },

        clear() {
            this.parameters.continent = "all";
            ghost.setParameters(this.parameters);
            this.$emit("eventUpdateParameters", this.parameters)
        },

        cosineToAngle(cs) {
            return Math.round(10 * Math.acos(1 - cs) * (180/Math.PI)) / 10
        }
    },

    computed: {

        pricing(){
           
            let completion_rate = 0;
            let prompt_rate = 0;
            if (this.parameters.openai_model=="gpt-4o-mini") {
                prompt_rate = 0.000000150;
                completion_rate = 0.000000600;
            } else {
                prompt_rate = 0.00000250;
                completion_rate = 0.000010;
            }
            return Math.round( 100000 * this.response.usage_completion_tokens * completion_rate + this.response.usage_prompt_tokens * prompt_rate) / 100000;
        },  
    }   
}
</script>

<style>

</style>