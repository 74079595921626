<template>
  <div>

    <Parameters 
        :clusters="clusters" 
        :delay="delay" 
        :max_posts_count="max_posts_count"
        :new_loading="new_loading" 
        :last_loading="last_loading"
        :gpt_response="gpt_response"
        @eventUpdateParameters="eventUpdateParameters"
        @eventFetchNewDeck="fetchNewDeck"
        @eventFetchLastPosts="fetchLastPosts"
    />

    <!-- error -->
    <v-card v-if="error && error != 'empty'" flat color="transparent" key="ghost-error-message">
        <!-- error msg -->
        <v-card-text class="ml-5 mt-2 error--text">API Error: {{ error }}</v-card-text>
    </v-card>

    <!-- empty decks message -->
    <v-card flat key="ghost-empty-message">
        <v-card-text v-if="error == 'empty'" class="text-center">Deck empty. Check Standby</v-card-text>
    </v-card>

    <!-- don't forget to change tab number in wathcer tab -->
    <v-tabs v-model="tab" centered background-color="transparent" key="deck-clusters-main-tabs">
        <v-tabs-slider :color="tabSliderColor"></v-tabs-slider>
        <v-tab @click="selectTab(0)" class="font-weight-bold orange--text text--accent-2">SKIMMER</v-tab>
        <v-tab @click="selectTab(1)" class="font-weight-bold blue--text text--lighten-2">MERGER</v-tab>
        <v-tab @click="selectTab(2)" class="font-weight-bold pink--text text--lighten-2">RECALLER</v-tab>
        <v-tab @click="selectTab(3)" class="font-weight-bold green--text">DECK ({{ clusters.length }})</v-tab>
        <v-tab @click="selectTab(4)" class="font-weight-bold teal--text text--accent-3">CLUSTERS</v-tab>
    </v-tabs>
    
    <v-tabs-items v-model="tab" :active-class="backgroundThemeSwitcher" class="tab-items">
        
        <v-tab-item key="skimmer" class="">
            <Skimmer 
                :ghost_clusters="clusters"
                :tags_list="tags_list" 
                :parameters="parameters"
                @eventShowCluster="eventShowCluster"
                @eventPostInNewCluster="eventPostInNewCluster"
                @eventVote="eventVote"
            />
        </v-tab-item>
        <v-tab-item key="merger" class="">
            <Merger 
                :ghost_clusters="clusters"
                :parameters="parameters"
                @eventShowMergedCluster="eventShowMergedCluster"
                @eventMergeClusters="eventMergeClusters"
                @eventPostInNewCluster="eventPostInNewCluster"
            />
        </v-tab-item>
        <v-tab-item key="recaller" class="">
            <Recaller 
                :ghost_clusters="clusters"
                :ghost_parameters="parameters"
                :tags_list="tags_list" 
                @eventRemoveClusterFromDeck="eventRemoveClusterFromDeck"
                @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
            />
        </v-tab-item>
        <v-tab-item key="deck"  class="">
            <Deck 
                :ghost_clusters="clusters"
                :parameters="parameters"
                :tags_list="tags_list" 
                :calendar="calendar"
                :tab="tab"
                @eventShowCluster="eventShowCluster"
                @eventUpdateClusters="updateClusters"
                @eventSortClusters="sortClusters"
                @eventFetchUpdatedPosts="fetchUpdatedPosts"
                @eventPostInNewCluster="eventPostInNewCluster"
                @eventVote="eventVote"
                @eventMergeClusters="eventMergeClusters"
                @eventUpdateCluster="eventUpdateCluster"
                @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                @eventSwitchToClusters="eventSwitchToClusters"
            />
        </v-tab-item>
        <v-tab-item key="clusters" class="">
            <Clusters 
                :ghost_clusters="clusters"
                :ghost_parameters="parameters"
                :tags_list="tags_list"
                 :tab="tab"
                @eventSwitchToDeck="eventSwitchToDeck"
                @eventRemoveClusterFromDeck="eventRemoveClusterFromDeck"
            />
        </v-tab-item>
    </v-tabs-items>
    

  </div>
</template>

<script>
import ghost from "@/common/ghost";
import cluster_vector_operations from "@/common/cluster/vector_operations";

import Parameters from './Parameters/GhostParameters.vue'
import Skimmer from './Skimmer/SkimmerTab.vue'
import Merger from './Merger/MergerTab.vue'
import Recaller from './Recaller/RecallerTab.vue'
import Deck from './Deck/DeckTab.vue'
import Clusters from './Clusters/ClustersTab.vue'

export default {

    name: 'GhostMain',

    components: {
        Parameters,
        Skimmer,
        Merger,
        Recaller,
        Deck,
        Clusters,
    },

    data: () => ({
        tab: null,
        tabColors: ["orange accent-2", "blue lighten-2", "pink lighten-2", "green", "teal accent-3"],

        loading: false,
        error: null,

        // Parameters
        delay: 0,
        max_posts_count: 0,
        parameters: null,
        new_loading: false,
        last_loading: false,

        //* Deck
        clusters: [],
        post_ids: [],
        gpt_response: null,

        //* Persistence
        storage_clusters: null,
        storage_gpt_response: null,

        //* widgets
        //- tags widget
        tags_list: [],

        // Calendar
        calendar:  {
            post_id: "",
            post_ids: [], 
        },

      
    }),

    created() {

        // init
        this.tab = ghost.retriveTab();
        this.storage_clusters = null,
        this.parameters = ghost.retriveParameters();

       
        // localStorage
        this.storage_clusters = JSON.parse(localStorage.getItem('clusters'));
        this.gpt_response = JSON.parse(localStorage.getItem('gpt_response'));

        if (!this.storage_clusters || this.storage_clusters.length == 0) {
            console.log("not deck in storage, fetching deck via api")
            // this.fetchNewDeck()
        } else {
            setTimeout(
                () => this.patchClusters()
                    .then(this.fetchUpdatedPosts()),
                1000
            )
        }

        // widgets Tags needs the full list to add tags
        this.fetchTags()


        //* Calendar
        // router navigation back from calendar
        if (this.$route.params.post_id) {
            console.log("params found pid", this.$route.params.post_id);
            this.calendar.post_id = this.$route.params.post_id;
            this.tab = 3  // deck tab
            ghost.setTab(this.tab)
        } else if (this.$route.params.post_ids) {
            console.log("params found pids", this.$route.params.post_ids);
            this.calendar.post_ids = this.$route.params.post_ids;
            this.tab = 3 // deck tab
            ghost.setTab(this.tab)
        }
        
    },


    watch: {

        tab: {
            immediate: true,
            handler() {
                switch (this.tab) {
                    case 0:
                        this.scrolling = ghost.retriveSkimmerScroll();
                        break;
                    case 1:
                        this.scrolling = ghost.retriveMergerScroll();
                        break;
                    case 2:
                        this.scrolling = ghost.retriveRecallerScroll();
                        break;
                    case 3:
                        this.scrolling = ghost.retriveDeckScroll();
                        break;
                    case 4: 
                        this.scrolling = ghost.retriveClustersScroll();
                        break;
                    default:
                        break;
                }

            }
        },
    },

    methods: {
        
        // tabs
        selectTab(value) {
            ghost.setTab(value)
        },

        //* EVENTS
        
        //* Parameters
        eventUpdateParameters(params) {
            console.log("eventUpdateParameters", params.continent)
            this.parameters = params;
        },

        eventShowCluster(cl_index) {
            console.log("ghost eventShowCluster", cl_index);
            this.clusters[cl_index].show = !this.clusters[cl_index].show;
            this.updateClusters();
        },

        eventUpdateCluster(cl_index, cluster) {
            console.log("ghost eventUpdateCluster", cl_index);
            this.clusters[cl_index] = cluster;
            this.updateClusters();
        },

        eventShowMergedCluster(cl_index) {
            console.log("ghost eventShowMergedCluster", cl_index);
            this.clusters[cl_index].show = !this.clusters[cl_index].show;
            this.updateClusters();
        },


        // new cluster form one single post
        eventPostInNewCluster(deck_cl_index, p_index, post) {
            console.log("ghost eventPostInNewCluster", deck_cl_index, p_index);

            // cvheck if the post is not already in its own cluster:
            for (let cl of this.clusters) {
                if (cl.posts.length == 1 && cl.posts[0].title == post.title) {
                    console.log("post already in its own cluster")
                    return
                }
            }
           
            if (this.clusters[deck_cl_index].posts.length > 1) {
                // create a cluster following the exited one
                let new_cluster = {
                    // new temporary key with 3 random digits
                    key: "new-" + Math.floor(Math.random() * 900 + 100),
                    posts: [post],
                    is_saved: false,
                    show: true,
                    is_out: this.parameters.see_out,
                    continent_name: this.clusters[deck_cl_index].continent_name,
                    continent_order: this.clusters[deck_cl_index].continent_order,
                    // single post cluster centroid
                    centroid: post.vector,
                }

                // insert new cluster in the deck
                this.clusters.splice(deck_cl_index + 1, 0, new_cluster);
                
                // remove post from the old cluster
                this.clusters[deck_cl_index].posts.splice(p_index, 1);
                
                // THEN update centroid in original cluster
                this.clusters[deck_cl_index].centroid = cluster_vector_operations.buildClusterCentroid(this.clusters[deck_cl_index]);

                this.updateClusters();
            }
        },

        // merge clusters
        eventMergeClusters(from_cl_index, to_cl_index) {
            console.log("Ghost- eventMergeClusters", from_cl_index, to_cl_index);
            for (let p of this.clusters[from_cl_index].posts) {
                this.clusters[to_cl_index].posts.push(p)
            }
            this.clusters.splice(from_cl_index, 1);
            
            // update centroid in target cluster
            this.clusters[to_cl_index].centroid = cluster_vector_operations.buildClusterCentroid(this.clusters[to_cl_index]);

            this.updateClusters();
        },

        eventVote(cl_index, p_index) {
            console.log("eventVote", cl_index, p_index)
            if (this.clusters[cl_index].posts.length == 1) {
                this.clusters.splice(cl_index, 1)
            } else {
                this.clusters[cl_index].posts.splice(p_index, 1);
            }

            this.updateClusters();
        },

        eventRemoveClusterFromDeck(cl_key) {
            console.log("ghost - eventRecallRemoveClusterFromDeck", cl_key)
            let cl_index = this.clusters.findIndex(c => c.key == cl_key);
            this.clusters.splice(cl_index, 1);
            this.updateClusters();
        },



        eventRemoveTagFromCluster(cl_index, ref) {
            console.log("ghost - eventRemoveTagFromCluster", cl_index, ref);
          
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                let tags = [];
                for (let t of p.tags) {
                    if (t.ref != ref) {
                        tags.push(t)
                    }
                }
                p.tags = tags
                posts.push(p)
            }
            this.clusters[cl_index].posts = posts
    
            this.updateClusters();
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            console.log("ghost - eventRemoveLootFromCluster", cl_index, ref);
            let posts = [];
            for (let p of this.clusters[cl_index].posts) {
                let loot = [];
                for (let l of p.loot) {
                    if (l.tag_ref != ref) {
                        loot.push(l)
                    }
                }
                p.loot = loot
                posts.push(p)
            }
            this.clusters[cl_index].posts = posts
            this.updateClusters();
        },

        eventSwitchToClusters() {
            this.tab = 4;
        },

        eventSwitchToDeck() {
            this.tab = 3;
        },

        //* API
        fetchNewDeck() {
            if (this.parameters.ghost_clusterer == "gpt") {
                this.fetchNewDeckGPT();
                // skimmer tab
                this.tab = 0;
                ghost.setTab(this.tab)
            } else {
               this.fetchNewDeckW2V();
            }
        },

        // Main deck fetch
        fetchNewDeckGPT: async function () {
            console.log("fetch new deck gpt");

            this.new_loading = true;
            this.error = null;
            this.delay = 0;

            this.clusters = [];
            this.skimmer_clusters = [];
            this.post_ids = [];

            let payload = {
                hour: this.parameters.daily_batch_hour,
                day_offset: this.parameters.day_offset,
                w2c_dimensions: this.parameters.w2c_dimensions,
                w2c_model: this.parameters.w2c_model,
                gpt_excerpt_tokens: this.parameters.gpt_excerpt_tokens,
            }

            // reset deck storage
            localStorage.removeItem('clusters');
            localStorage.removeItem('gpt_response');
            this.gpt_response = null;

            try {
                let res = await this.$api.post("/ghost/fetch_gpt", payload);
                for (let c of res.data.clusters) {
                   
                    let posts = []
                    for (let dp of c.deck_posts) {
                        let p = dp.post
                        p.is_out = false;
                        p.is_community = dp.is_community;
                        p.vector = dp.vector;
                        p.doc_length = dp.doc_length;
                        posts.push(p);
                    }
                    c.posts = posts
                    c.is_out = false;
                    c.show = false;
                    c.title = this.buildClusterTitle(c);
                    c.is_saved = false;
                    // remove deck posts
                    delete c.deck_posts;
                    this.clusters.push(c);
                }
               
                // delay
                this.delay = Math.round(10 * res.data.delay) / 10;
                // Max posts count for query
                this.max_posts_count = res.data.max_posts_count;
                // gpt response
                this.gpt_response = res.data.gpt_response;

            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.updateClusters();
                this.sortClusters();
                localStorage.setItem('gpt_response', JSON.stringify(this.gpt_response));
                this.new_loading = false;
            }

        },

        // Backup deck fetch
        fetchNewDeckW2V: async function () {
            console.log("fetch new deck w2c");
            this.new_loading = true;
            this.error = null;
            this.delay = 0;

            this.clusters = [],
            this.post_ids = [];
            this.skimmer_clusters = [];

            let payload = {
                hour: this.parameters.daily_batch_hour,
                doc_epsilon: this.parameters.doc_epsilon,
                min_points: this.parameters.min_points,
                day_offset: this.parameters.day_offset,
                w2c_dimensions: this.parameters.w2c_dimensions,
                w2c_model: this.parameters.w2c_model,
            }


            // reset deck storage
            localStorage.removeItem('clusters');

            try {
                let res = await this.$api.post("/profile/deck/fetch_w2c", payload);
                for (let c of res.data.clusters) {
                    let posts = []
                    for (let dp of c.deck_posts) {
                        let p = dp.post
                        p.is_out = false;
                        p.is_community = dp.is_community;
                        p.is_w2c_clustered = dp.is_w2c_clustered;
                        p.is_loot_clustered = dp.is_loot_clustered;
                        p.vector = dp.vector;
                        p.doc_length = dp.doc_length;
                        posts.push(p);
                    }
                    c.posts = posts
                    c.is_out = false;
                    c.show = false;
                    c.title = this.buildClusterTitle(c);
                    // remove deck posts
                    delete c.deck_posts;
                    this.clusters.push(c);
                }
                // delay
                this.delay = Math.round(1000 * res.data.delay);
                // Max posts count for query
                this.max_posts_count = res.data.max_posts_count

            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.updateClusters();
                this.sortClusters();
                this.new_loading = false;
            }
        },

        // used when:
        // - closing publisher
        // - reloading clusters from localStorage, we want to update to the latest state
        fetchUpdatedPosts: async function () {

            console.log("fetch updated posts")

            // need to rebuild post ids
            this.buildPostIds();

            // reset deck storage
            localStorage.removeItem('clusters');

            this.loading = true;
            this.error = null;

            let payload = {
                ids: this.post_ids,
            }

            try {
                let res = await this.$api.post("/profile/deck/updated", payload);
                let updated_posts_map = res.data
                let updated_clusters = []
                for (let c of this.clusters) {

                    // Create a deep copy of `c` to avoid modifying the original cluster
                    let updated_cluster = JSON.parse(JSON.stringify(c));
                    // reset posts
                    updated_cluster.posts = [];

                    for (let p of c.posts) {
                        let up = updated_posts_map[p.id].post;
                        up.is_out = p.is_out;
                        up.is_community = p.is_community;
                        up.is_w2c_clustered = p.is_w2c_clustered;
                        up.is_loot_clustered = p.is_loot_clustered;
                        up.vector = p.vector;
                        up.doc_length = p.doc_length;
                        updated_cluster.posts.push(up)
                    }
                    updated_clusters.push(updated_cluster)
                }
                this.clusters = updated_clusters;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.updateClusters();
                this.sortClusters();
                this.loading = false;
            }
            console.log("clusters updated")

        },

        fetchLastPosts: async function () {
            console.log("fetch last posts");
            
            this.last_loading = true;
            this.error = null;

            let payload = {
                ids: this.post_ids,
                hour: this.parameters.daily_batch_hour,
                doc_epsilon: this.parameters.doc_epsilon / 100,
                tags_loot_epsilon: this.parameters.tags_loot_epsilon / 10,
                min_points: this.parameters.min_points,
                day_offset: this.parameters.day_offset,
                publisher_model_ref: this.parameters.publisher_model_ref,
                doc_max_tokens: this.parameters.doc_max_tokens,
                w2c_dimensions: this.parameters.w2c_dimensions,
                w2c_model: this.parameters.w2c_model,
            }

            try {
                let res = await this.$api.post("/ghost/fetch_gpt", payload);
                for (let c of res.data.clusters) {
                    let posts = []
                    for (let dp of c.deck_posts) {
                        let p = dp.post
                        p.is_out = false;
                        p.is_community = dp.is_community;
                        p.vector = dp.vector;
                        p.doc_length = dp.doc_length;
                        posts.push(p);
                    }
                    c.posts = posts
                    c.is_out = false;
                    c.show = false;
                    this.clusters.push(c);
                }
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                if (data.message == "last empty") {
                    this.last_snackbar = true;
                } else {
                    this.error = data.message;
                }
            } finally {
                this.updateClusters();
                this.sortClusters();
                this.last_loading = false;
            }
        },

        //* WIDGETS
        fetchTags: async function () {
            console.log("fetching tags");

            this.error = null;
            this.tags_list = [];
            try {
                let res = await this.$api.get("/tags");
                this.tags_list = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            }
        },


        //* CLUSTERS

        // needs to return a promise to be followed by then() for the scrolling
        // as a result async func
        async patchClusters() {
            this.clusters = [];
            this.clusters = this.storage_clusters;
        },

        // global updating
        updateClusters() {
            console.log("updating clusters")
            // build posts ids for update
            this.buildPostIds()
            // deck storage
            localStorage.setItem('clusters', JSON.stringify(this.clusters));
        },

        // posts ids
        buildPostIds() {
            this.post_ids = []
            for (let c of this.clusters) {
                for (let p of c.posts) {
                    this.post_ids.push(p.id);
                }
            }
        },

        //- order clusters by continent and first country tag
        sortClusters() {

            // Sort by continent_order (string) first, then by first_country_tag (string, handles null)
            this.clusters = this.clusters.sort((a, b) => {
                // Primary sort: Compare continent_order lexicographically
                if (a.continent_order < b.continent_order) return -1;
                if (a.continent_order > b.continent_order) return 1;

                // Secondary sort: Compare first_country_tag, treating null as an empty string
                const tagA = a.first_country_tag || ""; // Treat null as empty string
                const tagB = b.first_country_tag || ""; // Treat null as empty string

                return tagA.localeCompare(tagB, undefined, { sensitivity: 'base' }); // Case-insensitive
            });

            // sort posts accroding to content no html length
            this.clusters.forEach(
                cluster => {
                    cluster.posts = cluster.posts.sort((a, b) => {
                        // Remove HTML tags and count the words for both posts
                        let wordCountA = a.content.replace(/<[^>]+>/g, "").split(" ").length;
                        let wordCountB = b.content.replace(/<[^>]+>/g, "").split(" ").length;

                        // Sort by word count in descending order
                        return wordCountB - wordCountA;
                    });
                }
            );
        },

        // build cluster title
        buildClusterTitle(cl) {
            let title = ""
            if (cl.posts.length > 0) {
                title = cl.posts[0].title;

                if (this.trans_settings && this.trans_settings[cl.posts[0].language] && this.trans_settings[cl.posts[0].language].available.length > 0) {
                    for (let ol of this.trans_settings[cl.posts[0].language].available) {
                        if (ol.trans_active) {
                            if (cl.posts.length > 0 && cl.posts[0].translations) {
                                for (let trans of cl.posts[0].translations) {
                                    if (trans.output_language == ol.iso) {
                                        if (trans.title.length > 0) {
                                            title = "[" + trans.title.trim() + "]";
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
            return title
        },

    },

    computed: {
        
        tabSliderColor() {
            return this.tabColors[this.tab];
        },

        backgroundThemeSwitcher() {
            if (this.$vuetify.theme.dark) {
                return "active-items-dark"
            } else {
                return "active-items-light"
            }
        }
    
    }
}
</script>

<style>

</style>