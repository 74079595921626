import Vue from "vue";
import VueRouter from "vue-router";
import rbac from "@/common/rbac";
import roles from "@/roles/roles";
import app from "@/common/app";

// SHELL
import App from "@/shell/App";
import Login from "@/shell/views/Login";
// import Lost from "@/shell/views/LostPassword";
// import Reset from "@/shell/views/ResetConfirmation";
import Main from "@/shell/views/Main";
import Users from "@/shell/views/Users";
import User from "@/shell/views/User";

// VIEWS
import PublicMain from "@/views/PublicMain"
import PublicHome from "@/views/PublicHome"

import Sources from "@/views/Sources/Sources"
import Source from "@/views/Sources/Source"
import Classifiers from "@/views/Classifiers/Classifiers"
import Classifier from "@/views/Classifiers/Classifier"
import ManagerMain from "@/views/Managr/ManagerMain";  // 'Managr' cause gitignore "manager"
import Package from "@/views/Managr/Package"
import PackageSet from "@/views/Managr/PackageSet"
import PostTransSet from "@/views/Managr/PostTransSet"
import ToolsMain from "@/views/Tooler/ToolerMain"
import ToolsPostCorpus from "@/views/Tooler/CorpusPostTab"
import ToolsPostTags from "@/views/Tooler/TagsPostTab"
import ToolsPostLoot from "@/views/Tooler/LootPostTab"
import PublisherCalendar from "@/views/Publisher/PublisherCalendar"
import Ghost from "@/views/Ghost/GhostMain"
import Layers from "@/views/Layers/LayersMain"
import Bank from "@/views/Bank/BankMain"

//* Profiles
import SubscriberMain from "@/views/Profiles/Subscriber/SubscriberMain"
import TrainerMain from "@/views/Profiles/Trainer/TrainerMain"
import SearcherMain from "@/views/Profiles/Searcher/SearcherMain"
import Slaver from "@/views/Profiles/Slaver/SlaverMain"
import TagsMain from "@/views/Tags/TagsMain"
import Tag from "@/views/Tags/Tag"
import Labels from "@/views/Tags/Labels"
import Label from "@/views/Tags/Label"


Vue.use(VueRouter);

// fix error from router link "NavigationDuplicated"
['push','replace'].forEach(method => {
    const originalMethod = VueRouter.prototype[method];
    VueRouter.prototype[method] = function m(location) {
      return originalMethod.call(this, location).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
            // capture exception
        }
      })
    };
});


const router = new VueRouter({

    routes: [
        {
            path: "/",
            component: App,
            meta: { authorize: [] } 
        },
        // PUBLIC
        {   
            name: "pmain",
            path: "/pmain",
            component: PublicMain,
            meta: { authorize: [] },
            children: [

                // {
                //     name: "login",
                //     path: "login",
                //     component: Login,
                //     meta: { authorize: [] } 
                // },

                {
                    name: "45673",
                    path: "45673",
                    component: Login,
                    meta: { authorize: [] } 
                },

                // {
                //     name: "lost",
                //     path: "lost",
                //     component: Lost,
                //     meta: { authorize: [] } 
                // },

                // {
                //     name: "reset",
                //     path: "reset/:code",
                //     component: Reset,
                //     meta: { authorize: [] } 
                // },

                {   
                    name: "phome",
                    path: "phome",
                    component: PublicHome,
                    meta: { authorize: [] },
                },
            ],
        },
        
        // LOGGED
        {
            path: "/main",
            component: Main,
            meta: { authorize: [] }, 
            children: [
                
                {   
                    name: "sources",
                    path: "sources",
                    component: Sources,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "source",
                    path: "source/:ref",
                    component: Source,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "classifiers",
                    path: "classifiers",
                    component: Classifiers,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "classifier",
                    path: "classifier/:id",
                    component: Classifier,
                    meta: { authorize: [roles.retrive().admin.name] },
                },


                // CALENDAR
                {   
                    name: "calendar",
                    path: "calendar",
                    component: PublisherCalendar,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // GHOST
                {   
                    name: "ghost",
                    path: "ghost",
                    component: Ghost,
                    meta: { authorize: [roles.retrive().admin.name] },
                },


                // LAYERS
                {   
                    name: "layers",
                    path: "layers",
                    component: Layers,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // BANK
                {   
                    name: "bank",
                    path: "bank",
                    component: Bank,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // LABELLER
                {   
                    name: "tags",
                    path: "tags",
                    component: TagsMain,
                    meta: { authorize: [roles.retrive().admin.name]},
                },
                {   
                    name: "tag",
                    path: "tag",
                    component: Tag,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // Labels
                // when we navigate from tag to associated labels
                {   
                    name: "labels",
                    path: "labels",
                    component: Labels,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "label",
                    path: "label",
                    component: Label,
                    meta: { authorize: [roles.retrive().admin.name] },
                },


                // PROFILES
                {   
                    name: "subscriber",
                    path: "subscriber",
                    component: SubscriberMain,
                    meta: { 
                        authorize: [roles.retrive().admin.name,roles.retrive().trainer_subscriber.name, roles.retrive().subscriber.name],
                        scroll: 0,
                    },
                },
               
                {   
                    name: "trainer",
                    path: "trainer",
                    component: TrainerMain,
                    meta: { 
                        authorize: [roles.retrive().admin.name, roles.retrive().trainer_subscriber.name, roles.retrive().trainer.name],
                        scroll: 0,
                    },
                },
                {   
                    name: "slaver",
                    path: "slaver",
                    component: Slaver,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "searcher",
                    path: "searcher",
                    component: SearcherMain,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                 //* MANAGER: Packages - sets - translation sets
                 {   
                    name: "manager",
                    path: "manager",
                    component: ManagerMain,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "package",
                    path: "package/:id",
                    component: Package,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "package_set",
                    path: "package_set/:id",
                    component: PackageSet,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "post_trans_set",
                    path: "post_trans_set/:id",
                    component: PostTransSet,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // TOOLS
                {   
                    name: "tools_main",
                    path: "tools_main",
                    component: ToolsMain,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                 // DIRECT ROUTE TO CorpusPostTab
                 {   
                    name: "tools_post_corpus_tab",
                    path: "tools_post_corpus_tab/:pid",
                    component:  ToolsPostCorpus,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // DIRECT ROUTE TO TagsPostTab
                {   
                    name: "tools_post_tags_tab",
                    path: "tools_post_tags_tab/:pid",
                    component:  ToolsPostTags,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // DIRECT ROUTE TO LootPostTab
                {   
                    name: "tools_post_loot_tab",
                    path: "tools_post_loot_tab/:pid",
                    component:  ToolsPostLoot,
                    meta: { authorize: [roles.retrive().admin.name] },
                },

                // SHELL VIEWS
                {   
                    name: "users",
                    path: "users",
                    component: Users,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
                {   
                    name: "user",
                    path: "user/:id",
                    component: User,
                    meta: { authorize: [roles.retrive().admin.name] },
                },
               

            ],
        },
    ]
});



router.beforeEach((to, from, next) => {

    //* RBAC
    // redirect to login page if not logged in and trying to access a restricted page
    //const { authorize} = to.meta;
    const userRole = rbac.get_user_role("role");
    
    if (to.meta.authorize.length > 0 && to.path != '/main') {
        if (!userRole) {
            // not logged in so redirect to login page with the return url
            return next({ path: '/main', query: { returnUrl: to.path } });
        }
        
        // check if route is restricted by role
        if (to.meta.authorize.length && !to.meta.authorize.includes(userRole)) {
            // role not authorised so redirect to specific page
            return next({ path: '/main' });
        }
    }
    
    //* SCROLL MANAGEMENT
    // store scroll position before going to single for view with a meta.scroll: 0
    if (from.meta.scroll == 0 ) {
        from.meta.scroll = window.scrollY;
    }

    //* MOBILE
    // redirect home mobile when navigation in drawer
    if (app.retriveIsMobile && to.path == '/main/home') {
        return next({ path: '/main/mhome' });
    }

    next();

})


// 👇️ default export
export default router;