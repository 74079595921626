<template>
    <div>
       
        <v-sheet tile rounded outlined class="" :color="color">
            <v-card tile flat class="d-flex py-1" key="recaller-cluster-header" >

                <!-- Cluster Meta-->
                <v-card flat outlined color="transparent" width="82px" class="px-1">
                    
                    <!-- SHOW/hide cluster posts  -->
                    <v-btn @click="switchShowRecalledCluster" x-small class="font-weight-bold Dialog--text ml-1 mt-0" :color="color">
                        DCL#{{cl_index}}
                    </v-btn>

                    <v-btn small outlined @click="forget" class="ml-1 mt-2" :color="color"><v-icon color="success">mdi-alpha-f-circle</v-icon></v-btn>

                </v-card>

                <!-- avatar -->
                <v-avatar v-if="avatar_url.length > 0"
                    rounded=""
                    size="100"
                    class="ml-2"
                >
                    <v-img v-if="cluster.posts.length > 0" :src="avatar_url" :key="avatar_url"></v-img>
                </v-avatar>

                <v-card flat class="" color="" width="100%" key="title-sources-translation">
                    
                    <!-- title -->
                    <v-card-title @click="switchShowRecalledCluster" style="cursor: pointer"  class="wordbreak text-body-1 font-weight-bold pt-1 pb-0 pl-2">
                        {{buildTitle}}
                    </v-card-title>

                    <v-card-actions class="pt-1 pb-2">
                        
                        <!-- Cluster Posts Meta-->
                        <v-btn x-small outlined fab class="font-weight-bold lime--text text-caption">{{ cluster.posts.length }}</v-btn>

                        <!-- key -->
                        <v-btn small text outlined color="white">{{ cluster.key }}</v-btn>

                        <!-- Sources Widget-->
                        <SourcesWidget :posts="cluster.posts" :color="color" class="ml-2"/>
                        <v-spacer></v-spacer>
                        <!-- Translation Widget-->
                        <TranslationWidget
                            :post="cluster.posts[0]"
                            tab="cluster"
                            stream="post"
                            class="ml-4"
                            @eventTranslation="eventTranslation"
                        ></TranslationWidget>


                    </v-card-actions>

                    <DeckTagsWidget 
                        :cluster="cluster"
                        :cl_index="cl_index"
                        :tags_list="tags_list"
                        @eventRemoveTagFromCluster="eventRemoveTagFromCluster"
                        class="ml-2"
                    />
                    <DeckLootWidget
                        :cluster ="cluster"
                        :cl_index="cl_index"
                        :tags_list="tags_list"
                        @eventRemoveLootFromCluster="eventRemoveLootFromCluster"
                        class="ml-2 mt-1"
                    />
                </v-card>


            </v-card>
        </v-sheet>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DeckTagsWidget from '../Deck/DeckCluster/DeckWidgetTags.vue'
import DeckLootWidget from '../Deck/DeckCluster/DeckWidgetLoot.vue'
import TranslationWidget from "../../Translations/TranslationWidget.vue";
import SourcesWidget from '../Deck/DeckCluster/DeckWidgetSources.vue';

export default {

    name: 'DeckClusterHeader',

    props: ['proposal_index', 'cluster', 'cl_index', 'tags_list', 'color'],

    components: {
        SourcesWidget,
        TranslationWidget,
        DeckTagsWidget,
        DeckLootWidget,
    },

    data: () => ({
        show_cluster: true,
    }),

    methods: {

        switchShowRecalledCluster() {
            this.show_cluster = !this.show_cluster
            this.$emit("eventShowRecalledCluster", this.cl_index);
        },

        forget() {
            this.$emit("eventForgetProposal", this.proposal_index);
        },

        //* Events
        eventRemoveTagFromCluster(cl_index, ref) {
            this.$emit("eventRemoveTagFromCluster", cl_index, ref);
        },

        eventRemoveLootFromCluster(cl_index, ref) {
            this.$emit("eventRemoveLootFromCluster", cl_index, ref);
        },

        // Translations
        eventTranslation(output_language, content, description, title) {
            console.log("eventTranslation", output_language, content.length, description.length,title)
            if (this.cluster.posts[0].translations.length > 0) {
                for (let tr of this.cluster.posts[0].translations) {
                    if (tr.output_language == output_language) {
                        if (title.length > 0) {
                            tr.title = title
                        }
                        if (description.length > 0) {
                            tr.description = description;
                        }
                        tr.content = content;
                    }
                }
            }
        },
    },

    computed: {

        ...mapGetters({
            trans_settings: 'getTransSettings',
        }),

        avatar_url() {
            return this.cluster.posts.length > 0 ? this.cluster.posts[0].img_url : '';
        },


        buildTitle() {
            let title = ""
            if (this.cluster.posts.length > 0) {
                title = this.cluster.posts[0].title;

                if (this.trans_settings && this.trans_settings[this.cluster.posts[0].language] && this.trans_settings[this.cluster.posts[0].language].available.length > 0) {
                    for (let ol of this.trans_settings[this.cluster.posts[0].language].available) {
                        if (ol.trans_active) {
                            if (this.cluster.posts.length > 0 && this.cluster.posts[0].translations) {
                                for (let trans of this.cluster.posts[0].translations) {
                                    if (trans.output_language == ol.iso) {
                                        if (trans.title.length > 0) {
                                            title = "[" + trans.title.trim() + "]";
                                        }
                                    }
                                }
                            }
                        } 
                    }
                    
                }
            }
            return title
        },
    },

}
</script>

<style>

</style>