<template>
    <div>
        <v-card flat class="transparent">

            <!-- loading -->
            <v-progress-linear
                indeterminate
                color="amber"
                :active="loading">
            </v-progress-linear>

            <!-- error msg -->
            <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>

            <v-card flat class="d-flex flex-wrap" color="transparent">
                
                <v-card flat tile color="transparent" class="mr-2">
                    <!-- goToLoot button -->
                    <v-icon v-if="stream == 'post'" @click="goLoot" small class="" color="amber">mdi-alpha-l-circle-outline</v-icon>
                    <v-icon v-if="stream == 'single'" @click="goLoot" class="" color="amber">mdi-alpha-l-circle-outline</v-icon>
                </v-card>

                <v-card v-for="(l, index) of widgetLoot" :key="index" class="mr-2" color="transparent">
                    <v-btn v-if="stream == 'post'" 
                        @click="openDialogUpdateLoot(index, l)"
                        x-small outlined color="amber"
                    >
                        {{ valueFormat(l) }} {{ displayCategory(l) }}
                        <!--<span v-for="(m,index) of l.metrics" :key="index" class='ml-2 font-weight-bold blue--text'>{{m.loot_ref }}</span>-->
                        <span class='ml-2 font-weight-bold pink--text'>{{ l.tag_name }}</span>
                    </v-btn>
                    <v-btn v-else 
                        @click="openDialogUpdateLoot(index, l)"
                        x-small outlined color="amber"
                    >
                        {{ valueFormat(l) }} {{ displayCategory(l) }}
                        <!--<span v-for="(m,index) of l.metrics" :key="index" class='ml-2 font-weight-bold blue--text'>{{m.loot_ref }}</span>-->
                        <span class='ml-2 font-weight-bold pink--text'>{{ l.tag_name }}</span>
                        <span v-if="stream == 'corpusPostTab'" class='ml-2 font-weight-bold pink--text'>[tags DB id:{{ l.db_id }}]</span>
                    </v-btn>
                    

                    <!-- DELETE -->
                    <v-icon v-if="allow_delete" small @click="removeLoot(l)" class="mx-1" color="amber">mdi-close-circle</v-icon>
                </v-card>

                <!-- DIALOG CHANGE VALUE -->
                <v-dialog v-model="dialog" width="400">
        
                    <v-card>
                        <v-card-title class="amber Dialog--text">{{dialogText}} <span v-if="!dialogCaseIsNew">'{{dialogLoot.value}}' {{dialogLoot.tag_name}}</span></v-card-title>
                        
                        <!-- update loot -->
                        <v-card-text v-if="!dialogCaseIsNew">
                            <v-text-field v-if="widgetLoot && widgetLoot[dialogIndex]"
                                label="new value"
                                hint="float number with a dot !"
                                v-model.number="widgetLoot[dialogIndex].value"
                                required
                                :rules=[commaDetection]
                                color="amber"
                                hide-details
                            ></v-text-field>
                        </v-card-text>
                        
                        <!-- add loot -->
                        <v-card-text v-else>
                            
                            <!-- loading -->
                            <v-progress-linear
                                indeterminate
                                color="amber"
                                :active="addLootLoading">
                            </v-progress-linear>
                            
                            <v-text-field
                                v-model.number="addLootValue"
                                label="new value"
                                color="amber"
                                :suffix='addLootType === "weight"? "Kg" : "Objects"'
                                required
                            ></v-text-field>
                            <v-radio-group row v-model="addLootType">
                                <v-radio small label="weight" color="amber" value="weight"></v-radio>
                                <v-radio small label="objects" color="amber" value="object"></v-radio>
                            </v-radio-group>
                            <v-autocomplete
                                outlined
                                dense
                                v-model="addLootRef"
                                :items="tags"
                                item-text="name"
                                item-value="ref"
                                color="amber"
                                clearable
                                hide-details
                                required
                            ></v-autocomplete>
                        </v-card-text>


                        
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="!dialogCaseIsNew"
                                color="amber"
                                text
                                @click="updateValueLoot()"
                            >
                                Save
                            </v-btn>
                            <v-btn v-else
                                color="amber"
                                text
                                @click="addNewLoot()"
                            >
                                Add
                            </v-btn>

                            <v-btn
                                color="amber"
                                text
                                @click="dialog = false"
                            >
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- ADD POST LOOT -->
                <v-btn v-if="status == 'old'" 
                    small
                    @click="openDialogAddLoot" 
                    :loading="loading"
                    :disabled="loading"
                    color="transparent"
                    class="">
                        <v-icon color="amber">mdi-plus-circle-outline</v-icon>
                </v-btn>

                <!-- UPDATE POST FROM LOOTER  -->
                <v-btn v-if="status == 'new'" 
                    small
                    @click="updateLoot" 
                    :loading="loading"
                    :disabled="loading"
                    color="transparent"
                    class="">
                        <v-icon color="amber">mdi-arrow-up-bold-circle-outline</v-icon>
                </v-btn>

            </v-card>

        </v-card>
        <!-- error msg -->
        <span v-if="error" class="ml-5 error--text">API Error: {{ error }}</span>
    </div>
</template>

<script>

export default {
    name: 'LootWidget',

    data: () => ({
        loading: false,
        error: null,
        widgetLoot: [],
        initLootList: [],
        widgetLootList: [],
        dialog: false,
        dialogText: "",
        
        // new loot
        dialogCaseIsNew: false,
        addLootValue : 0,
        addLootRef: null,
        addLootLoading: false,
        addLootType: "weight",
        dialogIndex: 0,
        dialogLoot: [],

        tags: [],
    }),

    props: ['loot', 'pid', 'stream', 'status', 'language', "allow_delete"],

    watch: {
        loot: {
            deep: true,
            immediate: true,
            handler() {
                this.widgetLootList =[];
                this.initLootList = [];
                this.widgetLoot = this.loot;
                if (this.loot && this.loot.length > 0) {
                    for (let l of this.loot) {
                        // set to detect changes
                        this.initLootList.push(l.tag_ref + "_" + l.value + "_" +l.category)
                        this.widgetLootList.push(l.tag_ref + "_" + l.value + "_" +l.category)
                    }
                }
            }
        },
    },

    methods: {

       
        openDialogAddLoot: async function () {
            console.log("adding loot")
            this.dialog = true;
            this.dialogText = "New loot value";
            this.dialogCaseIsNew = true;
            this.addLootRef = null;
            this.addLootValue = 0;
            this.fetchTags();
        },

        fetchTags: async function() {
            this.addLootLoading = true;
            this.error = null;
            this.tags = [];
            try {
                let res = await this.$api.get("/tags_market");
                this.tags = res.data;
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.addLootLoading = false;
            }
        },

        addNewLoot: async function() {
            console.log("adding new loot");
            this.addLootLoading = true;
            this.error = null;
            let payload = {
                post_id: this.pid,
                value: this.addLootValue,
                ref: this.addLootRef,
                language: this.language,
                category: this.addLootType,
            }

            //console.log(payload)
            try {
                await this.$api.post("/looter/loot/widget/add", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.addLootLoading = false;
                this.dialog = false;
                this.eventLoot()
            }
        },

        // remove loot
        removeLoot(loot) {
            console.log("removing loot:", loot);
            let updatedLoot = [];
            for (let l of this.widgetLoot) {
                if (l.value != loot.value || l.tag_ref != loot.tag_ref || l.category != loot.category) {
                    updatedLoot.push(l)
                    this.widgetLootList.push(l.tag_ref + "_" + l.value + "_" + l.category)
                }
            }
            this.widgetLoot = updatedLoot;
            this.updateLoot();
        },

        // dialog update value
        openDialogUpdateLoot(index, loot) {
            this.dialog = true;
            this.dialogText = "Update loot value:";
            this.dialogIndex = index;
            this.dialogLoot = loot;
        },

        updateValueLoot() {
            this.dialog = false;
            this.updateLoot()
        },

        updateLoot: async function () {
            console.log("updating loot")
            this.loading = true;
            this.error = null;

            // payload
            let payload = {
                post_id: this.pid,
                loot: [],
                error: 0,
                language: this.language,
            };

            // monitor if changed in tags made by user => posts.tags_error = 1
            if (this.isChanged(this.widgetLootList,this.initLootList)) {
                payload.error = 1;
                console.log("changed detected")
            }

            // json cards payload
            if (this.loot && this.loot.length > 0) {
                let loot_payload = [];
                // build payload if update
                for (let l of this.widgetLoot) {
                    loot_payload.push(JSON.stringify({
                        value: l.value,
                        category: l.category,
                        tag_ref: l.tag_ref,
                    }))
                }
                payload.loot = loot_payload;
            }

            try {
                await this.$api.post("/looter/loot/widget/update", payload);
            } catch (e) {
                let data = (e.response || {}).data || "unknown error";
                this.error = data.message;
            } finally {
                this.loading = false;
            }

        },

        eventLoot() {
            this.$emit("eventLoot");
        },

        goLoot() {
           this.$emit("eventGoLoot");
        },

        valueFormat(loot) {
        
            if (loot.category == "weight") {
                // 42780 => 42.78K
                if (parseInt(loot.value, 10) >= 1000) {

                    var value = parseInt(loot.value, 10);
                    if (value >= 1000) {
                        var result = value / 1000;
                        if (Number.isInteger(result)) {
                            return result + " tons";
                        } else {
                            return result.toFixed(2) + " tons";
                        }
                    }

                } else if (parseInt(loot.value, 10) >= 1) {
                    return Math.round(100 * loot.value)/100 + " kgs";
                } else {
                    // neede floating point in js handling
                    let val = Math.round(loot.value * 100000) / 100
                    return val + " gr";
                }
            } else if (loot.category == "cash" || loot.category == "worth" || loot.category == "object") {

                if (loot.value >= 1000000000) {
                    return Math.round(loot.value / 1000000000 * 10) / 10  +  "B"
                }

                if (loot.value >= 1000000 && loot.value < 1000000000 ) {
                    return Math.round(loot.value / 1000000 * 10) / 10  +  "M"
                }

                if (loot.value >= 1000 && loot.category != "object") {
                    return Math.round(loot.value / 1000 * 10) / 10  +  "K"
                }
            }

            return loot.value
        },

        displayCategory(loot) {
            let c = "unknown"
            switch (loot.category) {
                case "weight":
                    c = ""
                    break;
                case "cash":
                    c = "US$"
                    break;
                case "object":
                    if (loot.value > 1) {
                        c = "objects"
                    } else {
                        c = "object"
                    }
                    break;
                case "worth":
                    c = "worth US$"
                    break;
                default:
                    break;
            }
            return c
        },

        // detect change between init/widget tags
        isChanged(array1, array2) {
            if (array1.length !== array2.length) {
                return true;
            }
            
            const array2Sorted = array2.slice().sort();
            const array1Sorted = array1.slice().sort();
            
            for (let i = 0; i < array1Sorted.length; i++) {
                if (array1Sorted[i] !== array2Sorted[i]) {
                    return true;
                }
            }
            
            return false;
        },
    },

    computed: {
        commaDetection() {
            return (value) => {
                const regex = /,/;
                if (regex.test(value)) {
                    return 'Input should not have a comma';
                }
                return true; // Validation passed
            };
        },
    
    },

};
</script>

<style></style>